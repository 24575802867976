import { Form, Input, Select, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment from "moment";
import ColoredTag from "../../core/component/ColoredTag";
import DateBetweenFilter from "../../core/component/DateBetweenFilter";
import YesNoTag from "../../core/component/YesNoTag";
import { AuthHelper } from "../../core/helper/AuthHelper";
import {
  getDateString,
  stringNumberSorter,
} from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import Upload from "../../core/component/Upload";

export const getBusinessTasksCPColumns = (
  users: any[],
  taskStatus: any[],
  priorties: any[]
) => {
  var columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kullanıcı",
      dataIndex: "assignedWorker",
      key: "assignedWorker",
      render: (value: any) => value.name + " " + value.surname,
      filters: users,
      onFilter: (value: any, record: any) =>
        record.assignedWorker["@id"] === value,
    },
    {
      title: "Oluşturan",
      dataIndex: "creator",
      key: "creator",
      render: (value: any) => (value ? value.name + " " + value.surname : "-"),
      filters: users,
      onFilter: (value: any, record: any) => record.creator["@id"] === value,
    },
    {
      title: "Görev Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Görev Durumu",
      dataIndex: "state",
      key: "state",
      render: (value: any) => (
        <YesNoTag
          success={value === "done"}
          successText="Yapıldı"
          failText="Atandı"
          failColor="blue"
        />
      ),
      filters: taskStatus,
      onFilter: (value: any, record: any) => record.state === value,
    },
    {
      title: "Görev Aciliyeti",
      dataIndex: "priorty",
      key: "priorty",
      render: (value: any) => {
        const priorty = priorties.find((priorty) => {
          return priorty.value === value;
        });
        return <Tag color={value}>{priorty?.label}</Tag>;
      },
      filters: priorties,
      onFilter: (value: any, record: any) => record.priorty === value,
    },
    {
      title: "Tekrar Periyodu (Gün)",
      dataIndex: "retryPeriod",
      key: "retryPeriod",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
      onFilter: (value: any, record: any) => {
        return moment(new Date(record.createdAt), "").isBetween(
          value.first,
          value.last,
          "days",
          "[]"
        );
      },
      filterDropdown: ({
        selectedKeys,
        setSelectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <DateBetweenFilter
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
    },
    {
      title: "Yapılış Tarihi",
      dataIndex: "doneDate",
      key: "doneDate",
      render: (value: any) => (
        <YesNoTag
          success={value !== null}
          successText={getDateString(value)}
          failText="Girilmedi"
        />
      ),
    },
    {
      title: "Görev Bitiş Tarihi",
      dataIndex: "finishDate",
      key: "finishDate",
      render: (value: any) => (
        <YesNoTag
          success={value !== null}
          successText={getDateString(value)}
          failText="Girilmedi"
        />
      ),
    },
    {
      title: "K. Süre",
      dataIndex: "remainderDay",
      key: "remainderDay",
      render: (value: any) => (
        <Tag color={value === "Yapıldı" ? "" : value < 0 ? "red" : "green"}>
          {value}
        </Tag>
      ),
      sorter: (a: any, b: any) => stringNumberSorter(a, b, "remainderDay"),
    },
    {
      title: "G. Süre",
      dataIndex: "delayedDay",
      key: "delayedDay",
      render: (value: any) =>
        Number.isInteger(value) ? (
          <Tag color="red">{value}</Tag>
        ) : (
          <ColoredTag
            selected={value}
            options={[
              { key: "Henüz Yapılmadı", color: "", text: "Henüz Yapılmadı" },
              { key: "Zamanında Y.", color: "green", text: "Zamanında Y." },
            ]}
          />
        ),
      sorter: (a: any, b: any) => stringNumberSorter(a, b, "delayedDay"),
    },
  ];

  // if (AuthHelper.checkRole("ROLE_ADMIN")) {
  //   const column = {
  //     title: "Kullanıcı",
  //     dataIndex: "assignedWorker",
  //     key: "assignedWorker",
  //     render: (value: any) => value.name + " " + value.surname,
  //     filters: users,
  //     onFilter: (value: any, record: any) =>
  //       record.assignedWorker["@id"] === value,
  //   } as any;
  //   columns.splice(1, 0, column);
  // }

  return columns;
};

export const getBusinessTasksCPAddFormItems = (
  users: any[],
  priorties: any[]
) => {
  return (
    <>
      <Form.Item
        label="İdari Personel"
        name="assignedWorker"
        rules={[Rules.required]}
      >
        <Select allowClear options={users} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Görev Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(150)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Aciliyeti" name="priorty" rules={[Rules.required]}>
        <Select
          options={priorties.filter(
            (priortyItem: any) => priortyItem.value !== "cyan"
          )}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="Tanımı"
        name="definition"
        rules={[Rules.required, Rules.max(500)]}
      >
        <TextArea />
      </Form.Item>
    </>
  );
};

export const getBusinessTasksCPEditFormItems = (
  users: any[],
  priorties: any[],
  taskStatus: any[]
) => {
  return (
    <>
      {AuthHelper.checkRole("ROLE_ADMIN") &&
        getBusinessTasksCPAddFormItems(users, priorties)}
      <Form.Item label="Durumu" name="state" rules={[Rules.required]}>
        <Select options={taskStatus} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Açıklaması"
        name="description"
        rules={[Rules.required, Rules.max(5000)]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item name="media">
        <Upload />
      </Form.Item>
    </>
  );
};
