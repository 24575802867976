import { taskExtendedApi } from "../client/EntityApi";
import { getTaskApproveCPColumns } from "../components/crud/TaskApproveCPComponents";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import {
  EyeOutlined,
  CheckOutlined,
  ExclamationOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useRef, useState } from "react";
import { Form, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Rules } from "../core/Rules";
import { getCompanyDetailCPExpandedRowRender } from "../components/crud/CompanyDetailCPComponents";

const TaskApproveCrudPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);
  const [form] = Form.useForm();

  const [selectedId, setSelectedId] = useState<number>();
  const [approveDialogIsOpen, setApproveDialogIsOpen] =
    useState<boolean>(false);
  const [waitForEditDialogIsOpen, setWaitForEditDialogIsOpen] =
    useState<boolean>(false);
  const [rejectDialogIsOpen, setRejectDialogIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    setSelectedId(undefined);
    setApproveDialogIsOpen(false);
    setWaitForEditDialogIsOpen(false);
    setRejectDialogIsOpen(false);
    setLoading(false);
    form.resetFields();
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Görev Onay"
        hideSearchBar
        hideAddProcess
        hideDefaultRowProceses
        api={{}}
        getAll={taskExtendedApi.getWaitForApproveTasks}
        columns={getTaskApproveCPColumns()}
        expandable={{
          expandedRowRender: (record) =>
            getCompanyDetailCPExpandedRowRender(record),
        }}
        extraRowProcess={(row) => {
          return (
            <>
              <CrudPageProcessButton
                tooltipText="İşletme Detayı"
                icon={<EyeOutlined />}
                onClick={() =>
                  window.open("/isletme-detay/" + row.taskCategory.company.id)
                }
              />
              <CrudPageProcessButton
                tooltipText="Onayla"
                icon={<CheckOutlined />}
                onClick={() => {
                  setSelectedId(row.id);
                  setApproveDialogIsOpen(true);
                }}
              />
              <CrudPageProcessButton
                tooltipText="Düzeltme İste"
                icon={<ExclamationOutlined />}
                onClick={() => {
                  setSelectedId(row.id);
                  setWaitForEditDialogIsOpen(true);
                }}
              />
              <CrudPageProcessButton
                tooltipText="Reddet"
                icon={<CloseOutlined />}
                onClick={() => {
                  setSelectedId(row.id);
                  setRejectDialogIsOpen(true);
                }}
              />
            </>
          );
        }}
      />
      <Modal
        title="Görev Onay"
        visible={approveDialogIsOpen}
        okText="Onayla"
        cancelText="Kapat"
        onOk={() => {
          if (selectedId) {
            setLoading(true);
            taskExtendedApi
              .taskEvaluation(selectedId, { taskStatus: "approved" })
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        Görev onaylanacaktır. Emin misiniz ?
      </Modal>
      <Modal
        title="Görev Düzeltmesi"
        visible={waitForEditDialogIsOpen}
        okText="Düzeltme İste"
        cancelText="Kapat"
        onOk={() => {
          if (selectedId) {
            setLoading(true);
            taskExtendedApi
              .taskEvaluation(selectedId, {
                taskStatus: "waitForEdit",
                errorComment: form.getFieldValue("errorComment"),
              })
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="errorComment"
            label="Açıklama"
            rules={[Rules.required]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Görev Reddi"
        visible={rejectDialogIsOpen}
        okText="Reddet"
        cancelText="Kapat"
        onOk={() => {
          if (selectedId) {
            setLoading(true);
            taskExtendedApi
              .taskEvaluation(selectedId, {
                taskStatus: "rejected",
                errorComment: form.getFieldValue("errorComment"),
              })
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="errorComment"
            label="Açıklama"
            rules={[Rules.required]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TaskApproveCrudPage;
