import { vaccineCrudApi } from "../client/EntityApi";
import {
  getVaccineCPFormItems,
  getVaccineCPColumns,
} from "../components/crud/VaccineCPComponents";
import CrudTable from "../core/component/crud/CrudTable";

const VaccineCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Aşı"
      api={vaccineCrudApi}
      searchKeys={["name"]}
      columns={getVaccineCPColumns()}
      addFormItems={getVaccineCPFormItems()}
      editFormItems={getVaccineCPFormItems()}
    />
  );
};

export default VaccineCrudPage;
