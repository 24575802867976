import { Form, Input, Select, Switch } from "antd";
import { Rules } from "../../core/Rules";
import YesNoTag from "../../core/component/YesNoTag";

export const getCompanyCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İşletme Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tür",
      dataIndex: "companyType",
      key: "companyType",
      render: (value: any) => (value === "fatling" ? "Besi" : "Süt"),
    },
    {
      title: "İl / İlçe",
      dataIndex: "city",
      key: "cityDistrict",
      render: (value: any, row: any) => value.name + " / " + row.district.name,
    },
    {
      title: "Portföy",
      dataIndex: "portfolio",
      key: "portfolio",
      render: (value: any) => value.name,
    },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any) => <YesNoTag success={value} />,
      defaultFilteredValue: [true],
      filters: [
        { text: "Aktif", value: true },
        { text: "Pasif", value: false },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
  ];
};

export const getCompanyCrudPageFormItems = (
  companyTypes: any,
  cities: any[],
  portfolios: any[],
  selectedCity: any,
  setSelectedCity: (city: any) => void,
  form: any
) => {
  return (
    <>
      <Form.Item
        name="name"
        label="İşletme Adı"
        rules={[Rules.required, Rules.min(4)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="managerName"
        label="Yetkili Adı Soyadı"
        rules={[Rules.required]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="managerPhone"
        label="Yetkili Telefonu"
        rules={[Rules.required, Rules.max(20)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="subdomain"
        label="Ünvan"
        rules={[Rules.required, Rules.min(4)]}
      >
        <Input
          addonBefore={process.env.REACT_APP_SUBDOMAIN_FIELD_ADDON_BEFORE}
          addonAfter={process.env.REACT_APP_SUBDOMAIN_FIELD_ADDON_AFTER}
        />
      </Form.Item>
      <Form.Item
        name="username"
        label="Kullanıcı Adı"
        rules={[Rules.required, Rules.min(3)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Şifre"
        rules={[Rules.required, Rules.min(4)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="companyType"
        label="İşletme Türü"
        rules={[Rules.required]}
      >
        <Select options={companyTypes} />
      </Form.Item>
      <Form.Item name="city" label="İl" rules={[Rules.required]}>
        <Select
          options={cities}
          onChange={(value, row) => {
            form.resetFields(["district"]);
            setSelectedCity(row);
          }}
        />
      </Form.Item>
      <Form.Item name="district" label="İlçe" rules={[Rules.required]}>
        <Select options={selectedCity?.districts ?? []} />
      </Form.Item>
      <Form.Item
        name="locationLink"
        label="Harita Linki"
        rules={[Rules.required]}
      >
        <Input placeholder="Google Haritalar Linkini Giriniz." />
      </Form.Item>
      <Form.Item name="portfolio" label="Portföy" rules={[Rules.required]}>
        <Select options={portfolios} />
      </Form.Item>
      <Form.Item
        name="isActive"
        label="Aktif Mi ?"
        initialValue={true}
        valuePropName="checked"
        rules={[Rules.required]}
      >
        <Switch defaultChecked />
      </Form.Item>
    </>
  );
};
