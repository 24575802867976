import { Form, Input, InputNumber } from "antd";
import YesNoTag from "../../core/component/YesNoTag";
import { getDateString } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

export const getOrderCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Sipariş Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Vade (Gün)",
      dataIndex: "expiry",
      key: "expiry",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
    },
    {
      title: "Siparişi Oluşturan",
      dataIndex: "createdWorker",
      key: "createdWorker",
      render: (value: any) => value.name + " " + value.surname,
    },
    {
      title: "Siparişin Durumu",
      dataIndex: "state",
      key: "state",
      render: (value: any) => (
        <YesNoTag
          success={value === "done"}
          successText="Teslim Edildi"
          failText="Teslim Edilmedi"
        />
      ),
      filters: [
        {
          text: "Teslim Edildi",
          value: "done",
        },
        {
          text: "Teslim Edilmedi",
          value: "notDone",
        },
      ],
      onFilter: (value: any, record: any) => record.state === value,
    },
    {
      title: "Siparişi Onaylayan",
      dataIndex: "senderWorker",
      key: "senderWorker",
      render: (value: any) => (
        <YesNoTag
          success={value !== null}
          successText={value?.name + " " + value?.surname}
          failText="Teslim Edilmedi"
        />
      ),
    },
    {
      title: "Siparişin Onay Tarihi",
      dataIndex: "deliveredDate",
      key: "deliveredDate",
      render: (value: any) => (
        <YesNoTag
          success={value !== null}
          successText={getDateString(value)}
          failText="Teslim Edilmedi"
        />
      ),
    },
  ];
};

export const getOrderCPFormItems = () => {
  const { TextArea } = Input;
  return (
    <>
      <Form.Item
        label="Sipariş Adı"
        name="name"
        rules={[Rules.required, Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Sipariş Detayı"
        name="orderDetail"
        rules={[Rules.required, Rules.max(2000)]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Açıklama"
        name="description"
        rules={[Rules.required, Rules.max(2000)]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Vade Tarihi (Gün)"
        name="expiry"
        rules={[Rules.required]}
        initialValue={0}
      >
        <InputNumber min={0} precision={0} />
      </Form.Item>
    </>
  );
};
