import { Button, DatePicker, Row, Space } from "antd";
import { useState } from "react";

interface IDateBetweenFilterProps {
  selectedKeys: any;
  setSelectedKeys: any;
  confirm: any;
  clearFilters: any;
}

const DateBetweenFilter = (props: IDateBetweenFilterProps) => {
  const { RangePicker } = DatePicker;

  const [range, setRange] = useState<any>(null);

  return (
    <div style={{ padding: "1em" }}>
      Tarihe Göre Filtrele <br />
      <RangePicker
        allowClear
        value={range}
        format="DD/MM/YYYY"
        style={{ margin: "0.5em 0" }}
        size="middle"
        onChange={(value) => {
          if (value === null) return;
          setRange(value);
          props.setSelectedKeys([{ first: value[0], last: value[1] }]);
        }}
      />
      <Row>
        <Space>
          <Button
            onClick={() => {
              props.setSelectedKeys([]);
              setRange(null);
              props.clearFilters();
            }}
            type="link"
            size="middle"
            disabled={!(props.selectedKeys.length > 0)}
          >
            Sıfırla
          </Button>
          <Button
            type="primary"
            onClick={() => props.confirm({ closeDropdown: false })}
            size="middle"
          >
            Tamam
          </Button>
        </Space>
      </Row>
    </div>
  );
};

export default DateBetweenFilter;
