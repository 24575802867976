import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  consumptionCrudApi,
  consumptionExtendedApi,
  consumptionProductCrudApi,
} from "../client/EntityApi";
import {
  getConsumptionCrudPageColumns,
  getConsumptionCrudPageFormItems,
} from "../components/crud/ConsumptionCPComponents";
import CrudTable from "../core/component/crud/CrudTable";
import { getDateFromString } from "../core/helper/UtilHelper";

const ConsumptionCrudPage = () => {
  const { id } = useParams();
  const [products, setProducts] = useState<any[]>([]);
  const [addProductName, setAddProductName] = useState<string>("");

  useEffect(() => {
    consumptionProductCrudApi.getAll().then((response) => {
      const mappedProducts = response["hydra:member"].map((product: any) => {
        return {
          id: product.id,
          label: product.name,
          value: product.name,
        };
      });
      setProducts(mappedProducts);
    });
  }, []);

  const getCompanyConsumptions = () => {
    return id
      ? consumptionExtendedApi.getCompanyConsumptions(parseInt(id))
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const addProduct = () => {
    consumptionProductCrudApi
      .create({ name: addProductName })
      .then((response) => {
        setProducts([
          ...products,
          {
            id: response.id,
            label: response.name,
            value: response.name,
          } as never,
        ]);
        setAddProductName("");
      });
  };

  const deleteProduct = (id: number) => {
    consumptionProductCrudApi
      .delete(id)
      .then(() => setProducts(products.filter((product) => product.id !== id)));
  };

  const formItems = getConsumptionCrudPageFormItems(
    id,
    products,
    addProduct,
    addProductName,
    setAddProductName,
    deleteProduct
  );

  return (
    <CrudTable
      entityLabel="Sarfiyat"
      getAll={getCompanyConsumptions}
      api={consumptionCrudApi}
      searchKeys={["productName"]}
      columns={getConsumptionCrudPageColumns()}
      addFormItems={formItems}
      editFormItems={formItems}
      extendedAddFormOnCancel={() => setAddProductName("")}
      extendedEditFormOnCancel={() => setAddProductName("")}
      extendedDeleteFormOnCancel={() => setAddProductName("")}
      afterRefreshOperation={() => setAddProductName("")}
      setEditFields={(row) => {
        return {
          date: getDateFromString(row.date),
        };
      }}
    />
  );
};

export default ConsumptionCrudPage;
