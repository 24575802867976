import { Form, Input } from "antd";
import { Rules } from "../../core/Rules";

export const getVaccineCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Adı",
      dataIndex: "name",
      key: "name",
    },
  ];
};

export const getVaccineCPFormItems = () => {
  return (
    <>
      <Form.Item
        label="Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(100)]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
