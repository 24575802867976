import Icon from "@ant-design/icons";
const SpermIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1024 1024"
        enableBackground="new 0 0 1000 1000"
      >
        <g>
          <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
            <path d="M8370.4,4111.2c-1059.2-230.9-2083.7-894.7-2412.7-1561.3c-106.8-213.6-112.5-239.5-115.4-519.5l-2.9-294.4L5657.6,1586c-210.7-176-280-308.8-239.5-461.8c23.1-98.1,17.3-106.8-170.3-311.7c-409.8-444.5-718.6-935.1-857.1-1365.1c-167.4-516.6-666.7-1056.3-1223.7-1321.8c-236.7-115.4-369.4-147.2-831.2-204.9c-900.4-109.7-1881.7-834.1-2190.5-1616.2c-66.4-167.4-60.6-230.9,23.1-230.9c37.5,0,86.6,66.4,167.4,222.2c204.9,406.9,707.1,862.9,1206.3,1090.9c314.6,147.2,606.1,216.5,1018.8,242.4c533.9,37.5,857.1,153,1307.4,467.5c453.1,317.5,782.1,724.4,1010.1,1243.9c144.3,331.9,314.6,582.9,620.5,912c265.5,285.7,297.3,303,415.6,262.6c141.4-49.1,274.2-8.7,479.1,150.1l187.6,144.3l138.5-49.1c551.2-190.5,1261.2,60.6,2031.8,718.6C9389.2,2027.5,9900,2873.1,9900,3384c0,308.8-164.5,562.8-438.7,686.9c-129.9,57.7-207.8,69.3-528.1,75C8690.8,4148.8,8491.6,4137.2,8370.4,4111.2z" />
          </g>
        </g>
      </svg>
    )}
    {...props}
  />
);

export default SpermIcon;
