import { Button, Typography } from "antd";
import { toast } from "react-toastify";
import { userBonusCrudApi } from "../../client/EntityApi";
import { AuthHelper } from "../../core/helper/AuthHelper";

export const getPerformanceReportCPColumns = (
  setWorkList: (value: any[]) => void,
  setWorkListDialogIsOpen: (isOpen: boolean) => void,
  refreshData: () => void
) => {
  const { Paragraph } = Typography;

  const workListColumnAction = (value: any[]) => {
    setWorkList(value);
    setWorkListDialogIsOpen(true);
  };

  const renderWorkListColumn = (value: any, row: any, columnName: string) => {
    return (
      <Button
        onClick={() =>
          value > 0
            ? workListColumnAction(row[columnName])
            : toast.error("Görüntülenmek istenen iş listesi boş.")
        }
        type="link"
      >
        {value}
      </Button>
    );
  };

  const editableFieldOnChange = (id: any, value: any, key: string) => {
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue)) {
      userBonusCrudApi
        .edit(id, { [key]: parsedValue })
        .then(() => refreshData());
    } else {
      toast.error("Lütfen sayı giriniz.");
    }
  };

  return [
    {
      title: "No",
      dataIndex: "workerId",
    },
    {
      title: "Personel",
      dataIndex: "fullName",
    },
    {
      title: "Atanan",
      dataIndex: "assignedCategoriesCount",
      key: "assignedCategoriesCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "assignedCategoriesList"),
    },
    {
      title: "Onaylanmış",
      dataIndex: "doneCategoriesCount",
      key: "doneCategoriesCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "doneCategoriesList"),
    },
    {
      title: "Henüz Yapılmamış",
      dataIndex: "notDoneCategoriesCount",
      key: "notDoneCategoriesCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "notDoneCategoriesList"),
    },
    {
      title: "Reddilen",
      dataIndex: "rejectedTaskCount",
      key: "rejectedTaskCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "rejectedTaskList"),
    },
    {
      title: "Düzeltme Bekleyen",
      dataIndex: "waitForEditTaskCount",
      key: "waitForEditTaskCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "waitForEditTaskList"),
    },
    {
      title: "Değerlendirme Bekleyen",
      dataIndex: "waitForApproveTaskCount",
      key: "waitForApproveTaskCount",
      render: (value: any, row: any) =>
        renderWorkListColumn(value, row, "waitForApproveTaskList"),
    },
    {
      title: "Prim Oranı",
      key: "primRatio",
      dataIndex: "primRatio",
      render: (value: any, row: any) =>
        AuthHelper.checkVisibility("Prim Oranı Güncelleme") ? (
          <Paragraph
            editable={{
              onChange: (value: any) =>
                editableFieldOnChange(row.userBonusId, value, "primRatio"),
            }}
          >
            %{value}
          </Paragraph>
        ) : (
          <>%{value}</>
        ),
    },
    {
      title: "Bonus",
      key: "bonus",
      dataIndex: "bonus",
      render: (value: any, row: any) =>
        AuthHelper.checkVisibility("Bonus Güncelleme") ? (
          <Paragraph
            editable={{
              onChange: (value: any) =>
                editableFieldOnChange(row.userBonusId, value, "bonus"),
            }}
          >
            {value}
          </Paragraph>
        ) : (
          <>{value}</>
        ),
    },
    {
      title: "Başarı Oranı",
      dataIndex: "successRatio",
      key: "successRatio",
    },
    {
      title: "Hakedilen Prim",
      dataIndex: "userPrim",
      key: "userPrim",
    },
  ];
};
