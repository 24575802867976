import { Form, Input, Select } from "antd";
import moment from "moment";
import { Rules } from "../../core/Rules";
import { getDateString } from "../../core/helper/UtilHelper";
import DatePick from "../../core/component/DatePick";

export const getNotificationCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kullanıcı",
      key: "workerFullname",
      render: (text: any, row: any) =>
        row.worker.name + " " + row.worker.surname,
    },
    {
      title: "Başlık",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Uyarı Tarihi",
      dataIndex: "notificationDate",
      key: "notificationDate",
      render: (value: any) => getDateString(value),
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.notificationDate).unix() - moment(b.notificationDate).unix(),
      },
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      },
    },
    {
      title: "Okunma Tarihi",
      dataIndex: "readDate",
      key: "readDate",
      render: (value: any) => (value ? getDateString(value) : "Okunmamış"),
    },
  ];
};

export const getNotificationCPFormItems = (
  users: any[],
  isAddForm?: boolean
) => {
  const { TextArea } = Input;
  return (
    <>
      <Form.Item label="Kullanıcı" name="workerId" rules={[Rules.required]}>
        <Select
          mode={isAddForm ? "multiple" : undefined}
          allowClear={isAddForm}
          options={users}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="Başlık"
        name="title"
        rules={[Rules.required, Rules.min(3), Rules.max(100)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Açıklama" name="description" rules={[Rules.max(255)]}>
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Uyarı Tarihi"
        name="notificationDate"
        rules={[Rules.required]}
      >
        <DatePick />
      </Form.Item>
      <Form.Item label="Okunma Tarihi" name="readDate">
        <DatePick />
      </Form.Item>
    </>
  );
};
