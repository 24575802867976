import { Form, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { ExpandableConfig, TableRowSelection } from "antd/lib/table/interface";
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SearchBar from "../SearchBar";
import CrudPageProcessButton from "./CrudPageProcessButton";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CrudPageDeleteModal from "./CrudPageDeleteModal";
import CrudPageAddModal from "./CrudPageAddModal";
import CrudPageEditModal from "./CrudPageEditModal";
import CrudPageTitleRow from "./CrudPageTitleRow";

interface ICrudTableProps {
  entityLabel: string;
  api: any;
  getAll?: () => any;
  post?: (values: any) => any;
  expandable?: ExpandableConfig<never>;
  columns: ColumnType<never>[];
  searchKeys?: string[];
  afterRefreshOperation?: (data: any[]) => void;
  addFormItems?: ReactNode;
  editFormItems?: ReactNode;
  modalWidths?: string | number;
  setEditFields?: (row: any) => any;
  beforeAddOperation?: (values: any) => any;
  afterAddOperation?: () => void;
  beforeEditOperation?: (values: any) => any;
  afterEditOperation?: () => void;
  addModalOkText?: string;
  editModalOkText?: string;
  deleteModalOkText?: string;
  addModalCancelText?: string;
  editModalCancelText?: string;
  deleteModalCancelText?: string;
  addModalMaskClosable?: boolean;
  editModalMaskClosable?: boolean;
  deleteModalMaskClosable?: boolean;
  deleteModalMessage?: string;
  extraTitleProcess?: () => ReactNode;
  extraRowProcess?: (row: any) => ReactNode;
  customTableTitle?: () => ReactNode;
  customTableMiddleCol?: () => ReactNode;
  hideTableTitle?: (() => boolean) | boolean;
  hideSearchBar?: (() => boolean) | boolean;
  hideDefaultTitleProcess?: (() => boolean) | boolean;
  hideAddProcess?: (() => boolean) | boolean;
  hideRefreshProcess?: (() => boolean) | boolean;
  hideDefaultRowProceses?: ((row: any) => boolean) | boolean;
  hideEditProcess?: ((row: any) => boolean) | boolean;
  hideDeleteProcess?: ((row: any) => boolean) | boolean;
  extendedAddFormOnCancel?: () => void;
  extendedEditFormOnCancel?: () => void;
  extendedDeleteFormOnCancel?: () => void;
  rowSelection?: TableRowSelection<never>;
}

export interface ICrudTableRefMethods {
  refreshData: () => void;
  addForm: any;
  editForm: any;
}

const CrudTable = forwardRef((props: ICrudTableProps, ref) => {
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [entities, setEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [addDialogIsOpen, setAddDialogIsOpen] = useState<boolean>(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  const [addFormInstance] = Form.useForm();
  const [editFormInstance] = Form.useForm();

  const refreshData = () => {
    setDataLoading(true);
    const getAll = props.getAll ?? props.api.getAll;
    getAll()
      .then((response: any) => {
        setEntities(response["hydra:member"]);
        setFilteredEntities(response["hydra:member"]);
        if (props.afterRefreshOperation) {
          props.afterRefreshOperation(response["hydra:member"]);
        }
      })
      .finally(() => setDataLoading(false));
  };

  useImperativeHandle(ref, () => ({
    refreshData: refreshData,
    addForm: addFormInstance,
    editForm: editFormInstance,
  }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => refreshData(), []);

  const checkVisibility = (
    condition?: ((row?: any) => boolean) | boolean,
    conditionParameters?: any
  ) => {
    if (condition) {
      if (typeof condition === "boolean") {
        return !condition;
      }

      return !(conditionParameters
        ? condition(conditionParameters)
        : condition());
    }

    return true;
  };

  const rowProcessRender = (row: any) => {
    return (
      <>
        {props.extraRowProcess ? props.extraRowProcess(row) : <></>}
        {checkVisibility(props.hideDefaultRowProceses, row) && (
          <>
            {checkVisibility(props.hideEditProcess, row) && (
              <CrudPageProcessButton
                tooltipText={props.entityLabel + " Düzenle"}
                icon={<EditOutlined />}
                onClick={() => {
                  props.setEditFields
                    ? editFormInstance.setFieldsValue({
                        ...row,
                        ...props.setEditFields(row),
                      })
                    : editFormInstance.setFieldsValue(row);
                  setSelectedId(row.id);
                  setEditDialogIsOpen(true);
                }}
              />
            )}
            {checkVisibility(props.hideDeleteProcess, row) && (
              <CrudPageProcessButton
                tooltipText={props.entityLabel + " Sil"}
                icon={<DeleteOutlined />}
                onClick={() => {
                  setSelectedId(row.id);
                  setDeleteDialogIsOpen(true);
                }}
              />
            )}
          </>
        )}
      </>
    );
  };

  const titleProcessRender = (props: ICrudTableProps) => {
    return (
      <>
        {props.extraTitleProcess ? props.extraTitleProcess() : <></>}
        {checkVisibility(props.hideDefaultTitleProcess) && (
          <>
            {checkVisibility(props.hideRefreshProcess) && (
              <CrudPageProcessButton
                tooltipText={"Yeniden Yükle"}
                icon={<ReloadOutlined />}
                onClick={() => refreshData()}
              />
            )}
            {checkVisibility(props.hideAddProcess) && (
              <CrudPageProcessButton
                tooltipText={props.entityLabel + " Ekle"}
                icon={<PlusOutlined />}
                onClick={() => setAddDialogIsOpen(true)}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Table
        rowKey="id"
        loading={dataLoading}
        dataSource={filteredEntities}
        expandable={props.expandable ? props.expandable : undefined}
        scroll={{ x: "100vw" }}
        style={{ padding: "10px" }}
        rowSelection={props.rowSelection}
        columns={[
          ...props.columns,
          {
            title: "İşlemler",
            render: rowProcessRender,
          },
        ]}
        title={() => (
          <CrudPageTitleRow
            firstCol={
              props.customTableTitle
                ? props.customTableTitle()
                : checkVisibility(props.hideTableTitle) && (
                    <b>{props.entityLabel + " Listesi"}</b>
                  )
            }
            secondCol={
              props.customTableMiddleCol
                ? props.customTableMiddleCol()
                : checkVisibility(props.hideSearchBar) && (
                    <SearchBar
                      searchKeys={props.searchKeys ?? []}
                      entities={entities}
                      setFilteredEntities={setFilteredEntities}
                      placeholder={props.entityLabel + " Ara"}
                    />
                  )
            }
            thirdCol={titleProcessRender(props)}
          />
        )}
      />
      <CrudPageAddModal
        width={props.modalWidths}
        maskClosable={props.addModalMaskClosable}
        okText={props.addModalOkText}
        cancelText={props.addModalCancelText}
        isOpen={addDialogIsOpen}
        setIsOpen={setAddDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.post ? props.post : props.api.create}
        entityLabel={props.entityLabel}
        formInstance={addFormInstance}
        formItems={props.addFormItems}
        beforeOperation={props.beforeAddOperation}
        afterOperation={props.afterAddOperation}
        extendedOnCancel={props.extendedAddFormOnCancel}
      />
      <CrudPageEditModal
        width={props.modalWidths}
        maskClosable={props.editModalMaskClosable}
        okText={props.editModalOkText}
        cancelText={props.editModalCancelText}
        isOpen={editDialogIsOpen}
        setIsOpen={setEditDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.api.edit}
        entityLabel={props.entityLabel}
        formInstance={editFormInstance}
        formItems={props.editFormItems}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        beforeOperation={props.beforeEditOperation}
        afterOperation={props.afterEditOperation}
        extendedOnCancel={props.extendedEditFormOnCancel}
      />
      <CrudPageDeleteModal
        maskClosable={props.deleteModalMaskClosable}
        okText={props.deleteModalOkText}
        cancelText={props.deleteModalCancelText}
        isOpen={deleteDialogIsOpen}
        setIsOpen={setDeleteDialogIsOpen}
        refreshData={refreshData}
        endpoint={props.api.delete}
        entityLabel={props.entityLabel}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        deleteModalMessage={props.deleteModalMessage}
        extendedOnCancel={props.extendedDeleteFormOnCancel}
      />
    </>
  );
});

export default CrudTable;
