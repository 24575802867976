import { Form, Input, InputNumber, Row } from "antd";
import React from "react";
import DatePick from "../../core/component/DatePick";
import { formItemStyle } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import TableCell from "./TableCell";

interface IRationAnalyseTableProps {
  name: string;
  form: any;
}

const RationAnalyseTable = (props: IRationAnalyseTableProps) => {
  const computePrice = () => {
    let price = 0;

    const materials = props.form.getFieldValue([
      "rationTables",
      props.name,
      "materials",
    ]);
    materials.forEach((rawMaterial: any) => {
      price += rawMaterial.price * rawMaterial.quantity;
    });

    const rationTables = props.form.getFieldValue("rationTables");
    rationTables[props.name].rationPrice = price;
    props.form.setFieldsValue({ rationTables: rationTables });
  };

  const computeMealAndDay = (index: number) => {
    const mealCount = props.form.getFieldValue([
      "rationTables",
      props.name,
      "mealCount",
    ]);

    const animalCount = props.form.getFieldValue([
      "rationTables",
      props.name,
      "animalCount",
    ]);

    const quantity = props.form.getFieldValue([
      "rationTables",
      props.name,
      "materials",
      index,
      "quantity",
    ]);

    const rationTables = props.form.getFieldValue("rationTables");
    rationTables[props.name]["materials"][index]["meal"] =
      mealCount === 0 ? 0 : (animalCount * quantity) / mealCount;
    rationTables[props.name]["materials"][index]["day"] =
      animalCount * quantity;
    props.form.setFieldsValue({ rationTables: rationTables });
  };

  const computeMealAndDayAll = () => {
    const materials = props.form.getFieldValue([
      "rationTables",
      props.name,
      "materials",
    ]);

    materials.forEach((material: any, index: number) => {
      computeMealAndDay(index);
    });
  };

  return (
    <>
      <Row style={{ border: "1px solid black" }}>
        <TableCell colStyle={{ background: "#ddd" }}>
          <b>RASYON TABLOSU</b>
        </TableCell>
        <TableCell span={12}>Çiftlik Adı</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "companyName"]}
            rules={[Rules.required]}
          >
            <Input />
          </Form.Item>
        </TableCell>
        <TableCell span={12}>Rasyonu Yapan</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "user"]}
            rules={[Rules.required]}
          >
            <Input />
          </Form.Item>
        </TableCell>
        <TableCell span={12}>Rasyon Mevcut/Tavsiye</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "rationState"]}
            rules={[Rules.required]}
          >
            <Input />
          </Form.Item>
        </TableCell>
        <TableCell span={12}>Rasyon Grubu</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "group"]}
            rules={[Rules.required]}
          >
            <Input />
          </Form.Item>
        </TableCell>

        <TableCell colProps={{ xs: 12, md: 6 }}>Hayvan Sayısı</TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "animalCount"]}
            initialValue={0}
            rules={[Rules.required]}
          >
            <InputNumber
              min={0}
              onChange={() => computeMealAndDayAll()}
              decimalSeparator=","
            />
          </Form.Item>
        </TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>Öğün Sayısı</TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "mealCount"]}
            initialValue={2}
            rules={[Rules.required]}
          >
            <InputNumber
              min={0}
              onChange={() => computeMealAndDayAll()}
              decimalSeparator=","
            />
          </Form.Item>
        </TableCell>

        <TableCell colProps={{ xs: 12, md: 6 }}>Güncel Süt Verisi</TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "milkData"]}
            initialValue={0}
          >
            <InputNumber min={0} decimalSeparator="," />
          </Form.Item>
        </TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>
          120 Günden Küçük Buzağı Sayısı
        </TableCell>
        <TableCell colProps={{ xs: 12, md: 6 }}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "calfCount"]}
            initialValue={0}
          >
            <InputNumber min={0} decimalSeparator="," />
          </Form.Item>
        </TableCell>

        <TableCell span={12}>Rasyon Maliyeti</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "rationPrice"]}
            initialValue={0}
          >
            <Input disabled />
          </Form.Item>
        </TableCell>
        <TableCell span={12}>Tarih</TableCell>
        <TableCell span={12}>
          <Form.Item
            style={formItemStyle}
            name={[props.name, "date"]}
            rules={[Rules.required]}
          >
            <DatePick />
          </Form.Item>
        </TableCell>
        {[...Array(15)].map((_x, index) => (
          <React.Fragment key={index}>
            <TableCell colStyle={{ background: "#ddd" }}>
              Hammadde ({index + 1})
            </TableCell>
            <TableCell span={12}>Hammadde Adı</TableCell>
            <TableCell span={12}>
              <Form.Item
                style={formItemStyle}
                name={[props.name, "materials", index, "materialName"]}
              >
                <Input />
              </Form.Item>
            </TableCell>
            <TableCell span={12}>Maliyet (kg)</TableCell>
            <TableCell span={12}>
              <Form.Item
                style={formItemStyle}
                name={[props.name, "materials", index, "price"]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  onChange={() => {
                    computePrice();
                    computeMealAndDay(index);
                  }}
                  decimalSeparator=","
                />
              </Form.Item>
            </TableCell>
            <TableCell span={12}>Miktar (kg)</TableCell>
            <TableCell span={12}>
              <Form.Item
                style={formItemStyle}
                name={[props.name, "materials", index, "quantity"]}
                initialValue={0}
              >
                <InputNumber
                  min={0}
                  onChange={() => {
                    computePrice();
                    computeMealAndDay(index);
                  }}
                  decimalSeparator=","
                />
              </Form.Item>
            </TableCell>
            <TableCell span={12}>Öğünde</TableCell>
            <TableCell span={12}>
              <Form.Item
                style={formItemStyle}
                name={[props.name, "materials", index, "meal"]}
                initialValue={0}
              >
                <InputNumber disabled decimalSeparator="," />
              </Form.Item>
            </TableCell>
            <TableCell span={12}>Günde</TableCell>
            <TableCell span={12}>
              <Form.Item
                style={formItemStyle}
                name={[props.name, "materials", index, "day"]}
                initialValue={0}
              >
                <InputNumber disabled decimalSeparator="," />
              </Form.Item>
            </TableCell>
          </React.Fragment>
        ))}
      </Row>
    </>
  );
};

export default RationAnalyseTable;
