import { Row, Tag, Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ReactNode } from "react";

interface ILargeTagProps {
  color?: string;
  size?: number;
  minWidth?: number;
  text?: string;
  children?: ReactNode;
  tagStyle?: object;
  tooltipText?: string;
  tooltipPlacement?: TooltipPlacement;
  onClick?: () => void;
}

const LargeTag = (props: ILargeTagProps) => {
  return (
    <Tooltip
      title={props.tooltipText}
      placement={props.tooltipPlacement ?? "top"}
    >
      <Tag
        color={props.color ?? "green"}
        style={{ ...props.tagStyle }}
        onClick={props.onClick}
      >
        <Row
          justify="center"
          align="middle"
          style={{ height: props.size ?? 35, minWidth: props.minWidth ?? 100 }}
        >
          {props.children} {props.text}
        </Row>
      </Tag>
    </Tooltip>
  );
};

export default LargeTag;
