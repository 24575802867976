import { Form, Select, Switch } from "antd";
import YesNoTag from "../../core/component/YesNoTag";
import { getMonthString } from "../../core/helper/UtilHelper";

export const getPeriodCPColumns = () => {
  return [
    {
      title: "No",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "Ay",
      key: "month",
      dataIndex: "month",
      render: (value: any) => getMonthString(value),
    },
    {
      title: "Yıl",
      key: "year",
      dataIndex: "year",
    },
    {
      title: "Aktif Mi ?",
      key: "isActive",
      dataIndex: "isActive",
      render: (value: any) => <YesNoTag success={value} />,
    },
    {
      title: "Görünür Mü ?",
      key: "isVisible",
      dataIndex: "isVisible",
      render: (value: any) => <YesNoTag success={value} />,
    },
  ];
};

export const getPeriodCPAddFormItems = () => {
  const currentDate = new Date();
  return (
    <>
      <Form.Item
        label="Ay"
        name="month"
        rules={[{ required: true, message: "Ay bilgisi boş bırakılamaz." }]}
        initialValue={(currentDate.getMonth() + 1).toString()}
      >
        <Select
          placeholder="Ay Seçiniz"
          options={[
            { label: "Ocak", value: "1" },
            { label: "Şubat", value: "2" },
            { label: "Mart", value: "3" },
            { label: "Nisan", value: "4" },
            { label: "Mayıs", value: "5" },
            { label: "Haziran", value: "6" },
            { label: "Temmuz", value: "7" },
            { label: "Ağustos", value: "8" },
            { label: "Eylül", value: "9" },
            { label: "Ekim", value: "10" },
            { label: "Kasım", value: "11" },
            { label: "Aralık", value: "12" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Yıl"
        name="year"
        rules={[{ required: true, message: "Yıl bilgisi boş bırakılamaz." }]}
        initialValue={currentDate.getFullYear().toString()}
      >
        <Select
          placeholder="Yıl Seçiniz"
          options={[
            { label: "2021", value: "2021" },
            { label: "2022", value: "2022" },
            { label: "2023", value: "2023" },
            { label: "2024", value: "2024" },
            { label: "2025", value: "2025" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="isActive"
        label="Dönem Aktif Mi ?"
        initialValue={true}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Dönem Görünür Mü ?"
        name="isVisible"
        initialValue={true}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
};

export const getPeriodCPEditFormItems = () => {
  return (
    <>
      <Form.Item
        name="isActive"
        label="Dönem Aktif Mi ?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="isVisible"
        label="Dönem Görünür Mü ?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
};
