import Icon from "@ant-design/icons";
const SyringeIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        viewBox="0 0 512 512"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="6330"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M4580 4789 c-14 -5 -259 -244 -545 -529 l-520 -520 -120 121 c-66 66
     -132 127 -147 135 -45 24 -108 18 -159 -15 -24 -16 -116 -101 -204 -190 l-160
     -161 -62 65 c-88 92 -142 112 -221 80 -26 -11 -260 -238 -777 -755 l-740 -740
     -65 66 c-83 84 -109 97 -177 92 -42 -4 -60 -11 -84 -34 -60 -55 -74 -126 -40
     -192 11 -20 123 -140 250 -267 l231 -230 -107 -107 c-59 -60 -112 -108 -118
     -108 -6 0 -64 54 -130 120 -132 133 -162 150 -234 130 -97 -26 -146 -122 -110
     -217 13 -37 1143 -1169 1191 -1194 125 -64 264 48 227 183 -8 33 -36 68 -137
     170 l-126 129 109 109 110 110 235 -234 c129 -129 248 -240 263 -245 127 -48
     251 62 217 192 -10 37 -82 113 -641 672 l-629 630 168 168 167 167 175 -176
     c96 -96 188 -182 204 -191 66 -35 159 -9 203 57 27 39 31 116 9 158 -8 15 -94
     106 -190 202 l-176 175 112 112 113 113 185 -184 c199 -198 213 -207 297 -191
     49 9 109 69 118 118 16 84 7 98 -191 297 l-184 186 168 167 167 167 368 -368
     367 -367 -70 -34 c-150 -73 -280 -205 -361 -366 -58 -117 -90 -249 -88 -363
     l2 -73 -230 -232 c-202 -202 -233 -238 -243 -277 -38 -143 115 -253 241 -171
     24 16 111 96 193 179 l149 151 40 -57 c149 -217 386 -343 642 -343 496 0 883
     474 790 966 -58 308 -289 562 -584 644 -39 11 -76 23 -82 27 -6 4 59 77 164
     183 96 97 182 190 191 208 20 40 21 105 2 141 -8 15 -69 81 -135 147 l-121
     120 524 525 c567 567 548 545 532 635 -17 91 -127 149 -216 114z m-1175 -1389
     l220 -220 -113 -112 -112 -113 -222 222 -223 223 110 110 c60 60 112 110 115
     110 3 0 104 -99 225 -220z m220 -1015 c192 -50 338 -210 368 -404 36 -233
     -121 -464 -358 -526 -268 -69 -545 117 -588 395 -51 328 261 617 578 535z
     m-2242 -1227 c-59 -60 -112 -108 -118 -108 -13 0 -215 202 -215 215 0 5 48 58
     107 117 l108 108 112 -112 113 -113 -107 -107z"
          />
          <path
            d="M3453 2200 c-44 -19 -79 -64 -90 -112 -4 -19 -13 -29 -32 -33 -81
     -17 -137 -94 -128 -173 3 -24 14 -56 24 -71 27 -35 81 -71 108 -71 18 0 24 -7
     29 -35 13 -69 83 -125 156 -125 73 0 143 56 156 125 5 28 11 35 29 35 27 0 81
     36 108 71 63 85 5 220 -104 244 -19 4 -28 14 -32 33 -15 70 -89 132 -157 132
     -14 0 -44 -9 -67 -20z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default SyringeIcon;
