import ColoredTag from "../../core/component/ColoredTag";
import { getDateString } from "../../core/helper/UtilHelper";

export const getTaskApproveCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Personel",
      dataIndex: "worker",
      key: "worker",
      render: (value: any) => value.name + " " + value.surname,
    },
    {
      title: "İşletme Adı",
      dataIndex: "taskCategory",
      key: "companyName",
      render: (value: any) => value.company.name,
    },
    {
      title: "Görev Türü",
      dataIndex: "taskCategory",
      key: "serviceName",
      render: (value: any) => value.service.name,
    },
    {
      title: "Bildiri Durumu",
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (value: any, row: any) => (
        <ColoredTag
          selected={value}
          baseText={
            row.evaluator
              ? row.evaluator.name +
                " " +
                row.evaluator.surname +
                " Tarafından "
              : undefined
          }
          options={[
            { key: "approved", color: "green", text: "Onaylandı" },
            {
              key: "waitForApprove",
              color: "geekblue",
              text: "Değerlendirme Bekliyor",
            },
            { key: "waitForEdit", color: "orange", text: "Düzeltme İstendi" },
            { key: "rejected", color: "red", text: "Reddedildi" },
          ]}
        />
      ),
    },
    {
      title: "Tarih",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
    },
  ];
};
