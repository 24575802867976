import { Modal } from "antd";
import { periodCrudApi, periodCrudExtendedApi } from "../client/EntityApi";
import {
  getPeriodCPAddFormItems,
  getPeriodCPColumns,
  getPeriodCPEditFormItems,
} from "../components/crud/PeriodCPComponents";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { UnorderedListOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";

const PeriodCrudPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);
  const [periodId, setPeriodId] = useState();
  const [workSheetCreateDialogIsOpen, setWorkSheetCreateDialogIsOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const workSheetCreateDialogOnCancel = () => {
    setPeriodId(undefined);
    setWorkSheetCreateDialogIsOpen(false);
    setLoading(false);
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Dönem"
        api={periodCrudApi}
        columns={getPeriodCPColumns()}
        addFormItems={getPeriodCPAddFormItems()}
        editFormItems={getPeriodCPEditFormItems()}
        hideSearchBar
        extraRowProcess={(row: any) => {
          return (
            <>
              <CrudPageProcessButton
                tooltipText="İş Listesi Oluştur"
                disabled={row.isWorkSheetCreated}
                icon={<UnorderedListOutlined />}
                onClick={() => {
                  setPeriodId(row.id);
                  setWorkSheetCreateDialogIsOpen(true);
                }}
              />
            </>
          );
        }}
      />
      <Modal
        visible={workSheetCreateDialogIsOpen}
        title="İş Listesi Oluştur"
        onOk={() => {
          if (periodId) {
            setLoading(true);
            periodCrudExtendedApi
              .createWorkSheet(periodId)
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => workSheetCreateDialogOnCancel());
          }
        }}
        onCancel={workSheetCreateDialogOnCancel}
        okText="Oluştur"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        Bu dönem için oluşturulan iş listesi şuanki aktif olan işletmeleri baz
        alacaktır. Olası bir güncelleme istemeniz durumunda bu dönemi silip
        tekrardan oluşturmanız gerekmektedir. Dönemi sildiğiniz taktirde o dönem
        ile alakalı yapılmış tüm işlerde beraberinde silinecektir. Hala devam
        etmek istediğinize emin misiniz ?
      </Modal>
    </>
  );
};

export default PeriodCrudPage;
