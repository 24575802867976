import Icon from "@ant-design/icons";
const UltrasoundIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        enableBackground="new 0 0 1000 1000"
        viewBox="0 0 512 512"
      >
        <g
          transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M3792 5083 c-91 -44 -135 -159 -97 -251 23 -55 48 -76 178 -155 354
-214 605 -461 818 -803 34 -55 71 -110 83 -122 34 -38 90 -62 146 -62 146 0
240 145 181 277 -49 106 -230 361 -384 540 -157 183 -510 455 -738 569 -56 29
-137 32 -187 7z"
          />
          <path
            d="M3455 4365 c-100 -54 -141 -164 -96 -260 21 -46 47 -71 151 -145 211
-150 351 -299 502 -535 75 -116 117 -145 209 -145 92 0 164 57 189 148 19 74
0 126 -108 282 -176 256 -344 426 -590 597 -92 65 -99 68 -162 71 -46 2 -74
-2 -95 -13z"
          />
          <path
            d="M1999 4040 c-20 -11 -48 -32 -61 -48 -13 -16 -66 -141 -118 -278
-239 -638 -414 -1085 -451 -1149 -60 -106 -89 -141 -554 -690 -237 -278 -451
-537 -477 -575 -133 -197 -153 -474 -48 -679 l22 -44 -141 -141 c-85 -85 -146
-156 -156 -178 -32 -79 -8 -169 61 -226 37 -31 42 -32 127 -32 l89 0 153 150
152 151 50 -20 c182 -71 382 -65 558 18 95 45 104 52 508 395 321 273 342 294
354 371 14 86 -50 188 -133 213 -110 33 -119 27 -512 -306 -191 -162 -367
-304 -392 -316 -33 -15 -65 -21 -121 -21 -64 0 -84 4 -134 30 -138 71 -193
242 -122 381 14 27 222 281 462 564 240 283 461 547 490 586 l54 71 278 -281
c436 -442 518 -521 556 -539 78 -38 89 -34 783 223 355 132 656 245 667 251
34 18 78 62 89 89 25 61 26 95 8 219 -74 506 -323 1008 -639 1291 -287 257
-694 446 -1125 521 -140 25 -229 24 -277 -1z m520 -471 c130 -45 323 -136 421
-199 119 -77 241 -183 310 -270 189 -238 305 -496 384 -851 3 -14 -93 -53
-502 -205 l-506 -187 -387 394 c-214 217 -388 398 -389 403 0 5 84 230 186
501 l186 492 90 -19 c50 -11 143 -37 207 -59z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default UltrasoundIcon;
