import { Col, Grid, Row } from "antd";
import { ReactNode } from "react";

interface ICrudPageTitleRowProps {
  firstCol?: ReactNode;
  secondCol?: ReactNode;
  thirdCol?: ReactNode;
}

const CrudPageTitleRow = (props: ICrudPageTitleRowProps) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <>
      <Row gutter={[0, 8]}>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Row
            style={{ height: "100%" }}
            justify={screens.lg ? "start" : "center"}
            align="middle"
          >
            {props.firstCol}
          </Row>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Row justify="center">{props.secondCol}</Row>
        </Col>
        <Col xs={24} md={24} lg={8} xl={8}>
          <Row justify={screens.lg ? "end" : "center"} align="middle">
            {props.thirdCol}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CrudPageTitleRow;
