import { Menu } from "antd";
import { useState } from "react";

const NavigationMenu = (props: any) => {
  const [current, setCurrent] = useState("");
  return (
    <Menu
      onClick={(e) => setCurrent(e.key)}
      mode="inline"
      items={props.items}
      selectedKeys={[current]}
    />
  );
};

export default NavigationMenu;
