import YesNoTag from "../../core/component/YesNoTag";
import { Form, Input, Select, Switch } from "antd";
import { Rules } from "../../core/Rules";

export const getUserCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İsim",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Soyisim",
      dataIndex: "surname",
      key: "surname",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Aktif",
      dataIndex: "isPassive",
      key: "isPassive",
      render: (value: any) => <YesNoTag success={!value} />,
      filters: [
        {
          text: "Pasif",
          value: true,
        },
        {
          text: "Aktif",
          value: false,
        },
      ],
      defaultFilteredValue: [false],
      onFilter: (value: any, record: any) => record.isPassive === value,
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
      render: (value: any) => <>{value.name}</>,
    },
  ];
};

export const getUserCPFormItems = (
  roles: any[],
  visibilities: any[],
  isAddForm?: boolean
) => {
  return (
    <>
      <Form.Item
        label="İsim"
        name="name"
        rules={[Rules.required, Rules.min(3)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Soyisim"
        name="surname"
        rules={[Rules.required, Rules.min(3)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="K. Adı"
        name="username"
        rules={[Rules.required, Rules.min(3)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Şifre"
        name="plainPassword"
        rules={isAddForm ? [Rules.required, Rules.min(4)] : [Rules.min(4)]}
      >
        <Input.Password
          placeholder={
            isAddForm ? "" : "Şifreyi değiştirmek istemiyorsanız boş bırakın."
          }
        />
      </Form.Item>
      <Form.Item label="Rol" name="role" rules={[Rules.required]}>
        <Select options={roles} />
      </Form.Item>
      <Form.Item
        label="Görünürlükler"
        name="visibilities"
        rules={[Rules.required]}
      >
        <Select options={visibilities} mode="multiple" />
      </Form.Item>
      <Form.Item
        name="isPassive"
        label="Kullanıcı Pasif Mi ?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
};
