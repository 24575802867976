import { Card, Form, Input, Modal, Tag, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DoneAllIcon from "../../Icons/DoneAllIcon";
import { getDateString } from "../../core/helper/UtilHelper";
import YesNoTag from "../../core/component/YesNoTag";
import { useState } from "react";
import {
  notificationCrudApi,
  notificationCrudExtendedApi,
} from "../../client/EntityApi";
import DatePick from "../../core/component/DatePick";
import { Rules } from "../../core/Rules";
import moment from "moment";
import CrudPageProcessButton from "../../core/component/crud/CrudPageProcessButton";

interface INotificationCardProps {
  notification: any;
  hideActions?: boolean;
  showUser?: boolean;
  updateList?: () => void;
}

const NotificationCard = (props: INotificationCardProps) => {
  const { TextArea } = Input;

  const [notificationEditForm] = Form.useForm();

  const [makeReadModalIsOpen, setMakeReadModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Card
        style={{ margin: "0.5em", borderColor: "#cccccc" }}
        title={props.notification.title}
        size="small"
        extra={
          <Tooltip title="Uyarı Tarihi">
            <Tag>{getDateString(props.notification.notificationDate)}</Tag>
          </Tooltip>
        }
        actions={
          props.hideActions
            ? []
            : [
                <CrudPageProcessButton
                  disabled={props.notification.readDate !== null}
                  tooltipText={
                    props.notification.readDate === null
                      ? "Okundu Yap"
                      : "Okunmuş"
                  }
                  icon={<DoneAllIcon />}
                  onClick={() => setMakeReadModalIsOpen(true)}
                />,
                <CrudPageProcessButton
                  tooltipText="Düzenle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    notificationEditForm.setFieldsValue({
                      title: props.notification.title,
                      description: props.notification.description,
                      notificationDate: moment(
                        props.notification.notificationDate
                      ),
                    });
                    setEditModalIsOpen(true);
                  }}
                />,
                <CrudPageProcessButton
                  tooltipText="Sil"
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleteModalIsOpen(true)}
                />,
              ]
        }
      >
        <p>{props.notification.description}</p>
        <YesNoTag
          success={props.notification.readDate !== null}
          successText={
            getDateString(props.notification.readDate) + " Tarihinde Okundu"
          }
          failText="Okunmamış"
        />
        {props.showUser && (
          <Tag>
            {props.notification.worker.name +
              " " +
              props.notification.worker.surname}
          </Tag>
        )}
      </Card>
      <Modal
        title="Okundu Yap"
        visible={makeReadModalIsOpen}
        onOk={() => {
          setLoading(true);
          notificationCrudExtendedApi
            .markAsRead(props.notification.id)
            .then(() => {
              if (props?.updateList) props.updateList();
            })
            .finally(() => setLoading(false));
        }}
        onCancel={() => setMakeReadModalIsOpen(false)}
        okText="Okundu Yap"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        Bildirimi okundu olarak işaretlemek istediğinize emin misiniz ? Bu işlem
        geri alınamaz.
      </Modal>
      <Modal
        title="Bildiri Düzenle"
        visible={editModalIsOpen}
        onOk={() => {
          notificationEditForm.validateFields().then((values) => {
            setLoading(true);
            notificationCrudExtendedApi
              .edit(props.notification.id, values, true)
              .then(() => {
                if (props?.updateList) props.updateList();
              })
              .finally(() => setLoading(false));
          });
        }}
        onCancel={() => {
          notificationEditForm.resetFields();
          setEditModalIsOpen(false);
        }}
        okText="Düzenle"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        <Form form={notificationEditForm} layout="vertical">
          <Form.Item
            label="Başlık"
            name="title"
            rules={[Rules.required, Rules.min(3), Rules.max(100)]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Açıklama"
            name="description"
            rules={[Rules.max(255)]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="Uyarı Tarihi"
            name="notificationDate"
            rules={[Rules.required]}
          >
            <DatePick />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Bildiri Sil"
        visible={deleteModalIsOpen}
        onOk={() => {
          setLoading(true);
          notificationCrudApi
            .delete(props.notification.id)
            .then(() => {
              if (props?.updateList) props.updateList();
            })
            .finally(() => setLoading(false));
        }}
        onCancel={() => setDeleteModalIsOpen(false)}
        okText="Sil"
        cancelText="Vazgeç"
        confirmLoading={loading}
      >
        Bildirimi silmek istediğinize emin misiniz ? Bu işlem geri alınamaz.
      </Modal>
    </>
  );
};

export default NotificationCard;
