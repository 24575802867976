import { Modal, Typography } from "antd";
import {
  generatedRecipeCrudApi,
  generatedRecipeExtendedApi,
} from "../client/EntityApi";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { CloseOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { getGeneratedRecipeCPColumns } from "../components/crud/GeneratedRecipeCPComponents";

const GeneratedRecipeCrudPage = () => {
  const { Title, Paragraph } = Typography;
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [selectedId, setSelectedId] = useState<number>();
  const [GRBackLoading, setGRBackLoading] = useState<boolean>(false);
  const [GRBackModalIsOpen, setGRBackModalIsOpen] = useState<boolean>(false);

  const onCancel = () => {
    setSelectedId(undefined);
    setGRBackModalIsOpen(false);
    setGRBackLoading(false);
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel="Üretim Geçmişi"
        api={generatedRecipeCrudApi}
        hideSearchBar
        hideAddProcess
        hideDefaultRowProceses
        columns={getGeneratedRecipeCPColumns()}
        extraRowProcess={(row) => {
          return !row.isBack ? (
            <CrudPageProcessButton
              tooltipText="Üretimi İptal Et"
              icon={<CloseOutlined />}
              onClick={() => {
                setSelectedId(row.id);
                setGRBackModalIsOpen(true);
              }}
            />
          ) : (
            <></>
          );
        }}
        expandable={{
          expandedRowRender: (record: any) => (
            <Typography>
              <Title level={5}>Üretim Açıklaması</Title>
              <Paragraph> {record.description}</Paragraph>
            </Typography>
          ),
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={GRBackModalIsOpen}
        title={"Üretimi İptal Et"}
        onOk={() => {
          if (selectedId) {
            setGRBackLoading(true);
            generatedRecipeExtendedApi
              .generateBackRecipe(selectedId)
              .then((response) => {
                crudPageRef.current?.refreshData();
                toast.success(response.message);
              })
              .finally(() => onCancel());
          }
        }}
        onCancel={onCancel}
        okText="İptal Et"
        cancelText="Vazgeç"
        confirmLoading={GRBackLoading}
      >
        Üretimin iptal edilmesi durumunda üretimde kullanılan hammaddeler stoğa
        eklenecektir. Devam etmek istiyor musunuz ?
      </Modal>
    </>
  );
};

export default GeneratedRecipeCrudPage;
