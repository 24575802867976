import { cellStyle, getDateString, mathRound } from "../core/helper/UtilHelper";

const RationTable = (props: any) => {
  return (
    <table
      style={{
        width: "100%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid #dddddd",
        margin: "1em 0px 1em 0px",
      }}
    >
      <thead>
        <tr style={cellStyle}>
          <th colSpan={5} style={{ ...cellStyle, backgroundColor: "#ececec" }}>
            RASYON TABLOSU
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={cellStyle}>
          <th style={cellStyle}>Çiftlik Adı</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.companyName}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Rasyonu Yapan</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.user}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Rasyon Mevcut/Tavsiye</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.rationState}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Rasyon Grubu</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.group}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Hayvan Sayısı</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.animalCount}
          </th>
        </tr>
        <tr>
          <th style={cellStyle}>Öğün Sayısı</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.mealCount}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Güncel Süt Verisi</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.milkData}
          </th>
        </tr>
        <tr>
          <th style={cellStyle}>120 Günden Küçük Buzağı Sayısı</th>
          <th style={cellStyle} colSpan={4}>
            {props.rationTable.calfCount}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Rasyon Maliyeti</th>
          <th style={cellStyle} colSpan={4}>
            {mathRound(props.rationTable.rationPrice)}
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Tarih</th>
          <th style={cellStyle} colSpan={4}>
            {getDateString(props.rationTable.date)}
          </th>
        </tr>
        <tr style={{ ...cellStyle, backgroundColor: "#ececec" }}>
          <th style={cellStyle}>Hammadde Adı</th>
          <th style={cellStyle}>Maliyet (kg)</th>
          <th style={cellStyle}>Miktar (kg)</th>
          <th style={cellStyle}>Öğünde</th>
          <th style={cellStyle}>Günde</th>
        </tr>
        {props.rationTable.materials.map((material: any, index: number) => (
          <tr key={index} style={cellStyle}>
            <th style={cellStyle}>{material.materialName}</th>
            <th style={cellStyle}>{material.price}</th>
            <th style={cellStyle}>{material.quantity}</th>
            <th style={cellStyle}>{mathRound(material.meal)}</th>
            <th style={cellStyle}>{mathRound(material.day)}</th>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RationTable;
