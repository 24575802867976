import { cellStyle, getDateString } from "../core/helper/UtilHelper";

const FeedTable = (props: any) => {
  return (
    <table
      style={{
        width: "100%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid #dddddd",
        margin: "1em 0px 1em 0px",
      }}
    >
      <thead>
        <tr style={{ ...cellStyle, backgroundColor: "#ececec" }}>
          <th style={cellStyle} colSpan={2}>
            Yem Analizi
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={cellStyle}>
          <th style={cellStyle}>Çiftlik Adı</th>
          <th style={cellStyle}>{props.baitTable2.companyName}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Yem Cinsi</th>
          <th style={cellStyle}>{props.baitTable2.baitType}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Analiz Yöntemi</th>
          <th style={cellStyle}>{props.baitTable2.analyseType}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Analiz Tarihi</th>
          <th style={cellStyle}>{getDateString(props.baitTable2.date)}</th>
        </tr>
        <tr style={{ ...cellStyle, backgroundColor: "#ececec" }}>
          <th style={cellStyle} colSpan={2}>
            Analiz Sonucu
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>KURU MADDE (%ASFED)</th>
          <th style={cellStyle}>{props.baitTable2.dryMatter}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM PROTEİN (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rawProtein}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM YAĞ (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rawOil}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM KÜL (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rawAsh}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM SELÜLOZ (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rawCellulose}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>NİŞASTA (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.starch}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>RDP (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rdp}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>RUP (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.rup}</th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>NDF (%KM)</th>
          <th style={cellStyle}>{props.baitTable2.ndf}</th>
        </tr>
      </tbody>
    </table>
  );
};

export default FeedTable;
