import Icon from "@ant-design/icons";
const VaccineIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1.4em",
          height: "1.4em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        enableBackground="new 0 0 1000 1000"
        width="740.000000pt"
        height="740.000000pt"
        viewBox="0 0 740.000000 740.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,740.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1982 6030 c-14 -13 -22 -33 -22 -53 0 -29 38 -70 388 -420 l388
      -388 -4 -58 c-5 -73 3 -89 127 -251 53 -69 100 -130 104 -137 4 -6 -6 -33 -23
      -60 l-31 -48 88 -3 88 -3 30 38 c55 71 180 195 245 244 74 56 173 109 204 109
      13 0 45 -24 81 -60 l59 -59 -72 -75 c-65 -67 -72 -78 -72 -114 0 -47 26 -72
      75 -72 25 0 44 14 100 70 38 39 74 70 80 70 5 0 47 -37 92 -82 l83 -82 -75
      -77 c-60 -61 -75 -83 -75 -107 0 -45 30 -76 74 -76 32 0 46 9 111 74 l75 75
      87 -88 88 -87 -75 -75 c-65 -65 -74 -79 -74 -111 0 -44 31 -74 76 -74 24 0 46
      15 107 75 l77 74 87 -87 86 -87 -74 -76 c-60 -61 -75 -83 -75 -107 0 -35 27
      -70 55 -73 46 -4 61 4 131 73 l75 72 86 -87 87 -86 -78 -81 c-73 -74 -78 -83
      -72 -113 8 -40 39 -67 78 -67 23 0 46 16 106 75 l76 75 83 -82 c46 -46 83 -87
      83 -93 0 -6 -31 -42 -70 -80 -58 -58 -70 -75 -70 -102 0 -43 30 -73 73 -73 27
      0 44 12 102 70 38 39 74 70 80 70 13 0 165 -152 165 -165 0 -6 -128 -138 -285
      -295 l-285 -285 -453 453 -452 452 3 -107 3 -108 396 -397 396 -396 -22 -27
      c-13 -18 -21 -42 -21 -68 0 -39 6 -47 114 -155 l115 -115 47 6 c44 5 53 11
      190 148 l144 144 117 -117 117 -117 -62 -65 c-71 -76 -83 -114 -57 -170 22
      -45 109 -123 147 -131 59 -13 87 8 361 283 266 266 267 268 267 310 0 53 -17
      86 -67 133 -48 46 -73 59 -113 59 -43 0 -77 -20 -135 -79 l-49 -51 -118 117
      -118 117 144 146 c158 159 171 181 141 244 -9 19 -58 75 -109 124 -109 105
      -133 115 -202 81 l-46 -22 -934 934 -934 934 -63 0 c-67 -1 -137 -27 -230 -87
      -26 -16 -53 -28 -61 -25 -7 3 -73 51 -147 108 -157 120 -185 133 -254 125
      l-50 -6 -390 390 c-415 415 -415 414 -463 370z m3641 -3518 l117 -117 -65 -65
      -65 -65 -120 120 -120 120 62 62 c34 35 65 63 68 63 3 0 58 -53 123 -118z"
          />
          <path
            d="M1906 5638 c-107 -151 -136 -280 -81 -364 59 -90 207 -85 266 8 17
      28 20 45 16 103 -6 93 -45 178 -135 294 l-19 24 -47 -65z m92 -140 c18 -35 35
      -84 38 -110 6 -43 4 -50 -24 -77 -17 -17 -40 -31 -51 -31 -52 0 -91 41 -91 94
      0 50 71 203 89 192 4 -3 22 -33 39 -68z"
          />
          <path
            d="M2341 4421 c-28 -28 -33 -68 -29 -228 3 -134 4 -143 27 -167 20 -22
      33 -26 78 -26 l54 0 -6 -52 c-16 -131 -50 -206 -139 -305 -30 -33 -66 -82 -80
      -108 l-26 -48 0 -914 c0 -1003 -3 -955 60 -988 25 -13 122 -15 719 -15 l689 0
      31 26 c17 14 35 42 41 62 8 25 10 319 8 932 l-3 895 -23 47 c-13 26 -52 79
      -87 118 -89 99 -135 207 -135 315 l0 35 50 0 c41 0 56 5 75 25 25 24 25 27 25
      193 0 156 -2 171 -21 196 l-20 26 -635 0 c-615 0 -635 -1 -653 -19z m1063
      -443 c3 -13 8 -49 11 -81 13 -113 63 -223 141 -307 92 -99 94 -104 94 -271 l0
      -149 -660 0 -660 0 0 143 c0 163 -1 159 109 289 30 34 67 90 83 124 29 62 58
      175 58 230 0 17 3 34 7 37 3 4 188 7 409 7 l404 0 4 -22z m246 -2048 l0 -250
      -660 0 -660 0 0 243 c0 134 3 247 7 250 3 4 300 7 660 7 l653 0 0 -250z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default VaccineIcon;
