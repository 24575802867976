import { Layout, PageHeader, Drawer } from "antd";
import { useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import NavigationMenu from "../component/navigation/NavigationMenu";

const { Header, Sider, Content } = Layout;

const SidebarLayout = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Header
          style={{
            backgroundColor: "#3f51b5",
            padding: "0px",
            color: "white",
            height: "64px",
          }}
        >
          <PageHeader
            style={{ height: "64px" }}
            backIcon={isOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onBack={() => setIsOpen(!isOpen)}
            title={props.title}
            extra={props.extra(isSmallScreen)}
          />
        </Header>
        <Layout>
          <Sider
            style={{ backgroundColor: "white" }}
            hidden={isSmallScreen}
            width={250}
            collapsedWidth={60}
            breakpoint="md"
            trigger={null}
            collapsible
            collapsed={!isOpen}
            onCollapse={(value) => setIsOpen(value)}
            onBreakpoint={(broken) => {
              setIsOpen(false);
              setIsSmallScreen(broken);
            }}
          >
            <NavigationMenu items={props.navigations} />
          </Sider>
          {isSmallScreen && (
            <Drawer
              title={<>{props.title}</>}
              placement="left"
              visible={isOpen}
              onClose={() => setIsOpen(false)}
              width="260px"
            >
              <NavigationMenu items={props.navigations} />
            </Drawer>
          )}
          <Content>
            <div style={{ padding: "2em" }}>{props.children}</div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default SidebarLayout;
