import { petVaccineCrudApi } from "../client/EntityApi";
import {
  getPetVaccineCPColumns,
  getPetVaccineCPFormItems,
} from "../components/crud/PetVaccineCPComponents";
import CrudTable from "../core/component/crud/CrudTable";

const PetVaccineCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Pet Aşı"
      api={petVaccineCrudApi}
      searchKeys={["name"]}
      columns={getPetVaccineCPColumns()}
      addFormItems={getPetVaccineCPFormItems()}
      editFormItems={getPetVaccineCPFormItems()}
    />
  );
};

export default PetVaccineCrudPage;
