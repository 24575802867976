import { Form, Input, InputNumber, Select, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import YesNoTag from "../../core/component/YesNoTag";
import { getDateString } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

export const getRoutineCPColumns = (users: any[]) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kullanıcı",
      dataIndex: "worker",
      key: "worker",
      render: (value: any) => value.name + " " + value.surname,
      filters: users,
      onFilter: (value: any, record: any) => record.worker["@id"] === value,
    },
    {
      title: "Görev Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tekrar Periyodu (Gün)",
      dataIndex: "retryPeriod",
      key: "retryPeriod",
    },
    {
      title: "Aktif",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any) => <YesNoTag success={value} />,
      defaultFilteredValue: [true],
      filters: [
        {
          text: "Aktif",
          value: true,
        },
        {
          text: "Pasif",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
    {
      title: "Oluşturulma",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
    },
  ];
};

export const getRoutineCPFormItems = (users: any[], isEditForm = false) => {
  return (
    <>
      <Form.Item label="İdari Personel" name="worker" rules={[Rules.required]}>
        <Select allowClear options={users} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Görev Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(150)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Tekrar Periyodu (Gün)"
        name="retryPeriod"
        initialValue={1}
        rules={[Rules.required]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item
        label="Tanımı"
        name="definition"
        rules={[Rules.required, Rules.max(500)]}
      >
        <TextArea />
      </Form.Item>
      {isEditForm && (
        <Form.Item
          name="isActive"
          label="Aktif mi ?"
          valuePropName="checked"
          rules={[Rules.required]}
        >
          <Switch defaultChecked />
        </Form.Item>
      )}
    </>
  );
};
