import { Col, Row } from "antd";
import { ReactNode } from "react";

interface ITableCellProps {
  span?: number | string;
  children?: ReactNode;
  colProps?: object;
  colStyle?: object;
  rowProps?: object;
  rowStyle?: object;
}

const TableCell = (props: ITableCellProps) => {
  return (
    <Col
      {...props.colProps}
      span={props.span ?? 24}
      style={{ border: "1px solid black", padding: "1em", ...props.colStyle }}
    >
      <Row
        {...props.rowProps}
        align="middle"
        justify="center"
        style={{ ...props.rowStyle }}
      >
        {props.children}
      </Row>
    </Col>
  );
};

export default TableCell;
