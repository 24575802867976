import { Form, Input } from "antd";
import DatePick from "../../core/component/DatePick";
import { cellStyle, formItemStyle } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

interface IFeedAnalyseTableProps {
  name: string;
}

const FeedAnalyseTable = (props: IFeedAnalyseTableProps) => {
  return (
    <table
      style={{
        width: "100%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid #dddddd",
        margin: "1em 0px 1em 0px",
      }}
    >
      <thead>
        <tr style={{ ...cellStyle, backgroundColor: "#ececec" }}>
          <th style={cellStyle} colSpan={2}>
            Yem Analizi
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={cellStyle}>
          <th style={cellStyle}>Çiftlik Adı</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "companyName"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Yem Cinsi</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "baitType"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Analiz Yöntemi</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "analyseType"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Analiz Tarihi</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "date"]}
              rules={[Rules.required]}
            >
              <DatePick />
            </Form.Item>
          </th>
        </tr>
        <tr style={{ ...cellStyle, backgroundColor: "#ececec" }}>
          <th style={cellStyle} colSpan={2}>
            Analiz Sonucu
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>KURU MADDE (%ASFED)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "dryMatter"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM PROTEİN (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rawProtein"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM YAĞ (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rawOil"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM KÜL (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rawAsh"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>HAM SELÜLOZ (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rawCellulose"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>NİŞASTA (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "starch"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>RDP (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rdp"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>RUP (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "rup"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>NDF (%KM)</th>
          <th style={cellStyle}>
            <Form.Item
              style={formItemStyle}
              name={[props.name, "ndf"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
      </tbody>
    </table>
  );
};

export default FeedAnalyseTable;
