import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  petCrudApi,
  petExtendedApi,
  petOwnerCrudApi,
  petProcessCrudApi,
  petVaccineCrudApi,
  petVaccineRecordCrudApi,
} from "../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { SyncOutlined } from "@ant-design/icons";
import {
  getPetCPColumns,
  getPetCPExpandedRowRender,
  getPetCPFormItems,
  getPetProcessFormItems,
  getPetVaccineFormItems,
} from "../components/crud/PetCPComponents";
import { otherApi } from "../core/client/BaseEntityApi";
import { getDateFromString } from "../core/helper/UtilHelper";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { Form, Modal, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Rules } from "../core/Rules";

const PetCrudPage = () => {
  const { id } = useParams();
  const [petProcessForm] = useForm();
  const [petVaccineForm] = useForm();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [pets, setPets] = useState<any[]>([]);
  const [owner, setOwner] = useState<any>();
  const [genus, setGenus] = useState<any[]>([]);
  const [vaccineStatus, setVaccineStatus] = useState<any[]>([]);
  const [vaccines, setVaccines] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [petVaccineModalIsOpen, setPetVaccineModalIsOpen] =
    useState<boolean>(false);
  const [petProcessModalIsOpen, setPetProcessModalIsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    otherApi.constants().then((response) => {
      setVaccineStatus(response["vaccineStatus"]);
      setGenus(response["genus"]);
    });
    petVaccineCrudApi.getAll().then((response) => {
      setVaccines(
        response["hydra:member"].map((vaccine: any) => {
          return { label: vaccine.name, value: vaccine["@id"] };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (id)
      petOwnerCrudApi.get(parseInt(id)).then((response) => setOwner(response));
  }, [id]);

  const getOwnerPets = () => {
    return id
      ? petExtendedApi.getOwnerPets(parseInt(id))
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const petProcessModalOnCancel = () => {
    setPetProcessModalIsOpen(false);
    setLoading(false);
    petProcessForm.resetFields();
  };

  const petVaccineModalOnCancel = () => {
    setPetVaccineModalIsOpen(false);
    setLoading(false);
    petVaccineForm.resetFields();
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        customTableTitle={() =>
          owner ? (
            owner.firstname + " " + owner.lastname + " Pet Listesi"
          ) : (
            <SyncOutlined spin />
          )
        }
        getAll={getOwnerPets}
        api={petCrudApi}
        searchKeys={["name", "chipNo", "passportNo"]}
        columns={getPetCPColumns(genus)}
        addFormItems={getPetCPFormItems(id, genus, vaccines, vaccineStatus)}
        editFormItems={getPetCPFormItems(id, genus, vaccines, vaccineStatus)}
        expandable={{
          expandedRowRender: (record) =>
            getPetCPExpandedRowRender(record, vaccineStatus),
        }}
        afterRefreshOperation={(data) => {
          setPets(
            data.map((pet) => {
              return {
                label: pet.name,
                value: pet["@id"],
              };
            })
          );
        }}
        extraTitleProcess={() => (
          <>
            <CrudPageProcessButton
              tooltipText="Müdahele Kaydı"
              icon={<>M</>}
              onClick={() => setPetProcessModalIsOpen(true)}
            />
            <CrudPageProcessButton
              tooltipText="Aşı Kaydı"
              icon={<>A</>}
              onClick={() => setPetVaccineModalIsOpen(true)}
            />
          </>
        )}
        setEditFields={(row) => {
          const mappedPetProcesses = row.petProcesses.map((petProcess: any) => {
            return {
              ...petProcess,
              id: petProcess["@id"],
            };
          });

          const mappedPetVaccineRecords = row.petVaccineRecords.map(
            (petVaccineRecord: any) => {
              return {
                ...petVaccineRecord,
                id: petVaccineRecord["@id"],
                vaccine: petVaccineRecord.vaccine["@id"],
                date: getDateFromString(petVaccineRecord.date),
              };
            }
          );

          return {
            ...row,
            id: row["@id"],
            birthDate: getDateFromString(row.birthDate),
            petProcesses: mappedPetProcesses,
            petVaccineRecords: mappedPetVaccineRecords,
          };
        }}
      />
      <Modal
        confirmLoading={loading}
        visible={petProcessModalIsOpen}
        title={"Müdahale Kaydı Oluştur"}
        okText="Ekle"
        cancelText="Vazgeç"
        onCancel={petProcessModalOnCancel}
        onOk={() => {
          petProcessForm.validateFields().then((values) => {
            setLoading(true);
            petProcessCrudApi
              .create(values)
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => petProcessModalOnCancel());
          });
        }}
      >
        <Form form={petProcessForm} layout="vertical">
          <Form.Item label="Pet" name="pet" rules={[Rules.required]}>
            <Select options={pets} />
          </Form.Item>
          {getPetProcessFormItems()}
        </Form>
      </Modal>
      <Modal
        confirmLoading={loading}
        visible={petVaccineModalIsOpen}
        title={"Aşı Kaydı Oluştur"}
        okText="Ekle"
        cancelText="Vazgeç"
        onCancel={petVaccineModalOnCancel}
        onOk={() => {
          petVaccineForm.validateFields().then((values) => {
            setLoading(true);
            petVaccineRecordCrudApi
              .create(values)
              .then(() => crudPageRef.current?.refreshData())
              .finally(() => petVaccineModalOnCancel());
          });
        }}
      >
        <Form form={petVaccineForm} layout="vertical">
          <Form.Item label="Pet" name="pet" rules={[Rules.required]}>
            <Select options={pets} />
          </Form.Item>
          {getPetVaccineFormItems(vaccines, vaccineStatus)}
        </Form>
      </Modal>
    </>
  );
};

export default PetCrudPage;
