import { Button, Col, Form, Row } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

interface IBaseFormListProps {
  name: string;
  formItems: (name: any) => ReactNode;
  onClick?: (add: (values: any) => void) => void;
  title?: string;
  addButtonText?: string;
  addParameters?: object;
}

const BaseFormList = (props: IBaseFormListProps) => {
  return (
    <Form.List name={props.name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ name }, index) => (
            <Row
              key={index}
              style={{
                border: "1px dashed black",
                margin: "1em 0px 1em 0px",
                padding: "1em",
              }}
            >
              <Col span={24}>
                <Row style={{ marginBottom: "1em" }}>
                  <Col span={20}>
                    <Row justify="center">{props.title}</Row>
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
                {props.formItems(name)}
              </Col>
            </Row>
          ))}
          <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
            <Button
              type="dashed"
              onClick={() => {
                props.onClick ? props.onClick(add) : add(props.addParameters);
              }}
              block
              icon={<PlusOutlined />}
            >
              {props.addButtonText ?? "Ekle"}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default BaseFormList;
