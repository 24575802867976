import Icon from "@ant-design/icons";
const MilkTankIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        viewBox="0 0 512 512"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="6330"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1031 5094 c-187 -94 -161 -365 41 -421 l58 -16 0 -52 0 -52 -137 -6
      c-90 -3 -153 -10 -181 -20 -105 -39 -179 -119 -214 -229 -16 -53 -18 -96 -18
      -361 0 -328 9 -437 47 -567 28 -98 94 -242 147 -319 l36 -53 0 -1299 c0 -916
      3 -1314 11 -1351 25 -119 99 -225 200 -285 112 -67 10 -64 1750 -61 l1574 3
      41 22 c56 30 103 79 128 133 19 42 21 66 24 322 l3 278 -25 25 c-32 32 -75 33
      -104 2 -21 -23 -22 -30 -22 -284 0 -254 -1 -261 -23 -294 -12 -18 -34 -39 -47
      -46 -19 -10 -154 -13 -571 -13 l-546 0 -34 23 c-61 41 -61 37 -56 707 5 646 6
      655 58 822 25 77 35 88 125 120 43 15 83 21 144 20 101 0 165 -20 278 -86 188
      -110 365 -131 551 -65 64 22 64 23 85 -71 25 -111 36 -228 36 -398 0 -162 0
      -163 26 -189 20 -20 31 -24 57 -19 62 12 62 14 61 264 -1 194 -4 242 -23 332
      -41 191 -99 324 -231 529 -117 183 -167 306 -189 465 -14 96 -14 206 -1 206
      21 0 80 64 95 104 13 33 16 68 13 143 -3 90 -6 104 -31 140 -50 73 -82 83
      -257 83 l-152 0 5 23 c3 12 14 57 26 101 33 123 43 278 39 587 l-3 284 -28 61
      c-36 77 -104 145 -182 180 -54 25 -74 28 -197 32 l-138 5 0 52 0 53 49 12 c58
      14 123 66 151 121 42 80 21 195 -46 263 -64 63 -43 61 -574 64 -267 2 -495 0
      -507 -3 -29 -7 -55 -57 -47 -90 14 -56 8 -55 520 -55 462 0 472 0 498 -21 32
      -25 35 -75 7 -110 l-19 -24 -1106 0 -1105 0 -22 22 c-32 31 -30 80 5 110 l27
      23 473 0 c461 0 473 0 494 20 30 28 29 80 -1 108 -23 22 -24 22 -508 22 l-485
      0 -53 -26z m2099 -545 c0 -211 50 -322 236 -521 51 -54 64 -76 73 -118 7 -30
      11 -167 11 -340 l0 -290 -32 -14 c-43 -18 -83 -61 -103 -110 -13 -30 -16 -64
      -14 -143 l4 -103 -50 -36 c-28 -20 -66 -44 -86 -54 l-37 -19 -6 39 c-8 51 -46
      95 -101 115 -61 24 -106 14 -160 -36 l-44 -40 -68 41 c-128 77 -159 83 -490
      88 -399 6 -485 -7 -619 -94 l-56 -35 -38 34 c-70 64 -149 72 -210 20 -35 -29
      -60 -72 -60 -103 0 -11 -6 -20 -14 -20 -26 0 -169 101 -237 168 l-69 68 0 419
      c0 263 4 434 11 458 6 24 37 67 86 120 167 181 212 282 220 485 l6 132 923 0
      924 0 0 -111z m-2025 -171 c-17 -56 -66 -128 -153 -225 -142 -159 -136 -137
      -142 -523 -5 -323 -5 -325 -22 -275 -47 136 -50 167 -55 523 -6 384 -4 398 61
      461 48 47 95 60 214 61 103 0 103 0 97 -22z m2461 -1 c51 -26 91 -80 104 -140
      9 -39 11 -141 7 -368 -5 -322 -12 -381 -54 -504 l-18 -50 -5 315 c-5 314 -5
      315 -31 371 -15 32 -62 95 -112 150 -89 97 -141 173 -153 223 l-7 29 119 -5
      c84 -3 128 -9 150 -21z m472 -1259 c7 -7 12 -41 12 -83 0 -42 -5 -76 -12 -83
      -9 -9 -86 -12 -284 -12 -317 0 -304 -4 -304 91 0 106 -21 99 298 99 203 0 281
      -3 290 -12z m-1462 -283 c27 -8 83 -35 124 -60 102 -62 177 -59 239 9 14 14
      28 26 33 26 4 0 8 -63 8 -140 0 -77 -4 -140 -8 -140 -5 0 -19 12 -33 26 -62
      68 -137 71 -239 9 -122 -74 -131 -75 -495 -75 -362 0 -375 2 -489 71 -88 53
      -137 60 -200 29 -26 -12 -51 -31 -57 -41 -22 -43 -29 -15 -29 121 0 77 3 140
      8 140 4 0 12 -8 19 -19 17 -27 94 -61 139 -61 23 0 55 10 79 25 158 96 147 94
      526 94 262 1 335 -2 375 -14z m-1383 -161 l77 -39 0 -48 c0 -81 18 -133 61
      -171 67 -61 145 -57 219 10 l38 35 64 -40 c34 -22 92 -50 128 -63 63 -22 75
      -23 410 -23 262 0 358 3 400 14 53 13 162 64 206 96 20 15 24 14 67 -24 71
      -61 145 -66 207 -14 46 39 60 73 60 152 l0 71 59 30 c33 16 96 54 141 85 44
      30 84 55 87 55 13 0 -7 -229 -27 -305 -28 -105 -93 -242 -175 -366 -120 -183
      -182 -328 -227 -528 -20 -91 -21 -126 -25 -721 -3 -514 -1 -634 10 -678 l14
      -52 -906 2 -906 3 -57 28 c-60 29 -106 78 -136 144 -16 35 -17 125 -20 1271
      l-2 1232 78 -58 c44 -32 113 -76 155 -98z m2740 9 c14 -250 57 -363 277 -721
      52 -85 61 -105 49 -113 -33 -21 -129 -42 -194 -42 -93 0 -171 24 -276 85 -154
      88 -304 123 -429 100 -30 -6 -56 -10 -57 -9 -1 1 24 40 55 87 132 202 200 396
      209 603 l6 117 177 0 177 0 6 -107z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default MilkTankIcon;
