export const Rules = {
  required: { required: true, message: "Boş bırakılamaz!" },
  min: (length: number) => {
    return {
      min: length,
      message: "En az " + length + " karakter olmalıdır!",
    };
  },
  max: (length: number) => {
    return {
      max: length,
      message: "En fazla " + length + " karakter olmalıdır!",
    };
  },
};
