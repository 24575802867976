import { Col, Modal, Row } from "antd";
import { useState } from "react";
import AnimalReportColumn from "./AnimalReportColumn";

interface IAnimalReportBarProps {
  report?: any;
}

const AnimalReportBar = (props: IAnimalReportBarProps) => {
  const [earringModalIsOpen, setEarringModalIsOpen] = useState(false);
  const [earringNoData, setEarringNoData] = useState([]);
  return (
    <>
      <Row justify="center">
        <Col>
          <AnimalReportColumn
            columnName="T. HAYVAN"
            value={props.report?.animalCount}
          />
        </Col>
        <Col>
          <AnimalReportColumn columnName="SAĞMAL" value={props.report?.milch} />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="KURU"
            value={props.report?.dry.value}
            data={props.report?.dry.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="CLOSE UP"
            value={props.report?.closeup.value}
            data={props.report?.closeup.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="FRESH"
            value={props.report?.fresh.value}
            data={props.report?.fresh.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="T. GEBE"
            value={props.report?.pregnant.value}
            data={props.report?.pregnant.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="BOŞ"
            value={props.report?.nonPregnant.value}
            data={props.report?.nonPregnant.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="BELİRSİZ"
            value={props.report?.none.value}
            data={props.report?.none.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="T. BUZAĞI"
            value={props.report?.calf.value}
            data={props.report?.calf.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="D. BUZAĞI"
            value={props.report?.fcalf.value}
            data={props.report?.fcalf.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="D. DANA"
            value={props.report?.fsteer.value}
            data={props.report?.fsteer.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="B. DÜVE"
            value={props.report?.heifer.value}
            data={props.report?.heifer.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="G. DÜVE"
            value={props.report?.pregnantHeifer.value}
            data={props.report?.pregnantHeifer.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="USG1"
            value={props.report?.usg1.value}
            data={props.report?.usg1.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="USG2"
            value={props.report?.usg2.value}
            data={props.report?.usg2.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="pSGS"
            value={props.report?.avgSgs}
            condition={props.report?.avgSgs < 400}
          />
        </Col>
        <Col>
          <AnimalReportColumn columnName="SGS" value={props.report?.avgDsg} />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="OGSV"
            value={props.report?.ogsv}
            condition={props.report?.ogsv > 25}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="GBTS"
            value={props.report?.gbts}
            condition={props.report?.gbts < 1.9}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="DVO"
            value={props.report?.dvo}
            condition={props.report?.dvo > 70}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="Senk. Hazır"
            value={props.report?.readySync.value}
            data={props.report?.readySync.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="Senkronizasyonda"
            value={props.report?.inSync.value}
            data={props.report?.inSync.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
        <Col>
          <AnimalReportColumn
            columnName="B. Aşı Havuzu"
            value={props.report?.individualVaccine.value}
            data={props.report?.individualVaccine.data}
            setEarringModalIsOpen={setEarringModalIsOpen}
            setEarringNoData={setEarringNoData}
          />
        </Col>
      </Row>
      <Modal
        title="Küpe Numaraları"
        visible={earringModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => {
          setEarringNoData([]);
          setEarringModalIsOpen(false);
        }}
      >
        {earringNoData.map((earringNo, index) => (
          <div key={index}>
            {earringNo}
            <br />
          </div>
        ))}
      </Modal>
    </>
  );
};

export default AnimalReportBar;
