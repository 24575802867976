import Cookies from "js-cookie";
import { authApi } from "../client/BaseEntityApi";

export const AuthHelper = {
  login: (username: string, password: string) => {
    return authApi.login(username, password).then((response: any) => {
      Cookies.set("user", JSON.stringify(response.worker));
      Cookies.set("accessToken", response.accessToken);
      Cookies.set("refreshToken", response.refreshToken);
      window.location.reload();
    });
  },
  refreshToken: async (refreshToken: string) => {
    return authApi.refreshToken(refreshToken).then((response: any) => {
      Cookies.set("user", JSON.stringify(response.worker));
      Cookies.set("accessToken", response.accessToken);
      Cookies.set("refreshToken", response.refreshToken);
      window.location.reload();
    });
  },
  logout: () => {
    Cookies.remove("user");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    window.location.replace(window.location.origin);
  },
  getUser: () => {
    const user = Cookies.get("user");
    return user ? JSON.parse(user) : undefined;
  },
  getUserField: (field: string) => {
    const user = AuthHelper.getUser();
    return user ? user[field] : undefined;
  },
  isLoggedIn: () => {
    return Boolean(Cookies.get("accessToken"));
  },
  checkRole: (role: string) => {
    const userRole = AuthHelper.getUserField("role");
    return userRole?.value === role || userRole?.value === "ROLE_SUPER_ADMIN";
    // const roles = AuthHelper.getUserField("roles");
    // return roles?.includes(role) || roles?.includes("ROLE_SUPER_ADMIN");
  },
  checkVisibility: (key: string) => {
    return AuthHelper.getUserField("visibilities")?.includes(key);
  },
  isResourceOwn: (resourceId: any) => {
    return AuthHelper.getUserField("id") === resourceId;
  },
};
