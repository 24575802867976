import { Card, Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import { usageDocumentApi } from "../client/EntityApi";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const UsageDocument = () => {
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [content, setContent] = useState<string>("");
  const [editorContent, setEditorContent] = useState<string>();

  useEffect(() => {
    usageDocumentApi
      .get()
      .then((response) => setContent(response.content))
      .finally(() => setLoading(false));
  }, []);

  const onCancel = () => {
    setEditorContent(undefined);
    setEditDialogIsOpen(false);
    setConfirmLoading(false);
  };

  return (
    <>
      <Card style={{ borderRadius: "8px" }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <b style={{ fontSize: 20, marginRight: "1em" }}>
              Kullanım Dökümanı
            </b>
            <CrudPageProcessButton
              disabled={loading}
              tooltipText="Düzenle"
              icon={<EditOutlined />}
              onClick={() => {
                setEditorContent(content);
                setEditDialogIsOpen(true);
              }}
            />
          </Col>
          <Col span={24}>
            {loading ? (
              <LoadingOutlined />
            ) : (
              <div
                className="quiilContent"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Modal
        maskClosable={false}
        visible={editDialogIsOpen}
        title="Kullanım Dökümanı Güncelle"
        okText="Güncelle"
        cancelText="Vazgeç"
        onOk={() => {
          if (editorContent) {
            setConfirmLoading(true);
            usageDocumentApi
              .put(editorContent)
              .then((response) => {
                setContent(response.content);
                toast.success("Döküman güncellendi.");
              })
              .finally(() => onCancel());
          } else {
            onCancel();
          }
        }}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
      >
        <Form>
          <ReactQuill
            formats={formats}
            modules={modules}
            style={{ minHeight: "100px" }}
            value={editorContent}
            onChange={(value: any) => setEditorContent(value)}
          />
          <Form.Item name="content">
            <Input hidden />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UsageDocument;
