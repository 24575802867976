import Icon from "@ant-design/icons";

const ProductionIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1.5em",
          height: "1.5em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        enableBackground="new 0 0 1000 1000"
        viewBox="0 0 512 512"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M1410 5098 c-73 -28 -169 -123 -195 -193 -17 -45 -20 -87 -23 -416
l-3 -367 -187 -4 c-166 -4 -196 -8 -264 -30 -220 -73 -396 -243 -472 -456 -45
-126 -48 -198 -44 -1092 l4 -835 23 -75 c83 -274 293 -469 563 -524 50 -10
130 -16 228 -16 l150 0 0 -101 c0 -130 19 -168 87 -169 23 0 38 8 57 31 24 29
26 37 26 135 l0 104 305 0 305 0 0 -100 c0 -134 18 -170 85 -170 13 0 37 11
52 24 27 23 28 28 31 135 l4 111 149 0 c192 0 273 16 407 81 87 42 115 62 185
132 93 93 145 176 185 292 l27 80 5 467 c3 256 3 675 0 930 l-5 463 -27 80
c-40 116 -92 199 -183 290 -94 93 -161 135 -282 178 -84 29 -97 31 -275 35
l-186 4 -4 362 c-4 347 -5 363 -26 418 -30 76 -99 150 -170 185 -56 28 -59 28
-267 30 -194 3 -214 1 -265 -19z m477 -170 c24 -16 49 -44 62 -70 20 -43 21
-58 21 -391 l0 -347 -305 0 -305 0 0 351 c0 379 1 388 55 439 45 42 88 50 266
47 161 -2 167 -3 206 -29z m618 -988 c169 -44 306 -155 377 -306 52 -112 58
-163 58 -571 l0 -373 -1275 0 -1275 0 0 383 c0 421 4 457 61 573 78 159 253
283 424 302 33 4 402 6 820 6 588 -1 771 -4 810 -14z m433 -1822 c-4 -384 -5
-406 -26 -469 -60 -180 -216 -326 -398 -374 -85 -22 -1620 -22 -1704 0 -195
52 -358 219 -405 416 -12 51 -15 141 -15 447 l0 382 1276 0 1275 0 -3 -402z"
          />
          <path
            d="M2345 4878 c-53 -31 -60 -85 -16 -129 l29 -29 941 0 c640 0 957 -3
993 -11 147 -30 298 -146 370 -285 55 -104 71 -185 65 -319 -8 -163 -49 -265
-151 -374 -105 -112 -222 -164 -403 -180 -153 -14 -235 -56 -315 -163 -95
-126 -105 -353 -22 -495 69 -118 170 -179 322 -194 204 -20 289 -55 403 -169
82 -81 127 -158 154 -265 19 -76 19 -241 0 -324 -35 -148 -147 -293 -280 -363
-87 -45 -130 -57 -253 -67 -140 -13 -202 -39 -283 -120 -98 -98 -112 -141
-117 -353 l-4 -167 26 -26 c32 -32 76 -33 113 -1 l28 24 5 164 c5 159 6 166
33 206 42 64 93 93 184 103 170 20 236 37 328 83 187 95 322 261 377 464 27
102 30 314 4 417 -47 193 -179 364 -360 470 -72 42 -190 75 -298 84 -118 10
-142 17 -193 63 -57 49 -75 97 -75 201 0 97 14 144 58 190 47 50 97 68 210 78
217 19 392 111 519 273 111 142 156 280 156 476 1 222 -63 381 -213 530 -123
123 -239 183 -400 209 -95 16 -1908 14 -1935 -1z"
          />
          <path
            d="M2329 4531 c-17 -18 -29 -40 -29 -56 0 -16 12 -38 29 -56 l29 -29
934 0 c884 0 935 -1 973 -19 92 -42 134 -116 135 -234 0 -85 -12 -125 -55
-175 -45 -53 -95 -73 -213 -83 -322 -28 -567 -229 -654 -536 -18 -63 -22 -104
-22 -218 -1 -167 13 -227 81 -365 87 -178 264 -320 459 -370 42 -11 103 -20
134 -20 117 0 208 -49 249 -133 17 -34 21 -62 21 -133 0 -106 -18 -152 -78
-206 -45 -41 -93 -58 -165 -58 -135 0 -328 -73 -439 -167 -113 -95 -204 -237
-243 -378 -17 -64 -33 -362 -21 -409 9 -38 41 -66 75 -66 79 0 91 29 91 214 0
171 10 225 57 326 68 142 230 271 375 299 29 5 95 14 147 20 161 17 270 92
333 227 26 56 32 82 36 163 8 160 -30 263 -129 356 -74 69 -136 94 -268 105
-228 20 -391 120 -484 298 -51 96 -67 167 -67 292 0 194 45 307 169 431 114
113 196 148 396 167 126 12 198 46 274 128 85 90 105 147 106 294 0 109 -2
118 -32 182 -36 76 -108 155 -171 187 -101 52 -88 51 -1080 51 l-924 0 -29
-29z"
          />
          <path
            d="M545 653 c-170 -2 -268 -7 -282 -14 -28 -15 -45 -62 -34 -92 21 -55
30 -56 272 -59 l224 -3 0 -160 0 -160 -226 -3 c-226 -2 -226 -2 -249 -27 -30
-32 -30 -78 0 -110 l23 -25 2287 0 2287 0 23 25 c30 32 30 78 0 110 -23 25
-23 25 -249 27 l-226 3 0 160 0 160 224 3 c197 2 227 5 244 20 28 25 38 57 26
89 -21 62 134 57 -2069 58 -1108 1 -2132 0 -2275 -2z m3683 -325 l-3 -163
-1665 0 -1665 0 -3 163 -2 162 1670 0 1670 0 -2 -162z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default ProductionIcon;
