import { Form, Input, InputNumber, Select } from "antd";
import { getDateString, mathRound } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

export const getMaterialCPColumns = (currencies: any) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Hammadde Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Hammadde Miktarı",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Fiyat (TL)",
      dataIndex: "unit",
      render: (value: any, row: any) =>
        mathRound(value * currencies[row.currency] ?? 1),
    },
    {
      title: "Stok Maliyeti (TL)",
      dataIndex: "stockPrice",
      render: (text: any, row: any) =>
        mathRound(row.unit * (currencies[row.currency] ?? 1) * row.quantity),
    },
    {
      title: "Son Fiyat Güncellemesi",
      dataIndex: "lastPriceUpdate",
      render: (value: any) => (value ? getDateString(value) : "-"),
    },
  ];
};

export const getMaterialCPFormItems = (currencies: any) => {
  return (
    <>
      <Form.Item
        label="Hammadde Adı"
        name="name"
        rules={[Rules.required, Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Miktar" name="quantity" initialValue={"0"}>
        <InputNumber min={0} precision={3} decimalSeparator="," stringMode />
      </Form.Item>
      <Form.Item label="Fiyat" name="unit" initialValue={"0"}>
        <InputNumber min={0} precision={3} decimalSeparator="," stringMode />
      </Form.Item>
      <Form.Item initialValue="tl" label="Döviz Türü" name="currency">
        <Select options={currencies} />
      </Form.Item>
    </>
  );
};
