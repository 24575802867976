import { Row } from "antd";
import { toast } from "react-toastify";
import LargeTag from "../../core/component/LargeTag";
import { AuthHelper } from "../../core/helper/AuthHelper";

export const getCPeriodicTasksCPColumns = (
  setAssignTaskDialogIsOpen: (state: boolean) => void,
  setSelectedTaskCategoryId: (id: number) => void,
  form: any
) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İşletme Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "İşletme Türü",
      dataIndex: "companyType",
      key: "companyType",
      render: (value: any) => (value === "dairy" ? "Süt" : "Besi"),
    },
    {
      title: "Konum",
      dataIndex: "city",
      key: "location",
      render: (value: any, row: any) => value.name + " / " + row.district.name,
    },
    {
      title: "Görevler",
      dataIndex: "taskCategories",
      key: "taskCategories",
      render: (value: any) => (
        <>
          {value.map((taskCategory: any, index: any) => (
            <LargeTag
              key={index}
              text={taskCategory.service.name}
              tagStyle={{ cursor: "pointer" }}
              onClick={() => {
                if (AuthHelper.checkVisibility("Görev Atama")) {
                  form.setFieldsValue({
                    worker: taskCategory?.assignedWorker
                      ? "/api/workers/" + taskCategory.assignedWorker.id
                      : "none",
                  });
                  setAssignTaskDialogIsOpen(true);
                  setSelectedTaskCategoryId(taskCategory.id);
                } else {
                  toast.warning("Görev atamaya yetkiniz bulunmamakta.");
                }
              }}
              color={
                taskCategory.assignedWorker
                  ? taskCategory.taskCategoryStatus === "done"
                    ? "green"
                    : taskCategory.taskCategoryStatus === "continuing"
                    ? "orange"
                    : "red"
                  : "geekblue"
              }
              tooltipText={
                taskCategory.assignedWorker
                  ? taskCategory.assignedWorker.name +
                    " " +
                    taskCategory.assignedWorker.surname
                  : "Kimse"
              }
            />
          ))}
        </>
      ),
    },
  ];
};

export const getCPeriodicTasksCPCustomTableTitle = (companyReport: any) => {
  return (
    <Row gutter={[8, 8]} justify="center">
      <LargeTag text={"Yapılan İş : " + companyReport?.doneCategoryCount} />
      <LargeTag
        color="orange"
        text={"Devam Eden İş : " + companyReport?.continuingCategoryCount}
      />
      <LargeTag
        color="red"
        text={"Yapılamayan İş : " + companyReport?.notDoneCategoryCount}
      />
      <LargeTag
        color="geekblue"
        text={"Atama Bekleyen İş : " + companyReport?.waitForAssigneCount}
      />
    </Row>
  );
};
