import { Form, Input } from "antd";
import Upload from "../../core/component/Upload";
import { getDateString } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

export const getPriceCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Liste Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Son Güncelleme Tarihi",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: any) => getDateString(value),
    },
  ];
};

export const getPriceCPFormItems = () => {
  return (
    <>
      <Form.Item
        label="Liste Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="image" rules={[Rules.required]}>
        <Upload />
      </Form.Item>
    </>
  );
};
