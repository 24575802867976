import { Form, Select, Typography, Image } from "antd";
import TextArea from "antd/lib/input/TextArea";
import ColoredTag from "../../core/component/ColoredTag";
import DatePick from "../../core/component/DatePick";
import Upload from "../../core/component/Upload";
import YesNoTag from "../../core/component/YesNoTag";
import { getDateString, isImageMedia } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import BaseFormList from "../BaseFormList";
import FeedScreenTable from "../FeedScreenTable";
import FeedTable from "../FeedTable";
import GaitaTable from "../GaitaTable";
import RationTable from "../RationTable";
import FeedAnalyseTable from "../task/FeedAnalyseTable";
import FeedScreenAnalyseTable from "../task/FeedScreenAnalyseTable";
import GaitaAnalyseTable from "../task/GaitaAnalyseTable";
import RationAnalyseTable from "../task/RationAnalyseTable";
import VaccineCard from "../VaccineCard";

export const getCompanyDetailCPExpandedRowRender = (record: any) => {
  const { Title, Paragraph, Text, Link } = Typography;
  return (
    <>
      <Typography>
        <Title level={5}>Açıklama</Title>
        <Paragraph> {record.description}</Paragraph>
        {record.vaccines.length > 0 && (
          <>
            <Title level={5}>Aşı Listesi</Title>
            <Paragraph>
              {record.vaccines.map((vaccine: any, index: number) => (
                <VaccineCard key={index} vaccine={vaccine} />
              ))}
            </Paragraph>
          </>
        )}
        {record.rationTables.length > 0 && (
          <>
            <Title level={5}>Rasyon Tabloları</Title>
            <Paragraph>
              {record.rationTables.map((rationTable: any, index: number) => (
                <RationTable key={index} rationTable={rationTable} />
              ))}
            </Paragraph>
          </>
        )}
        {record.baitAnalyseTables.length > 0 && (
          <>
            <Title level={5}>Yem Analiz Tabloları</Title>
            <Paragraph>
              {record.baitAnalyseTables.map(
                (baitAnalyseTable: any, index: number) => (
                  <FeedTable key={index} baitTable2={baitAnalyseTable} />
                )
              )}
            </Paragraph>
          </>
        )}
        {record.gaitaTables.length > 0 && (
          <>
            <Title level={5}>Gaita Analiz Tabloları</Title>
            <Paragraph>
              {record.gaitaTables.map((gaitaTable: any, index: number) => (
                <GaitaTable key={index} gaitaTable={gaitaTable} />
              ))}
            </Paragraph>
          </>
        )}
        {record.baitTables.length > 0 && (
          <>
            <Title level={5}>Yem Elek Analiz Tabloları</Title>
            <Paragraph>
              {record.baitTables.map((baitTable: any, index: number) => (
                <FeedScreenTable key={index} baitTable={baitTable} />
              ))}
            </Paragraph>
          </>
        )}
        <Title level={5}>Medya Listesi</Title>
        <Paragraph>
          <ul>
            {record.media.map((media: any, index: number) => {
              return (
                !isImageMedia(media.extension) && (
                  <li key={index}>
                    <Link
                      target="_blank"
                      href={process.env.REACT_APP_BASE_URL + media.uid}
                    >
                      {media.name}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        </Paragraph>
        <Title level={5}>Görseller</Title>
        {record.media.map((media: any, index: number) => {
          return (
            isImageMedia(media.extension) && (
              <Image
                key={index}
                width={200}
                src={process.env.REACT_APP_BASE_URL + media.uid}
              />
            )
          );
        })}
        {record.errorComment && (
          <>
            <Title level={5}>Yönetici Yorumu</Title>
            <Paragraph>
              <Text type="danger"> {record.errorComment}</Text>
            </Paragraph>
          </>
        )}
      </Typography>
    </>
  );
};

export const getCompanyDetailCPColumns = (services: any[]) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Personel",
      dataIndex: "worker",
      key: "worker",
      render: (value: any) => value.name + " " + value.surname,
    },
    {
      title: "Bildiri Kategorisi",
      dataIndex: "taskCategory",
      key: "taskCategory",
      render: (value: any) => value.service.name,
      filters: services,
      onFilter: (value: any, record: any) =>
        value === record.taskCategory.service.name,
    },
    {
      title: "Bildiri Tipi",
      dataIndex: "taskType",
      key: "taskType",
      render: (value: any) => (
        <YesNoTag
          success={value === "work"}
          successColor="geekblue"
          failColor="gold"
          successText="İş"
          failText="Yorum"
        />
      ),
    },
    {
      title: "Bildiri Durumu",
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (value: any, row: any) => (
        <ColoredTag
          selected={value}
          baseText={
            row.evaluator
              ? row.evaluator.name +
                " " +
                row.evaluator.surname +
                " Tarafından "
              : undefined
          }
          options={[
            { key: "approved", color: "green", text: "Onaylandı" },
            {
              key: "waitForApprove",
              color: "geekblue",
              text: "Değerlendirme Bekliyor",
            },
            { key: "waitForEdit", color: "orange", text: "Düzeltme İstendi" },
            { key: "rejected", color: "red", text: "Reddedildi" },
          ]}
        />
      ),
    },
    {
      title: "Bildiri Zamanı",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
    },
  ];
};

export const getCompanyDetailCPFormItems = (
  taskTypes: any[],
  taskCategories: any[],
  vaccineOptions: any[],
  selectedTaskCategoryName: string,
  setSelectedTaskCategoryName: (name: string) => void,
  form: any
) => {
  return (
    <>
      <Form.Item
        label="Görev Bildiri Tipi"
        name="taskType"
        rules={[Rules.required]}
      >
        <Select options={taskTypes} />
      </Form.Item>
      <Form.Item
        label="Görev Kategorisi"
        name="taskCategory"
        rules={[Rules.required]}
      >
        <Select
          onChange={(value, object: any) =>
            setSelectedTaskCategoryName(object.label)
          }
          options={taskCategories}
        />
      </Form.Item>
      <Form.Item label="Açıklama" name="description" rules={[Rules.max(5000)]}>
        <TextArea rows={4} placeholder="Açıklama giriniz." />
      </Form.Item>
      <Form.Item name="media">
        <Upload multiple patchableList />
      </Form.Item>
      {getTaskSubForm(selectedTaskCategoryName, vaccineOptions, form)}
    </>
  );
};

const getTaskSubForm = (
  taskCategoryName: string,
  vaccineOptions: any[],
  form: any
) => {
  switch (taskCategoryName) {
    case "Rasyon Kontrol":
      return getRationControlForm(form);
    case "Tmr Kontrol":
      return getTmrControlForm(form);
    case "Analiz ve Protokoller":
      return getAnalyseAndProtocolForm(vaccineOptions);
    default:
      return <></>;
  }
};

const getRationControlForm = (form: any) => {
  return (
    <BaseFormList
      name="rationTables"
      addButtonText="Rasyon Tablosu Ekle"
      formItems={(name) => <RationAnalyseTable name={name} form={form} />}
    />
  );
};

const getTmrControlForm = (form: any) => {
  return (
    <>
      <BaseFormList
        name="gaitaTables"
        addButtonText="Gaita Analiz Tablosu Ekle"
        formItems={(name) => <GaitaAnalyseTable name={name} form={form} />}
        addParameters={{
          sample: 1000,
          firstSieve: 0,
          secondSieve: 0,
          thirdSieve: 0,
        }}
      />
      <BaseFormList
        name="baitTables"
        addButtonText="Yem Eleme Tablosu Ekle"
        formItems={(name) => <FeedScreenAnalyseTable name={name} form={form} />}
        addParameters={{
          sample: 1000,
          firstSieve: 0,
          secondSieve: 0,
          thirdSieve: 0,
          fourthSieve: 0,
        }}
      />
    </>
  );
};

const getAnalyseAndProtocolForm = (vaccineOptions: any[]) => {
  return (
    <>
      <BaseFormList
        name="vaccines"
        title="Aşı Protokolü"
        addButtonText="Aşı Protokolü Ekle"
        formItems={(name) => (
          <>
            <Form.Item
              label="Aşı Tipi"
              name={[name, "vaccineType"]}
              rules={[Rules.required]}
            >
              <Select options={vaccineOptions} />
            </Form.Item>
            <Form.Item
              label="Uygulama Tarihi"
              name={[name, "implementationDate"]}
              rules={[Rules.required]}
            >
              <DatePick />
            </Form.Item>
            <Form.Item label="Rapel Tarihi" name={[name, "rapelDate"]}>
              <DatePick />
            </Form.Item>
            <Form.Item label="Açıklama" name={[name, "description"]}>
              <TextArea rows={2} placeholder="Açıklama giriniz." />
            </Form.Item>
          </>
        )}
      />
      <BaseFormList
        name="baitAnalyseTables"
        addButtonText="Yem Analiz Tablosu Ekle"
        formItems={(name) => <FeedAnalyseTable name={name} />}
      />
    </>
  );
};
