import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginRoute from "./core/component/navigation/LoginRoute";
import PrivateRoute from "./core/component/navigation/PrivateRoute";
import LoginPage from "./core/pages/LoginPage";
import { getPrivateRoutes } from "./navigation/Navigations";
import "dayjs/locale/tr";
import dayjs from "dayjs";

dayjs.locale("tr");

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/login"
            element={<LoginRoute element={<LoginPage />} />}
          />
          <Route path="/" element={<PrivateRoute />}>
            {getPrivateRoutes()}
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

export default App;
