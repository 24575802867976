import { Typography } from "antd";
import { useEffect, useState } from "react";
import { routineCrudApi } from "../client/EntityApi";
import {
  getRoutineCPColumns,
  getRoutineCPFormItems,
} from "../components/crud/RoutineCPComponents";
import { userCrudApi } from "../core/client/BaseEntityApi";
import CrudTable from "../core/component/crud/CrudTable";

const RoutineCrudPage = () => {
  const { Paragraph, Title } = Typography;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    userCrudApi.getAll().then((response) => {
      const mappedUsers = response["hydra:member"]
        .filter((user: any) => !user.isPassive)
        .map((user: any) => {
          const fullName = user.name + " " + user.surname;
          return {
            text: fullName,
            label: fullName,
            value: user["@id"],
          };
        });
      setUsers(mappedUsers);
    });
  }, []);

  return (
    <CrudTable
      entityLabel="Rutin"
      api={routineCrudApi}
      searchKeys={["name", "definition"]}
      columns={getRoutineCPColumns(users)}
      addFormItems={getRoutineCPFormItems(users)}
      editFormItems={getRoutineCPFormItems(users, true)}
      expandable={{
        expandedRowRender: (record: any) => (
          <Typography>
            <Title level={5}>Görev Tanımı</Title>
            <Paragraph> {record.definition}</Paragraph>
          </Typography>
        ),
      }}
      setEditFields={(row) => {
        return {
          worker: row.worker["@id"],
        };
      }}
    />
  );
};

export default RoutineCrudPage;
