import { Col, Row, Tag } from "antd";
import { getDateString } from "../core/helper/UtilHelper";

const VaccineCard = (props: any) => {
  return (
    <Row style={{ margin: "1em" }}>
      <Col span={24} style={{ border: "1px solid #cecece" }}>
        <Row style={{ padding: "0.5em" }}>
          <Col span={24}>
            <Row style={{ marginBottom: "0.7em" }}>
              <Col span={10}>
                <b>Aşı Türü : </b>
              </Col>
              <Col span={14}>{props.vaccine.vaccineType}</Col>
            </Row>
            <Row style={{ marginBottom: "0.7em" }}>
              <Col span={10}>
                <b>Uygulama Tarihi : </b>
              </Col>
              <Col span={14}>
                {getDateString(props.vaccine.implementationDate)}
              </Col>
            </Row>
            <Row style={{ marginBottom: "0.7em" }}>
              <Col span={10}>
                <b>Rapel Tarihi : </b>
              </Col>
              <Col span={14}>
                {props.vaccine?.rapelDate ? (
                  getDateString(props.vaccine.rapelDate)
                ) : (
                  <Tag color="red">Girilmedi</Tag>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: "0.7em" }}>
              <Col span={10}>
                <b>Açıklama : </b>
              </Col>
              <Col span={14}>
                {props.vaccine?.description ? (
                  props.vaccine.description
                ) : (
                  <Tag color="red">Girilmedi</Tag>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VaccineCard;
