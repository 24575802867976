interface IPetVaccineProps {
  report: any;
}

const PetVaccineReport = (props: IPetVaccineProps) => {
  return (
    <>
      {Object.keys(props.report).map((company: string, index) => {
        return (
          <div key={index} style={{ marginBottom: "1em" }}>
            <h4 style={{ color: "orange" }}>{company}</h4>
            {props.report[company].map(
              (animalInformation: string, index: number) => (
                <div key={index}>{animalInformation}</div>
              )
            )}
          </div>
        );
      })}
    </>
  );
};

export default PetVaccineReport;
