import {
  notificationCrudApi,
  notificationCrudExtendedApi,
} from "../client/EntityApi";
import CrudTable from "../core/component/crud/CrudTable";
import {
  getNotificationCPColumns,
  getNotificationCPFormItems,
} from "../components/crud/NotificationCPComponents";
import { useEffect, useState } from "react";
import { userCrudApi } from "../core/client/BaseEntityApi";
import { getDateFromString } from "../core/helper/UtilHelper";

const NotificationCrudPage = () => {
  const [users, setUsers] = useState<any[]>([]);
  useEffect(() => {
    userCrudApi.getAll().then((response) => {
      const mappedUsers = response["hydra:member"].map((user: any) => {
        return {
          label: user.name + " " + user.surname,
          value: user.id.toString(),
        };
      });
      setUsers(mappedUsers);
    });
  }, []);

  return (
    <CrudTable
      entityLabel="Bildiri"
      api={notificationCrudApi}
      post={notificationCrudExtendedApi.creates}
      searchKeys={["title"]}
      columns={getNotificationCPColumns()}
      addFormItems={getNotificationCPFormItems(users, true)}
      editFormItems={getNotificationCPFormItems(users)}
      beforeEditOperation={(values) => {
        values.worker = "/api/workers/" + values.workerId;
        return values;
      }}
      setEditFields={(row) => {
        return {
          title: row.title,
          description: row.description,
          notificationDate: getDateFromString(row.notificationDate),
          readDate: row.readDate && getDateFromString(row.readDate),
          worker: row.worker["@id"],
          workerId: row.worker.id.toString(),
        };
      }}
    />
  );
};

export default NotificationCrudPage;
