import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AuthHelper } from "../helper/AuthHelper";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
  responseType: "json",
});

api.interceptors.request.use(
  (config: any) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "",
    };

    const accessToken = Cookies.get("accessToken");
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

    config.headers = headers;

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    let errorMessage = "";
    if (error.response.data.errorCode === "SB-403") {
      const refreshToken = Cookies.get("refreshToken");
      if (refreshToken) {
        errorMessage = error.response.data.message;
        await AuthHelper.refreshToken(refreshToken);
      } else {
        AuthHelper.logout();
      }
    } else if (error.response.status === 422) {
      error.response.data.violations.forEach((violation: any) => {
        errorMessage += violation.message + "\n";
      });
    } else if (error.response.data["hydra:description"]) {
      errorMessage = error.response.data["hydra:description"];
    } else {
      errorMessage = error.response.data.message;
    }

    toast.error(errorMessage);
    return Promise.reject(error);
  }
);

export default api;
