import { toast } from "react-toastify";
import api from "./AxiosConfig";

const sendSmsIri = "https://sms.sifirlabir.com/api/send-sms";
const getQuantityIri = "https://sms.sifirlabir.com/api/quantity";
const apiId = "2413748bf3244ebf62d3bcd5";
const apiKey = "38b94e19e575e0b9043fe299";
const originator = "TMRPRO VET.";

const smsApi = {
  sendSms: (message: string, phones: string[]) => {
    const data = {
      apiId: apiId,
      apiKey: apiKey,
      originator: originator,
      message: message,
      phones: phones,
    };

    return api
      .post(sendSmsIri, data)
      .then((response) => {
        toast.success(
          "Mesaj gönderildi. Kalan bakiye: " + response.data.quantity
        );
        return response;
      })
      .catch(() => toast.error("Mesaj gönderilemedi."));
  },

  getQuantity: () => {
    const data = {
      apiId: apiId,
      apiKey: apiKey,
    };

    return api
      .post(getQuantityIri, data)
      .then((response) => {
        toast.success("Kalan bakiye: " + response.data.quantity);
        return response;
      })
      .catch(() => toast.error("Bakiye sorgulanamadı."));
  },
};

export default smsApi;
