import moment from "moment";
import YesNoTag from "../../core/component/YesNoTag";
import { getDateString } from "../../core/helper/UtilHelper";

export const getGeneratedRecipeCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Üretilen Reçete",
      dataIndex: "recipe",
      key: "recipe",
      render: (value: any) => value.name,
    },
    {
      title: "Üretim Miktarı",
      dataIndex: "quantity",
      key: "quantity",
      render: (value: any) => value,
    },
    {
      title: "Üretim Durumu",
      dataIndex: "isBack",
      key: "isBack",
      render: (value: any) => (
        <YesNoTag
          success={!value}
          successText="Üretim Yapıldı"
          failText="Üretim İptal Edildi"
        />
      ),
    },
    {
      title: "Üretim Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => (value ? getDateString(value) : "Girilmedi"),
      sorter: {
        compare: (a: any, b: any) =>
          moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      },
    },
    {
      title: "Üretim İptal Tarihi",
      dataIndex: "cancelDate",
      key: "cancelDate",
      render: (value: any) => (value ? getDateString(value) : "Girilmedi"),
    },
  ];
};
