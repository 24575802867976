import Icon from "@ant-design/icons";

const AddStockIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1.5em",
          height: "1.5em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        enableBackground="new 0 0 1000 1000"
        viewBox="0 0 512 512"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M2100 4790 l-585 -5 -642 -512 c-353 -282 -654 -519 -667 -528 -13
-9 -28 -28 -33 -43 -4 -15 -6 -685 -3 -1490 5 -1434 5 -1464 24 -1478 17 -12
225 -14 1408 -14 l1389 0 24 25 c14 13 25 31 25 39 0 31 -24 65 -53 76 -20 7
-455 10 -1353 10 l-1324 0 0 1365 0 1365 540 0 540 0 0 -585 c0 -572 0 -585
20 -605 19 -19 33 -20 469 -20 l450 0 20 26 c21 26 21 35 21 605 l0 579 570 0
570 0 0 -694 c0 -659 1 -695 18 -717 25 -30 75 -30 105 0 l22 22 3 718 3 719
404 434 c223 238 409 434 414 436 4 1 6 -517 3 -1152 l-5 -1154 26 -26 c35
-35 79 -35 106 0 21 27 21 27 21 1299 0 1226 -1 1273 -19 1289 -17 15 -95 17
-972 19 -525 1 -1217 0 -1539 -3z m380 -154 c-8 -8 -246 -210 -528 -450 l-514
-436 -491 0 c-298 0 -487 4 -482 9 6 5 259 208 564 450 l554 441 456 0 c392 0
453 -2 441 -14z m750 0 c-8 -8 -244 -210 -524 -450 l-509 -436 -266 0 c-155 0
-261 4 -255 9 5 5 243 207 529 450 l519 441 260 0 c224 0 258 -2 246 -14z
m1150 8 c0 -3 -187 -205 -416 -450 l-415 -444 -562 0 c-480 0 -559 2 -547 14
8 8 244 210 523 450 l508 436 455 0 c250 0 454 -3 454 -6z m-2160 -1579 l0
-535 -342 2 -343 3 -3 533 -2 532 345 0 345 0 0 -535z"
          />
          <path
            d="M3921 2089 c-29 -5 -92 -22 -140 -38 -229 -78 -403 -229 -511 -446
-74 -147 -93 -229 -93 -400 0 -166 19 -250 87 -390 146 -302 426 -486 766
-502 448 -21 840 295 919 741 23 130 11 295 -31 426 -80 248 -273 456 -518
556 -137 56 -331 77 -479 53z m312 -155 c92 -20 236 -90 307 -148 270 -224
352 -605 196 -914 -76 -151 -185 -259 -338 -337 -121 -61 -209 -79 -361 -73
-144 6 -237 32 -348 98 -230 136 -369 380 -369 645 0 344 228 636 565 725 89
24 250 26 348 4z"
          />
          <path
            d="M4019 1671 c-23 -19 -24 -24 -27 -205 l-3 -186 -180 0 c-178 0 -180
0 -204 -25 -32 -31 -32 -69 0 -100 24 -25 26 -25 204 -25 l180 0 3 -186 c3
-181 4 -186 27 -205 32 -26 73 -24 99 4 21 22 22 32 22 205 l0 182 179 0 c165
0 180 2 205 21 19 15 26 30 26 54 0 24 -7 39 -26 54 -25 19 -40 21 -205 21
l-179 0 0 183 c0 179 -1 184 -23 205 -29 27 -67 28 -98 3z"
          />
          <path
            d="M545 1505 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 22 -23 31
-25 129 -25 58 0 116 5 130 11 51 23 55 103 7 128 -11 6 -70 11 -131 11 -104
0 -112 -1 -135 -25z"
          />
          <path
            d="M542 1187 c-27 -29 -28 -71 -2 -97 19 -19 33 -20 245 -20 212 0 226
1 245 20 27 27 26 71 -3 98 -22 21 -31 22 -243 22 -218 0 -221 0 -242 -23z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default AddStockIcon;
