import { useEffect, useRef, useState } from "react";
import { companyExtendedApi } from "../client/EntityApi";
import PeriodSelect from "../components/PeriodSelect";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { getMyPeriodicTasksCPColumns } from "../components/crud/MyPeriodicTasksCPComponents";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { EnvironmentOutlined, EyeOutlined } from "@ant-design/icons";

const MyPeriodicTasksCrudPage = () => {
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [selectedPeriodId, setSelectedPeriodId] = useState<number>();
  const [taskCount, setTaskCount] = useState<number>(0);

  useEffect(() => {
    crudPageRef.current?.refreshData();
  }, [selectedPeriodId]);

  const getAll = () => {
    return selectedPeriodId
      ? companyExtendedApi.getUserTaskCategories(selectedPeriodId)
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        api={{}}
        getAll={getAll}
        columns={getMyPeriodicTasksCPColumns()}
        searchKeys={["company.name"]}
        customTableTitle={() => <b>Kalan Görev Sayısı : {taskCount}</b>}
        hideAddProcess
        hideDefaultRowProceses
        extraRowProcess={(row) => (
          <>
            <CrudPageProcessButton
              tooltipText="Konum"
              icon={<EnvironmentOutlined />}
              onClick={() => window.open(row.company.locationLink)}
            />
            <CrudPageProcessButton
              tooltipText="Detaya Git"
              icon={<EyeOutlined />}
              onClick={() => window.open("/isletme-detay/" + row.company.id)}
            />
          </>
        )}
        extraTitleProcess={() => (
          <PeriodSelect
            style={{ width: "50%", marginRight: "1em" }}
            selectedPeriodId={selectedPeriodId}
            setSelectedPeriodId={setSelectedPeriodId}
          />
        )}
        afterRefreshOperation={(data) => setTaskCount(data?.length)}
      />
    </>
  );
};

export default MyPeriodicTasksCrudPage;
