import { Tag } from "antd";
import { useEffect, useState } from "react";

interface IColoredTagProps {
  selected: string;
  baseText?: string;
  options: IColoredTagOption[];
}

interface IColoredTagOption {
  key: string;
  color: string;
  text: string;
}

const ColoredTag = (props: IColoredTagProps) => {
  const [selected, setSelected] = useState({ color: "", text: "" });

  useEffect(() => {
    const findedOption = props.options.find(
      (option) => option.key === props.selected
    );
    if (findedOption)
      setSelected({ color: findedOption.color, text: findedOption.text });
  }, [props]);

  return (
    <Tag color={selected.color}>
      {(props.baseText ?? "") + " " + selected.text}
    </Tag>
  );
};

export default ColoredTag;
