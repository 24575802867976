import Icon from "@ant-design/icons";
const BabyIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="6330"
      >
        <path
          d="M699.302 312.036c-21.812-27.282-43.312-48.874-44.25-49.78-4-4-9.376-6.25-15.06-6.25H383.98a21.286 21.286 0 0 0-15.062 6.25c-0.906 0.906-22.438 22.5-44.25 49.78-31.844 39.812-47.344 70.342-47.344 93.31v554.646c0 35.312 28.718 64 64 64h341.354c35.25 0 63.998-28.688 63.998-64V405.346c0-22.968-15.5-53.498-47.374-93.31z"
          fill="#E6E9ED"
          p-id="6331"
        ></path>
        <path
          d="M277.324 597.344v362.648c0 35.312 28.718 64 64 64h341.354c35.25 0 63.998-28.688 63.998-64V597.344H277.324z"
          fill="#4FC2E9"
          p-id="6332"
        ></path>
        <path
          d="M699.302 312.036c-21.812-27.282-43.312-48.874-44.25-49.78-4-4-9.376-6.25-15.06-6.25h-42.688c5.688 0 11.124 2.25 15.124 6.25 0.876 0.906 22.376 22.5 44.248 49.78 31.812 39.812 47.312 70.342 47.312 93.31v554.646c0 35.312-28.688 64-63.998 64h42.686c35.25 0 63.998-28.688 63.998-64V405.346c0.002-22.968-15.498-53.498-47.372-93.31z"
          fill="#FFFFFF"
          opacity=".2"
          p-id="6333"
        ></path>
        <path
          d="M341.324 767.996c11.78 0 21.342-9.532 21.342-21.312s-9.562-21.344-21.342-21.344h-64v42.656h64zM341.324 895.992c11.78 0 21.342-9.528 21.342-21.308s-9.562-21.344-21.342-21.344h-64v42.652h64zM341.324 640.032c11.78 0 21.342-9.562 21.342-21.344s-9.562-21.344-21.342-21.344h-64v42.688h64zM341.324 512.032c11.78 0 21.342-9.562 21.342-21.344s-9.562-21.344-21.342-21.344h-64v42.688h64z"
          fill="#434A54"
          p-id="6334"
        ></path>
        <path
          d="M597.554 170.694c-3-1.75-12-14.5-17.5-52.312-4-27.686-4.062-54.092-4.062-54.374 0-35.282-28.688-64-64.016-64-35.28 0-64 28.718-64 64 0 0.282-0.062 26.686-4.062 54.374-5.5 37.812-14.5 50.562-17.5 52.312-11.686 0.124-21.092 9.624-21.092 21.312a21.332 21.332 0 0 0 21.342 21.344h170.64c11.812 0 21.376-9.532 21.376-21.344 0-11.688-9.438-21.186-21.126-21.312z"
          fill="#967ADC"
          p-id="6335"
        ></path>
        <path
          d="M639.992 170.694H383.98c-35.282 0-64 28.718-64 63.998v85.312h384.01v-85.312c0-35.28-28.688-63.998-63.998-63.998z"
          fill="#AC92EB"
          p-id="6336"
        ></path>
      </svg>
    )}
    {...props}
  />
);

export default BabyIcon;
