import { Form, Input, Select, Image, Divider, TreeSelect } from "antd";
import DatePick from "../../core/component/DatePick";
import Upload from "../../core/component/Upload";
import { getConstantValue, getDateString } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";
import BaseFormList from "../BaseFormList";

export const getPetCPColumns = (genus: any[]) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Çip No",
      dataIndex: "chipNo",
      key: "chipNo",
      render: (value: any) => value ?? "-",
    },
    {
      title: "Pasaport No",
      dataIndex: "passportNo",
      key: "passportNo",
      render: (value: any) => value ?? "-",
    },
    {
      title: "Cins",
      dataIndex: "genus",
      key: "genus",
      render: (value: any) => getConstantValue(value, genus),
    },
    {
      title: "Irk",
      dataIndex: "racial",
      key: "racial",
      render: (value: any) => value ?? "-",
    },
    {
      title: "Doğum Tarihi",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (value: any) => (value ? getDateString(value) : "-"),
    },
  ];
};

export const getPetCPFormItems = (
  id: any,
  genus: any[],
  vaccines: any[],
  vaccineStates: any[]
) => {
  return (
    <>
      <Form.Item
        hidden
        name="owner"
        rules={[Rules.required]}
        initialValue={"api/pet-owners/" + id}
      >
        <Input hidden />
      </Form.Item>
      <Form.Item
        label="Adı"
        name="name"
        rules={[Rules.required, Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Çip No"
        name="chipNo"
        rules={[Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Pasaport No"
        name="passportNo"
        rules={[Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Cins" name="genus" rules={[Rules.required]}>
        <Select options={genus} />
      </Form.Item>
      <Form.Item
        label="Irk"
        name="racial"
        rules={[Rules.min(2), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Doğum Tarihi" name="birthDate">
        <DatePick />
      </Form.Item>
      <BaseFormList
        name="petProcesses"
        addButtonText="Müdahele Ekle"
        formItems={(name) => getPetProcessFormListItems(name)}
      />
      <BaseFormList
        name="petVaccineRecords"
        addButtonText="Aşı Kaydı Ekle"
        formItems={(name) =>
          getPetVaccineFormListItems(name, vaccines, vaccineStates)
        }
      />
    </>
  );
};

export const getPetProcessFormItems = () => {
  const { TextArea } = Input;

  return (
    <>
      <Form.Item label="Şüphe" name="suspicion" rules={[Rules.required]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Teşhis" name="diagnosis" rules={[Rules.max(500)]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Tedavi" name="cure" rules={[Rules.max(500)]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name="media">
        <Upload />
      </Form.Item>
    </>
  );
};

export const getAnimalProcessFormItems = (diagnoses: any[]) => {
  const { TextArea } = Input;

  return (
    <>
      <Form.Item label="Şüphe" name="suspicion" rules={[Rules.required]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Teşhis" name="diagnosis" rules={[Rules.max(500)]}>
        <TreeSelect treeData={diagnoses} />
      </Form.Item>
      <Form.Item label="Tedavi" name="cure" rules={[Rules.max(500)]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name="media">
        <Upload />
      </Form.Item>
    </>
  );
};

export const getAnimalProcessFormListItems = (
  name: string,
  diagnoses: any[]
) => {
  const { TextArea } = Input;

  return (
    <>
      <Form.Item
        label="Şüphe"
        name={[name, "suspicion"]}
        rules={[Rules.required]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Teşhis" name={[name, "diagnosis"]}>
        <TreeSelect treeData={diagnoses} />
      </Form.Item>
      <Form.Item label="Tedavi" name={[name, "cure"]} rules={[Rules.max(500)]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name={[name, "media"]}>
        <Upload />
      </Form.Item>
    </>
  );
};

export const getPetProcessFormListItems = (name: string) => {
  const { TextArea } = Input;

  return (
    <>
      <Form.Item
        label="Şüphe"
        name={[name, "suspicion"]}
        rules={[Rules.required]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        label="Teşhis"
        name={[name, "diagnosis"]}
        rules={[Rules.max(500)]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item label="Tedavi" name={[name, "cure"]} rules={[Rules.max(500)]}>
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item name={[name, "media"]}>
        <Upload />
      </Form.Item>
    </>
  );
};

export const getPetVaccineFormItems = (
  vaccines: any[],
  vaccineStates: any[]
) => {
  return (
    <>
      <Form.Item label="Aşı" name="vaccine" rules={[Rules.required]}>
        <Select options={vaccines} />
      </Form.Item>
      <Form.Item label="Durum" name="state" rules={[Rules.required]}>
        <Select options={vaccineStates} />
      </Form.Item>
      <Form.Item label="Tarih" name="date" rules={[Rules.required]}>
        <DatePick />
      </Form.Item>
    </>
  );
};

export const getPetVaccineFormListItems = (
  name: string,
  vaccines: any[],
  vaccineStates: any[]
) => {
  return (
    <>
      <Form.Item label="Aşı" name={[name, "vaccine"]} rules={[Rules.required]}>
        <Select options={vaccines} />
      </Form.Item>
      <Form.Item label="Durum" name={[name, "state"]} rules={[Rules.required]}>
        <Select options={vaccineStates} />
      </Form.Item>
      <Form.Item label="Tarih" name={[name, "date"]} rules={[Rules.required]}>
        <DatePick />
      </Form.Item>
    </>
  );
};

const boxStyle = {
  border: "1px solid grey",
  padding: "5px",
  margin: "5px 0",
};

export const getPetCPExpandedRowRender = (
  record: any,
  vaccineStatus: any[]
) => {
  return (
    <>
      <div style={boxStyle}>
        <h3>
          <b>Aşı Listesi ({record.petVaccineRecords.length + " Adet"})</b>
        </h3>
        <ul>
          {record.petVaccineRecords.map(
            (petVaccineRecord: any, index: number) => (
              <li key={index}>
                {petVaccineRecord.vaccine.name} |{" "}
                {getDateString(petVaccineRecord.date)} |{" "}
                {getConstantValue(petVaccineRecord.state, vaccineStatus)}
              </li>
            )
          )}
        </ul>
      </div>
      <div style={boxStyle}>
        <h3>
          <b>Müdahale Listesi ({record.petProcesses.length + " Adet"})</b>
          <Divider />
          {record.petProcesses.map((petProcess: any, index: number) => (
            <div key={index}>
              <p>
                <b>Şüphe</b> : {petProcess.suspicion ?? "-"}
              </p>
              <p>
                <b>Teşhis</b> : {petProcess.diagnosis ?? "-"}
              </p>
              <p>
                <b>Tedavi</b> : {petProcess.cure ?? "-"}
              </p>
              <p>
                <b>Tarih</b> : {getDateString(petProcess.createdAt)}
              </p>
              {petProcess.media && (
                <Image
                  width={200}
                  src={process.env.REACT_APP_BASE_URL + petProcess.media.uid}
                />
              )}
              <Divider />
            </div>
          ))}
        </h3>
      </div>
    </>
  );
};
