import Icon from "@ant-design/icons";

const PetIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "20px",
          height: "20px",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="64.000000pt"
        height="64.000000pt"
        viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M320 633 c-28 -5 -187 -156 -201 -190 -5 -14 -5 -33 1 -48 8 -21 4
-33 -24 -73 -42 -59 -100 -171 -92 -179 8 -8 6 -11 50 72 64 119 80 135 132
135 25 0 54 4 64 10 23 12 56 58 75 104 21 51 0 45 -26 -6 -34 -68 -57 -88
-101 -88 -29 0 -41 6 -53 24 -21 32 -15 42 83 140 74 72 83 78 111 73 19 -4
31 -2 31 4 0 21 54 -2 90 -37 20 -19 46 -34 58 -34 12 0 26 -9 32 -19 5 -11
23 -22 40 -26 31 -7 39 -25 18 -43 -7 -5 -54 -15 -105 -22 -82 -11 -95 -11
-117 4 -29 19 -36 20 -36 6 0 -6 13 -18 28 -28 16 -9 27 -23 24 -30 -3 -10 -7
-10 -18 -1 -12 10 -24 3 -65 -37 -74 -73 -102 -138 -107 -253 -3 -68 -1 -92 7
-87 6 4 11 36 11 75 0 38 7 88 15 112 19 53 68 127 101 152 24 18 24 18 24 -8
0 -14 -11 -37 -24 -50 -31 -31 -13 -45 18 -16 41 38 118 9 129 -49 5 -22 12
-29 34 -32 25 -3 28 -7 26 -38 -2 -41 -24 -60 -71 -60 -65 0 -90 -33 -57 -76
14 -18 15 -17 12 16 l-2 35 55 6 c60 8 76 22 86 77 7 38 -11 62 -48 62 -11 0
-18 7 -18 19 0 31 -39 71 -68 71 -50 0 -57 13 -32 58 22 39 24 40 104 52 108
16 126 28 126 83 0 24 -5 48 -12 55 -7 7 -37 12 -68 12 -48 0 -60 4 -81 26
-41 45 -89 59 -159 47z m295 -104 c4 -6 5 -13 2 -16 -8 -7 -47 7 -47 18 0 13
37 11 45 -2z"
          />
          <path d="M406 553 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
          <path d="M417 224 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default PetIcon;
