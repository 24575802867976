import { Form, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import YesNoTag from "../../core/component/YesNoTag";
import { Rules } from "../../core/Rules";

export const getPetOwnerCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Adı",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Soyadı",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      render: (value: any) => <YesNoTag success={value} />,
      defaultFilteredValue: [true],
      filters: [
        { text: "Aktif", value: true },
        { text: "Pasif", value: false },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
  ];
};

export const getPetOwnerCPFormItems = () => {
  return (
    <>
      <Form.Item
        label="Adı"
        name="firstname"
        rules={[Rules.required, Rules.min(2), Rules.max(100)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Soyadı"
        name="lastname"
        rules={[Rules.required, Rules.min(2), Rules.max(100)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Telefon"
        name="phone"
        rules={[Rules.required, Rules.min(10), Rules.max(10)]}
      >
        <Input placeholder="Başında sıfır olmadan ve boşluksuz yazınız. Ör: 5334441122" />
      </Form.Item>
      <Form.Item label="Adres" name="address" rules={[Rules.max(500)]}>
        <TextArea />
      </Form.Item>
      <Form.Item
        name="username"
        label="Kullanıcı Adı"
        rules={[Rules.min(3), Rules.max(255)]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Şifre"
        rules={[Rules.min(4), Rules.max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Aktif mi?"
        name={"isActive"}
        initialValue={true}
        rules={[Rules.required]}
      >
        <Select
          options={[
            { label: "Aktif", value: true },
            { label: "Pasif", value: false },
          ]}
        />
      </Form.Item>
    </>
  );
};
