import SidebarLayout from "../core/layout/SidebarLayout";
import { getMenuItems } from "../navigation/Navigations";
import {
  LogoutOutlined,
  BackwardOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import HeaderBadge from "../core/component/HeaderBadge";
import { AuthHelper } from "../core/helper/AuthHelper";
import OwnNotifications from "../components/notification/OwnNotifications";
import AllNotifications from "../components/notification/AllNotifications";
import { useNavigate } from "react-router-dom";

const Layout = (props: any) => {
  const navigate = useNavigate();

  return (
    <SidebarLayout
      title={
        <img
          width={50}
          height={30}
          alt="logo"
          src="https://www.logomaker.com/api/main/images/1j+ojVVCOMkX9Wyrexe4hGfKx6jTuktNlQiRjmpmYzBL9A5olyYukLc1rLx+fxAB51QCihENcs86nnUlDt5c0UUgvDKM"
        />
      }
      //TODO: Logo değişecek
      navigations={getMenuItems()}
      extra={(isSmallScreen: any) => (
        <>
          <HeaderBadge
            tooltipText="Geri Dön"
            avatarIcon={<BackwardOutlined />}
            onClick={() => navigate(-1)}
            isSmallScreen={isSmallScreen}
          />
          {AuthHelper.checkVisibility("Tüm Bildirimler") && (
            <AllNotifications isSmallScreen={isSmallScreen} />
          )}
          <OwnNotifications isSmallScreen={isSmallScreen} />
          <HeaderBadge
            tooltipText="İleri Git"
            avatarIcon={<ForwardOutlined />}
            onClick={() => navigate(1)}
            isSmallScreen={isSmallScreen}
          />
          <HeaderBadge
            tooltipText="Çıkış Yap"
            avatarIcon={<LogoutOutlined />}
            onClick={() => AuthHelper.logout()}
            isSmallScreen={isSmallScreen}
          />
        </>
      )}
    >
      {props.children}
    </SidebarLayout>
  );
};

export default Layout;
