export const getMyPeriodicTasksCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İşletme Adı",
      dataIndex: "company",
      key: "companyName",
      render: (value: any) => value.name,
    },
    {
      title: "Yetkili Tel.",
      dataIndex: "company",
      key: "companyManagerPhone",
      render: (value: any) => value.managerPhone,
    },
    {
      title: "Görev",
      dataIndex: "service",
      key: "serviceName",
      render: (value: any) => value.name,
    },
  ];
};
