import { Button, Col, Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

interface IAnimalReportColumnProps {
  columnName: string;
  value?: any;
  data?: any[];
  condition?: boolean;
  setEarringModalIsOpen?: (open: boolean) => void;
  setEarringNoData?: (data: any) => void;
}

const AnimalReportColumn = (props: IAnimalReportColumnProps) => {
  const openModal = () => {
    if (props.setEarringNoData && props.setEarringModalIsOpen) {
      if (props.data?.length === 0) {
        toast.warning("Gösterilecek hayvan bilgisi bulunmamakta.");
        return;
      }
      props.setEarringNoData(props.data);
      props.setEarringModalIsOpen(true);
    }
  };

  const getDisplay = () => {
    let component = <LoadingOutlined />;
    if (props.value !== undefined) {
      component = props.value;
      if (props.data !== undefined) {
        component = (
          <Button type="link" onClick={openModal}>
            {props.value}
          </Button>
        );
      }

      if (props.condition !== undefined) {
        component = (
          <span style={{ color: props.condition ? "green" : "red" }}>
            {props.value}
          </span>
        );
      }
    }

    return component;
  };

  return (
    <Row
      style={{
        border: "1px solid black",
        margin: "0.5em",
        padding: "1em",
        width: 150,
        height: 70,
      }}
    >
      <Col span={24}>
        <Row align="middle" justify="center">
          {props.columnName}
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center">
          {getDisplay()}
        </Row>
      </Col>
    </Row>
  );
};

export default AnimalReportColumn;
