import {
  SolutionOutlined,
  CreditCardOutlined,
  InfoCircleOutlined,
  ExperimentOutlined,
  BankOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";
import { Link, Route } from "react-router-dom";
import { AuthHelper } from "../core/helper/AuthHelper";
import Layout from "../layout/Layout";
import BusinessTasksCrudPage from "../pages/BusinessTasksCrudPage";
import CompanyCrudPage from "../pages/CompanyCrudPage";
import CompanyDetail from "../pages/CompanyDetail";
import CPeriodicTasksCrudPage from "../pages/CPeriodicTasksCrudPage";
import GeneratedRecipeCrudPage from "../pages/GeneratedRecipeCrudPage";
import MaterialCrudPage from "../pages/MaterialCrudPage";
import MyPeriodicTasksCrudPage from "../pages/MyPeriodicTasksCrudPage";
import NotificationCrudPage from "../pages/NotificationCrudPage";
import OrderCrudPage from "../pages/OrderCrudPage";
import PerformanceReportCrudPage from "../pages/PerformanceReportCrudPage";
import PeriodCrudPage from "../pages/PeriodCrudPage";
import PriceCrudPage from "../pages/PriceCrudPage";
import ProfilePage from "../pages/ProfilePage";
import RecipeCrudPage from "../pages/RecipeCrudPage";
import UsageDocument from "../pages/UsageDocument";
import UserCrudPage from "../pages/UserCrudPage";
import VaccineCrudPage from "../pages/VaccineCrudPage";
import TaskApproveCrudPage from "../pages/TaskApproveCrudPage";
import AnimalCrudPage from "../pages/AnimalCrudPage";
import ConsumptionCrudPage from "../pages/ConsumptionCrudPage";
import RoutineCrudPage from "../pages/RoutineCrudPage";
import PetVaccineCrudPage from "../pages/PetVaccineCrudPage";
import PetOwnerCrudPage from "../pages/PetOwnerCrudPage";
import PawPrintIcon from "../Icons/PawPrintIcon";
import PetCrudPage from "../pages/PetCrudPage";

export const getPrivateRoutes = () => {
  return (
    <>
      <Route index element={<Layout />} />
      {AuthHelper.checkVisibility("Aylık İşletme Görevleri Sayfası") &&
        getRoute(<CPeriodicTasksCrudPage />, "/aylik-isletme-gorevleri")}
      {AuthHelper.checkVisibility("Aylık Görevlerim Sayfası") &&
        getRoute(<MyPeriodicTasksCrudPage />, "/aylik-gorevlerim")}
      {AuthHelper.checkVisibility("Performans Raporu Sayfası") &&
        getRoute(<PerformanceReportCrudPage />, "/performans-raporu")}
      {AuthHelper.checkVisibility("İşletmeler Sayfası") &&
        getRoute(<CompanyCrudPage />, "/isletmeler")}
      {AuthHelper.checkVisibility("İşletme Detay Sayfası") &&
        getRoute(<CompanyDetail />, "/isletme-detay/:id")}
      {AuthHelper.checkVisibility("İşletme Sürü Sayfası") &&
        getRoute(<AnimalCrudPage />, "/isletme/:id/suru")}
      {AuthHelper.checkVisibility("İşletme Sarfiyat Sayfası") &&
        getRoute(<ConsumptionCrudPage />, "/isletme/:id/sarfiyat")}
      {AuthHelper.checkVisibility("Görev Onay sayfası") &&
        getRoute(<TaskApproveCrudPage />, "/gorev-onay")}
      {AuthHelper.checkVisibility("Kullanım Dökümanı Sayfası") &&
        getRoute(<UsageDocument />, "/kullanim-dokumani")}
      {AuthHelper.checkVisibility("İdari Görevler Sayfası") &&
        getRoute(<BusinessTasksCrudPage />, "/idari-gorevler")}
      {AuthHelper.checkVisibility("Rutin Atama Sayfası") &&
        getRoute(<RoutineCrudPage />, "/rutinler")}
      {AuthHelper.checkVisibility("Pet Sahipleri Sayfası") &&
        getRoute(<PetOwnerCrudPage />, "/pet-sahipleri")}
      {AuthHelper.checkVisibility("Pet Sahipleri Sayfası") &&
        getRoute(<PetCrudPage />, "/sahip/:id/pet")}
      {AuthHelper.checkVisibility("Pet Aşıları Sayfası") &&
        getRoute(<PetVaccineCrudPage />, "/pet-asilari")}
      {AuthHelper.checkVisibility("Hammaddeler Sayfası") &&
        getRoute(<MaterialCrudPage />, "/hammaddeler")}
      {AuthHelper.checkVisibility("Reçeteler Sayfası") &&
        getRoute(<RecipeCrudPage />, "/receteler")}
      {AuthHelper.checkVisibility("Üretim Geçmişi Sayfası") &&
        getRoute(<GeneratedRecipeCrudPage />, "/uretim-gecmisi")}
      {AuthHelper.checkVisibility("Fiyat Listesi Sayfası") &&
        getRoute(<PriceCrudPage />, "/fiyat-listesi")}
      {AuthHelper.checkVisibility("Sipariş Listesi Sayfası") &&
        getRoute(<OrderCrudPage />, "/siparis-listesi")}
      {AuthHelper.checkVisibility("Ajanda Sayfası") &&
        getRoute(<NotificationCrudPage />, "/ajanda")}
      {AuthHelper.checkVisibility("Dönem Sayfası") &&
        getRoute(<PeriodCrudPage />, "/donem")}
      {AuthHelper.checkVisibility("Aşı Sayfası") &&
        getRoute(<VaccineCrudPage />, "/asi")}
      {AuthHelper.checkVisibility("Kullanıcı Yönetimi Sayfası") &&
        getRoute(<UserCrudPage />, "/kullanici-yonetimi")}
      {getRoute(<ProfilePage />, "/profil")}
    </>
  );
};

const getRoute = (component: ReactNode, path: string) => {
  return <Route path={path} element={<Layout>{component}</Layout>} />;
};

export const getMenuItems = () => {
  return [
    AuthHelper.checkVisibility("İş/İşletme Menüsü") && {
      label: "İş / İşletme",
      key: "is-isletme",
      icon: <BankOutlined />,
      children: [
        AuthHelper.checkVisibility("Raporlar Menüsü") && {
          label: "Raporlar",
          key: "raporlar",
          children: [
            AuthHelper.checkVisibility("Aylık İşletme Görevleri Sayfası") && {
              label: (
                <Link to="/aylik-isletme-gorevleri">
                  Aylık İşletme Görevleri
                </Link>
              ),
              key: "aylik-isletme-gorevleri",
            },
            AuthHelper.checkVisibility("Aylık Görevlerim Sayfası") && {
              label: <Link to="/aylik-gorevlerim">Aylık Görevlerim</Link>,
              key: "aylik-gorevlerim",
            },
            AuthHelper.checkVisibility("Performans Raporu Sayfası") && {
              label: <Link to="/performans-raporu">Performans Raporu</Link>,
              key: "performans-raporu",
            },
          ],
        },
        AuthHelper.checkVisibility("İşletmeler Sayfası") && {
          label: <Link to="/isletmeler">İşletmeler</Link>,
          key: "isletmeler",
        },
        AuthHelper.checkVisibility("Görev Onay sayfası") && {
          label: <Link to="/gorev-onay">Görev Onay</Link>,
          key: "gorev-onay",
        },
        AuthHelper.checkVisibility("Kullanım Dökümanı Sayfası") && {
          label: <Link to="/kullanim-dokumani">Kullanım Dökümanı</Link>,
          key: "kullanim-dokumani",
        },
      ],
    },
    AuthHelper.checkVisibility("İdari Menüsü") && {
      label: "İdari",
      key: "idari",
      icon: <InfoCircleOutlined />,
      children: [
        AuthHelper.checkVisibility("İdari Görevler Sayfası") && {
          label: <Link to="/idari-gorevler">İdari Görevler</Link>,
          key: "idari-gorevler",
        },
        AuthHelper.checkVisibility("Rutin Atama Sayfası") && {
          label: <Link to="/rutinler">Rutin Atama</Link>,
          key: "rutinler",
        },
      ],
    },
    AuthHelper.checkVisibility("Pet Takip Menüsü") && {
      label: "Pet Takip",
      key: "pet",
      icon: <PawPrintIcon />,
      children: [
        AuthHelper.checkVisibility("Pet Sahipleri Sayfası") && {
          label: <Link to="/pet-sahipleri">Pet Sahipleri</Link>,
          key: "pet-sahipleri",
        },
        AuthHelper.checkVisibility("Pet Aşıları Sayfası") && {
          label: <Link to="/pet-asilari">Pet Aşıları</Link>,
          key: "pet-asilar",
        },
      ],
    },
    AuthHelper.checkVisibility("Üretim Menüsü") && {
      label: "Üretim",
      key: "uretim",
      icon: <ExperimentOutlined />,
      children: [
        AuthHelper.checkVisibility("Hammaddeler Sayfası") && {
          label: <Link to="/hammaddeler">Hammaddeler</Link>,
          key: "hammaddeler",
        },
        AuthHelper.checkVisibility("Reçeteler Sayfası") && {
          label: <Link to="/receteler">Reçeteler</Link>,
          key: "receteler",
        },
        AuthHelper.checkVisibility("Üretim Geçmişi Sayfası") && {
          label: <Link to="/uretim-gecmisi">Üretim Geçmişi</Link>,
          key: "uretim-gecmisi",
        },
      ],
    },
    AuthHelper.checkVisibility("Sipariş Menüsü") && {
      label: "Sipariş",
      key: "siparis",
      icon: <CreditCardOutlined />,
      children: [
        AuthHelper.checkVisibility("Fiyat Listesi Sayfası") && {
          label: <Link to="/fiyat-listesi">Fiyat Listesi</Link>,
          key: "fiyat-listesi",
        },
        AuthHelper.checkVisibility("Sipariş Listesi Sayfası") && {
          label: <Link to="/siparis-listesi">Sipariş Listesi</Link>,
          key: "siparis-listesi",
        },
      ],
    },
    AuthHelper.checkVisibility("Yönetici Menüsü") && {
      label: "Yönetici",
      key: "yonetici",
      icon: <SolutionOutlined />,
      children: [
        AuthHelper.checkVisibility("Ajanda Sayfası") && {
          label: <Link to="/ajanda">Ajanda</Link>,
          key: "ajanda",
        },
        AuthHelper.checkVisibility("Dönem Sayfası") && {
          label: <Link to="/donem">Dönem</Link>,
          key: "donem",
        },
        AuthHelper.checkVisibility("Aşı Sayfası") && {
          label: <Link to="/asi">Aşı</Link>,
          key: "asi",
        },
        AuthHelper.checkVisibility("Kullanıcı Yönetimi Sayfası") && {
          label: <Link to="/kullanici-yonetimi">Kullanıcı Yönetimi</Link>,
          key: "kullanici-yonetimi",
        },
      ],
    },
    {
      label: "Hesap Yönetimi",
      key: "hesap-yonetimi",
      icon: <UserOutlined />,
      children: [
        {
          label: <Link to="/profil">Profil</Link>,
          key: "profil",
        },
        {
          label: <span onClick={AuthHelper.logout}>Çıkış Yap</span>,
          key: "cikis",
        },
      ],
    },
  ];
};
