import { Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { cellStyle, mathRound } from "../../core/helper/UtilHelper";
import { Rules } from "../../core/Rules";

interface IGaitaAnalyseTableProps {
  name: string;
  form: any;
}

const GaitaAnalyseTable = (props: IGaitaAnalyseTableProps) => {
  const [sample, setSample] = useState<number>(1000);
  const [firstSieve, setFirstSieve] = useState<number>(0);
  const [secondSieve, setSecondSieve] = useState<number>(0);
  const [thirdSieve, setThirdSieve] = useState<number>(0);

  useEffect(() => {
    const fields = props.form.getFieldValue("gaitaTables");
    setSample(fields[props.name]["sample"]);
    setFirstSieve(fields[props.name]["firstSieve"]);
    setSecondSieve(fields[props.name]["secondSieve"]);
    setThirdSieve(fields[props.name]["thirdSieve"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form]);

  return (
    <table
      style={{
        width: "100%",
        textAlign: "center",
        borderCollapse: "collapse",
        border: "1px solid #dddddd",
        margin: "1em 0px 1em 0px",
      }}
    >
      <thead>
        <tr style={cellStyle}>
          <th colSpan={4} style={cellStyle}>
            Gaita Elek Analizi
          </th>
          <th colSpan={1} style={cellStyle}>
            <Form.Item
              label="Numune (gr)"
              name={[props.name, "sample"]}
              rules={[Rules.required]}
            >
              <InputNumber
                onChange={(value) => setSample(value)}
                min={0}
                decimalSeparator=","
              />
            </Form.Item>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={cellStyle}>
          <th colSpan={5} style={cellStyle}>
            <Form.Item
              label="Padok İsmi"
              name={[props.name, "padokName"]}
              rules={[Rules.required]}
            >
              <Input />
            </Form.Item>
          </th>
        </tr>
        <tr style={cellStyle}>
          <th style={cellStyle}>Elek İsmi</th>
          <th style={cellStyle}>Ref (%)</th>
          <th style={cellStyle}>Ref (gr)</th>
          <th style={cellStyle}>Sonuç (%)</th>
          <th style={cellStyle}>Sonuç (gr)</th>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>1. Elek</td>
          <td style={cellStyle}>{"<%10"}</td>
          <td style={cellStyle}>{mathRound(sample * 0.1)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (firstSieve / sample) * 100 <= 10 ? "#92d050" : "#fc0404",
            }}
          >
            %{sample > 0 ? mathRound((firstSieve / sample) * 100) : 0}
          </td>
          <td>
            <Form.Item
              name={[props.name, "firstSieve"]}
              rules={[Rules.required]}
            >
              <InputNumber
                onChange={(value) => setFirstSieve(value)}
                min={0}
                decimalSeparator=","
              />
            </Form.Item>
          </td>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>2. Elek</td>
          <td style={cellStyle}>{"<%20"}</td>
          <td style={cellStyle}>{mathRound(sample * 0.2)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (secondSieve / sample) * 100 <= 20 ? "#92d050" : "#fc0404",
            }}
          >
            %{sample > 0 ? mathRound((secondSieve / sample) * 100) : 0}
          </td>
          <td>
            <Form.Item
              name={[props.name, "secondSieve"]}
              rules={[Rules.required]}
            >
              <InputNumber
                onChange={(value) => setSecondSieve(value)}
                min={0}
                decimalSeparator=","
              />
            </Form.Item>
          </td>
        </tr>
        <tr style={cellStyle}>
          <td style={cellStyle}>3. Elek</td>
          <td style={cellStyle}>{">%50"}</td>
          <td style={cellStyle}>{mathRound(sample * 0.5)}</td>
          <td
            style={{
              ...cellStyle,
              backgroundColor:
                (thirdSieve / sample) * 100 > 50 ? "#92d050" : "#fc0404",
            }}
          >
            %{sample > 0 ? mathRound((thirdSieve / sample) * 100) : 0}
          </td>
          <td>
            <Form.Item
              name={[props.name, "thirdSieve"]}
              rules={[Rules.required]}
            >
              <InputNumber
                onChange={(value) => setThirdSieve(value)}
                min={0}
                decimalSeparator=","
              />
            </Form.Item>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default GaitaAnalyseTable;
