import { businessTaskCrudApi } from "../client/EntityApi";
import CrudTable from "../core/component/crud/CrudTable";
import {
  getBusinessTasksCPColumns,
  getBusinessTasksCPAddFormItems,
  getBusinessTasksCPEditFormItems,
} from "../components/crud/BusinessTasksCPComponents";
import { useEffect, useState } from "react";
import { otherApi, userCrudApi } from "../core/client/BaseEntityApi";
import { Typography } from "antd";
import { AuthHelper } from "../core/helper/AuthHelper";

const BusinessTasksCrudPage = () => {
  const { Paragraph, Title, Link } = Typography;

  const [users, setUsers] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [priorties, setPriorties] = useState([]);

  useEffect(() => {
    userCrudApi.getAll().then((response) => {
      const mappedUsers = response["hydra:member"]
        .filter((user: any) => !user.isPassive)
        .map((user: any) => {
          const fullName = user.name + " " + user.surname;
          return {
            text: fullName,
            label: fullName,
            value: user["@id"],
          };
        });

      setUsers(mappedUsers);
    });

    otherApi.constants().then((response) => {
      setPriorties(
        response.priorty.map((priortyItem: any) => {
          return {
            text: priortyItem.key,
            label: priortyItem.label,
            value: priortyItem.value,
          };
        })
      );
      setTaskStatus(
        response.businessTaskStatus.map((businessTaskState: any) => {
          return {
            text: businessTaskState.key,
            label: businessTaskState.label,
            value: businessTaskState.value,
          };
        })
      );
    });
  }, []);

  return (
    <CrudTable
      entityLabel="İdari Görev"
      api={businessTaskCrudApi}
      searchKeys={["name"]}
      columns={getBusinessTasksCPColumns(users, taskStatus, priorties)}
      beforeEditOperation={(values) => {
        values.media = values.media ?? null;
        return values;
      }}
      hideEditProcess={(row) => {
        if (AuthHelper.checkRole("ROLE_ADMIN")) return false;
        return (
          !AuthHelper.isResourceOwn(row.assignedWorker.id) ||
          row.state === "done"
        );
      }}
      hideDeleteProcess={(row) => row.state === "done"}
      hideAddProcess={!AuthHelper.checkVisibility("İdari Görev Ekle")}
      addFormItems={getBusinessTasksCPAddFormItems(users, priorties)}
      editFormItems={getBusinessTasksCPEditFormItems(
        users,
        priorties,
        taskStatus
      )}
      setEditFields={(row) => {
        return {
          ...row,
          assignedWorker: row.assignedWorker["@id"],
        };
      }}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Typography>
              <Title level={5}>Görev Tanımı</Title>
              <Paragraph> {record.definition}</Paragraph>
              <Title level={5}>Görev Açıklaması</Title>
              <Paragraph> {record.description}</Paragraph>
              {record.media && (
                <>
                  <Title level={5}>Medya</Title>
                  <Paragraph>
                    <Link
                      target="_blank"
                      href={process.env.REACT_APP_BASE_URL + record.media.uid}
                    >
                      {record.media.name}
                    </Link>
                  </Paragraph>
                </>
              )}
            </Typography>
          </>
        ),
      }}
    />
  );
};

export default BusinessTasksCrudPage;
