import { EyeOutlined, EnvironmentOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import { Key, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  animalExtendedApi,
  companyCrudApi,
  companyExtendedApi,
} from "../client/EntityApi";
import {
  getCompanyCrudPageFormItems,
  getCompanyCrudPageColumns,
} from "../components/crud/CompanyCrudPageComponents";
import HerdReport from "../components/HerdReport";
import { otherApi } from "../core/client/BaseEntityApi";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { Rules } from "../core/Rules";
import CowIcon from "../Icons/CowIcon";
import { MessageOutlined } from "@ant-design/icons";
import smsApi from "../core/client/SmsApi";
import { AuthHelper } from "../core/helper/AuthHelper";
import CounterIcon from "../Icons/CounterIcon";
import ConsumptionReport from "../components/ConsumptionReport";
import DatePick from "../core/component/DatePick";
import { unique } from "../core/helper/UtilHelper";

const CompanyCrudPage = () => {
  const navigate = useNavigate();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [smsForm] = Form.useForm();
  const [vaccineRecordForm] = Form.useForm();
  const { TextArea } = Input;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>();
  const [selectedRows, setSelectedRows] = useState<any[]>();
  const [smsDialogIsOpen, setSmsDialogIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [companyTypes, setCompanyTypes] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [portfolios, setPortfolios] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [herdReportModalIsOpen, setHerdReportModalIsOpen] = useState(false);
  const [consumptionReportModalIsOpen, setConsumptionReportModalIsOpen] =
    useState(false);
  const [companiesHerdReport, setCompaniesHerdReport] = useState<any>([]);
  const [consumptionReport, setConsumptionReport] = useState<any>([]);
  const [
    companyIndividualVaccineReportModalIsOpen,
    setCompanyIndividualVaccineReportModalIsOpen,
  ] = useState(false);
  const [companyIndividualVaccineReport, setCompanyIndividualVaccineReport] =
    useState<any>([]);
  const [
    animalVaccineRecordReportModalIsOpen,
    setAnimalVaccineRecordReportModalIsOpen,
  ] = useState(false);
  const [animalVaccineRecordReport, setAnimalVaccineRecordReport] =
    useState<any>([]);
  const [vaccineStates, setVaccineStates] = useState<any[]>([]);

  useEffect(() => {
    otherApi.constants().then((response: any) => {
      setCompanyTypes(response.companyType);
      setVaccineStates(response["vaccineStatus"]);
    });
    companyExtendedApi.getAllCities().then((response) => {
      const mappedCities = response["hydra:member"].map((city: any) => {
        const districts = city.districts.map((district: any) => {
          return { label: district.name, value: district["@id"] };
        });
        return { label: city.name, value: city["@id"], districts: districts };
      });
      setCities(mappedCities);
    });
    companyExtendedApi.getAllPortfolios().then((response) =>
      setPortfolios(
        response["hydra:member"].map((portfolio: any) => {
          return { label: portfolio.name, value: portfolio["@id"] };
        })
      )
    );
  }, []);

  const onCancel = () => {
    smsForm.resetFields();
    setLoading(false);
    setSmsDialogIsOpen(false);
  };

  return (
    <>
      <Row justify="center" style={{ marginBottom: "1em" }} gutter={[8, 8]}>
        <Col>
          <Button
            onClick={() => setHerdReportModalIsOpen(true)}
            disabled={companiesHerdReport.length === 0}
          >
            Sürü Raporlarını Göster
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => setConsumptionReportModalIsOpen(true)}
            disabled={consumptionReport.length === 0}
          >
            Sarfiyat Takibi
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => setAnimalVaccineRecordReportModalIsOpen(true)}
            disabled={animalVaccineRecordReport.length === 0}
          >
            Yaklaşan Aşılar
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => setCompanyIndividualVaccineReportModalIsOpen(true)}
            disabled={companyIndividualVaccineReport.length === 0}
          >
            Bireysel Aşı Havuzları
          </Button>
        </Col>
      </Row>
      <CrudTable
        ref={crudPageRef}
        entityLabel="İşletme"
        api={companyCrudApi}
        searchKeys={["name"]}
        columns={getCompanyCrudPageColumns()}
        extendedAddFormOnCancel={() => setSelectedCity(undefined)}
        extendedEditFormOnCancel={() => setSelectedCity(undefined)}
        addFormItems={getCompanyCrudPageFormItems(
          companyTypes,
          cities,
          portfolios,
          selectedCity,
          setSelectedCity,
          crudPageRef.current?.addForm
        )}
        editFormItems={getCompanyCrudPageFormItems(
          companyTypes,
          cities,
          portfolios,
          selectedCity,
          setSelectedCity,
          crudPageRef.current?.editForm
        )}
        setEditFields={(row) => {
          setSelectedCity(
            cities.find((city) => city.value === row.city["@id"])
          );
          return {
            ...row,
            city: row.city["@id"],
            district: row.district["@id"],
            portfolio: row.portfolio["@id"],
          };
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys: any, selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          },
        }}
        extraRowProcess={(row) => (
          <>
            <CrudPageProcessButton
              tooltipText="Konum"
              icon={<EnvironmentOutlined />}
              onClick={() => window.open(row.locationLink)}
            />
            <CrudPageProcessButton
              tooltipText="İşletme Detayı"
              icon={<EyeOutlined />}
              onClick={() => navigate("/isletme-detay/" + row.id)}
            />
            <CrudPageProcessButton
              tooltipText="İşletme Sürüsü"
              icon={<CowIcon />}
              onClick={() => navigate("/isletme/" + row.id + "/suru")}
            />
            {AuthHelper.checkVisibility("İşletme Sarfiyat Sayfası") && (
              <CrudPageProcessButton
                tooltipText="Sarfiyat Takibi"
                icon={<CounterIcon />}
                onClick={() => navigate("/isletme/" + row.id + "/sarfiyat")}
              />
            )}
          </>
        )}
        extraTitleProcess={() => (
          <>
            {AuthHelper.checkVisibility("Sms Gönder") &&
              selectedRowKeys &&
              selectedRowKeys?.length > 0 && (
                <CrudPageProcessButton
                  tooltipText="Sms Gönder"
                  icon={<MessageOutlined />}
                  onClick={() => setSmsDialogIsOpen(true)}
                />
              )}
          </>
        )}
        afterRefreshOperation={() => {
          companyExtendedApi
            .getCompaniesHerdReport()
            .then((response) => setCompaniesHerdReport(response));
          companyExtendedApi
            .getCompaniesConsumptionReport()
            .then((response) => setConsumptionReport(response));
          companyExtendedApi
            .getCompanyIndividualVaccineReport()
            .then((response) => setCompanyIndividualVaccineReport(response));
          companyExtendedApi
            .getCompanyAnimalVaccineRecordReport()
            .then((response) => setAnimalVaccineRecordReport(response));
        }}
      />
      <Modal
        title="İşletme Sürü Raporları"
        visible={herdReportModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => setHerdReportModalIsOpen(false)}
      >
        <HerdReport report={companiesHerdReport} />
      </Modal>
      <Modal
        title="İşletme Sarfiyat Raporları"
        visible={consumptionReportModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => setConsumptionReportModalIsOpen(false)}
      >
        <ConsumptionReport report={consumptionReport} />
      </Modal>
      <Modal
        title="Bireysel Aşı Havuzları"
        visible={companyIndividualVaccineReportModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => setCompanyIndividualVaccineReportModalIsOpen(false)}
      >
        <HerdReport report={companyIndividualVaccineReport} />
      </Modal>
      <Modal
        title="Yaklaşan Aşılar"
        visible={animalVaccineRecordReportModalIsOpen}
        cancelText="Kapat"
        okText="Aşıları Güncelle"
        onCancel={() => {
          vaccineRecordForm.resetFields();
          setAnimalVaccineRecordReportModalIsOpen(false);
          setLoading(false);
        }}
        confirmLoading={loading}
        onOk={() => {
          vaccineRecordForm.validateFields().then((values) => {
            setLoading(true);
            animalExtendedApi.batchVaccineRecordUpdate(values).finally(() => {
              vaccineRecordForm.resetFields();
              setAnimalVaccineRecordReportModalIsOpen(false);
              setLoading(false);
              setAnimalVaccineRecordReport([]);
              crudPageRef.current?.refreshData();
            });
          });
        }}
      >
        <Form form={vaccineRecordForm}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item label="Durum" name="state" rules={[Rules.required]}>
                <Select style={{ width: "100%" }} options={vaccineStates} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tarih" name="date" rules={[Rules.required]}>
                <DatePick />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Form.Item name="animalVaccineRecords">
            <Checkbox.Group>
              {Object.keys(animalVaccineRecordReport).map(
                (company: string, index) => {
                  return (
                    <div key={index} style={{ marginBottom: "1em" }}>
                      <h4 style={{ color: "orange" }}>
                        <Row gutter={[8, 8]} align="middle">
                          <Col>{company} </Col>
                          <Col>
                            <Button
                              onClick={() => {
                                const ids = animalVaccineRecordReport[
                                  company
                                ].map((item: any) => item.value);
                                const animalVaccineRecords =
                                  vaccineRecordForm.getFieldValue(
                                    "animalVaccineRecords"
                                  ) ?? [];
                                vaccineRecordForm.setFieldValue(
                                  "animalVaccineRecords",
                                  unique([...ids, ...animalVaccineRecords])
                                );
                              }}
                            >
                              Tümünü Seç
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              onClick={() => {
                                const ids = animalVaccineRecordReport[
                                  company
                                ].map((item: any) => item.value);
                                const animalVaccineRecords =
                                  vaccineRecordForm.getFieldValue(
                                    "animalVaccineRecords"
                                  ) ?? [];

                                vaccineRecordForm.setFieldValue(
                                  "animalVaccineRecords",
                                  unique(
                                    animalVaccineRecords.filter(
                                      (n: any) => !ids.includes(n)
                                    )
                                  )
                                );
                              }}
                            >
                              Tümünü Kaldır
                            </Button>
                          </Col>
                        </Row>
                      </h4>
                      {animalVaccineRecordReport[company].map(
                        (item: any, index: number) => (
                          <Row key={index}>
                            <Checkbox value={item["value"]}>
                              {item["label"]}
                            </Checkbox>
                          </Row>
                        )
                      )}
                    </div>
                  );
                }
              )}
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="İşletmelere SMS Gönder"
        visible={smsDialogIsOpen}
        okText="Seçili İşletmelere Gönder"
        cancelText="Vazgeç"
        onOk={() => {
          setLoading(true);
          smsForm.validateFields().then((values) => {
            const phones = selectedRows?.map((row) => row.managerPhone);
            if (phones && phones.length > 0)
              smsApi.sendSms(values.message, phones).finally(() => onCancel());
          });
        }}
        onCancel={onCancel}
        confirmLoading={loading}
      >
        <Form form={smsForm}>
          <Form.Item name="message" rules={[Rules.required]}>
            <TextArea
              placeholder="Gönderilecek olan sms içeriğini giriniz."
              rows={4}
            />
          </Form.Item>
          {selectedRows?.map((row: any, index: number) => {
            return (
              <Tag key={index} style={{ margin: "0.25em" }} color="geekblue">
                {row.name}
              </Tag>
            );
          })}
        </Form>
      </Modal>
    </>
  );
};

export default CompanyCrudPage;
