import Icon from "@ant-design/icons";
const SyringeCancelIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "1em",
          height: "1em",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        viewBox="0 0 512 512"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="6330"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M25 5095 c-53 -52 -54 -50 324 -430 l349 -350 -55 -60 c-76 -83 -188
-232 -258 -345 -528 -853 -510 -1944 47 -2772 496 -738 1335 -1168 2218 -1135
328 13 572 68 942 213 l48 18 118 -117 c97 -95 124 -117 148 -117 25 0 111 82
621 593 511 510 593 596 593 621 0 24 -22 51 -117 148 l-117 118 18 48 c145
370 200 614 213 942 33 892 -403 1736 -1155 2232 -690 454 -1566 543 -2347
237 -260 -101 -558 -285 -750 -462 l-60 -55 -350 349 c-380 378 -378 377 -430
324z m2750 -135 c566 -52 1080 -294 1485 -700 278 -277 462 -574 585 -945 180
-540 163 -1126 -49 -1652 -74 -184 -107 -241 -190 -323 l-76 -75 -72 73 -73
72 33 57 c19 32 57 108 87 168 344 710 259 1571 -218 2212 -77 104 -99 119
-146 102 -35 -13 -2949 -2925 -2966 -2965 -8 -17 -11 -41 -8 -54 12 -45 344
-270 400 -270 34 0 73 36 73 67 0 40 -17 60 -77 94 -72 40 -203 129 -203 138
0 3 222 228 493 499 l492 492 250 -250 249 -249 -92 -94 c-62 -64 -92 -102
-92 -117 0 -17 41 -64 137 -162 180 -181 163 -181 332 -13 l127 125 173 -174
174 -174 -150 -75 c-83 -41 -201 -91 -263 -111 -392 -127 -815 -133 -1209 -16
-110 33 -139 30 -160 -17 -29 -62 4 -93 139 -133 354 -104 770 -111 1131 -20
174 44 394 135 562 232 l57 33 72 -73 73 -72 -75 -76 c-82 -83 -139 -116 -323
-190 -520 -209 -1109 -229 -1637 -54 -380 125 -681 310 -960 590 -628 627
-859 1519 -614 2369 96 331 255 625 487 899 l70 83 90 -89 89 -89 -88 -109
c-352 -435 -519 -956 -483 -1506 28 -416 173 -810 422 -1145 80 -108 102 -121
152 -98 16 8 549 534 1183 1168 l1153 1154 -3 38 c-4 49 -42 81 -85 71 -18 -5
-102 -81 -243 -221 l-215 -214 -400 398 c-440 437 -449 444 -559 445 -77 0
-124 -16 -185 -64 l-49 -39 -128 90 c-71 50 -136 94 -144 100 -53 33 373 282
624 364 492 160 1027 125 1493 -100 122 -59 333 -187 333 -203 0 -3 -76 -83
-170 -177 -183 -184 -190 -197 -149 -249 15 -19 29 -26 56 -26 33 0 51 15 234
197 109 108 205 210 213 228 24 50 11 71 -97 152 -335 249 -729 394 -1145 422
-550 36 -1071 -131 -1506 -483 l-109 -88 -89 89 -89 89 82 69 c499 427 1141
634 1784 575z m-1444 -1057 c76 -53 139 -99 139 -103 0 -3 -34 -39 -75 -80
-46 -46 -78 -71 -84 -65 -19 21 -191 274 -191 281 0 11 52 64 62 64 5 0 72
-44 149 -97z m539 -54 c8 -4 44 -37 79 -73 l65 -65 -52 -55 c-62 -66 -70 -110
-26 -145 40 -32 60 -26 125 36 l60 57 62 -62 62 -62 -54 -54 c-61 -62 -71
-101 -33 -138 37 -38 76 -28 138 33 l54 54 62 -62 62 -62 -57 -60 c-62 -65
-68 -85 -36 -125 35 -44 79 -36 145 26 l55 52 42 -42 42 -42 -302 -302 -303
-303 -203 203 c-173 172 -208 202 -233 202 -62 0 -98 -55 -70 -108 8 -15 100
-112 205 -217 l191 -190 -492 -492 c-271 -271 -496 -493 -500 -493 -16 0 -144
210 -203 333 -266 553 -266 1186 1 1737 81 167 240 408 257 390 2 -3 47 -67
100 -143 l96 -139 -39 -49 c-48 -61 -64 -108 -64 -185 1 -87 31 -141 139 -251
71 -72 98 -93 119 -93 39 0 61 15 75 49 17 40 1 68 -93 161 -82 81 -101 118
-85 173 12 43 471 504 514 516 30 9 67 5 95 -10z m2363 -186 c337 -511 421
-1147 231 -1731 -36 -113 -162 -383 -184 -397 -8 -5 -72 52 -181 161 l-169
168 125 127 c168 169 168 152 -13 332 -98 96 -145 137 -162 137 -15 0 -53 -30
-117 -92 l-94 -92 -249 249 -250 250 492 492 c271 271 496 493 500 493 4 0 36
-44 71 -97z m-920 -1251 l247 -247 -303 -303 -302 -302 -250 250 -250 250 300
300 c165 165 302 300 305 300 3 0 116 -111 253 -248z m630 -200 l57 -57 -518
-518 -517 -517 -60 60 -60 60 515 515 c283 283 517 515 520 515 3 0 31 -26 63
-58z m392 -1142 l-90 -90 -300 300 -300 300 90 90 90 90 300 -300 300 -300
-90 -90z m-492 102 l297 -297 -90 -90 -90 -90 -300 300 -300 300 87 87 c48 49
90 88 93 88 3 0 139 -134 303 -298z m580 -474 l-518 -518 -62 62 -63 63 517
517 518 518 62 -62 63 -63 -517 -517z"
          />
        </g>
      </svg>

      //   <svg
      //     style={{
      //       width: "1em",
      //       height: "1em",
      //       verticalAlign: "middle",
      //       fill: "currentcolor",
      //       overflow: "hidden",
      //     }}
      //     viewBox="0 0 512 512"
      //     version="1.1"
      //     xmlns="http://www.w3.org/2000/svg"
      //     p-id="6330"
      //   >
      //     <g
      //       transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      //       fill="#000000"
      //       stroke="none"
      //     >
      //       <path
      //         d="M4580 4789 c-14 -5 -259 -244 -545 -529 l-520 -520 -120 121 c-66 66
      //  -132 127 -147 135 -45 24 -108 18 -159 -15 -24 -16 -116 -101 -204 -190 l-160
      //  -161 -62 65 c-88 92 -142 112 -221 80 -26 -11 -260 -238 -777 -755 l-740 -740
      //  -65 66 c-83 84 -109 97 -177 92 -42 -4 -60 -11 -84 -34 -60 -55 -74 -126 -40
      //  -192 11 -20 123 -140 250 -267 l231 -230 -107 -107 c-59 -60 -112 -108 -118
      //  -108 -6 0 -64 54 -130 120 -132 133 -162 150 -234 130 -97 -26 -146 -122 -110
      //  -217 13 -37 1143 -1169 1191 -1194 125 -64 264 48 227 183 -8 33 -36 68 -137
      //  170 l-126 129 109 109 110 110 235 -234 c129 -129 248 -240 263 -245 127 -48
      //  251 62 217 192 -10 37 -82 113 -641 672 l-629 630 168 168 167 167 175 -176
      //  c96 -96 188 -182 204 -191 66 -35 159 -9 203 57 27 39 31 116 9 158 -8 15 -94
      //  106 -190 202 l-176 175 112 112 113 113 185 -184 c199 -198 213 -207 297 -191
      //  49 9 109 69 118 118 16 84 7 98 -191 297 l-184 186 168 167 167 167 368 -368
      //  367 -367 -70 -34 c-150 -73 -280 -205 -361 -366 -58 -117 -90 -249 -88 -363
      //  l2 -73 -230 -232 c-202 -202 -233 -238 -243 -277 -38 -143 115 -253 241 -171
      //  24 16 111 96 193 179 l149 151 40 -57 c149 -217 386 -343 642 -343 496 0 883
      //  474 790 966 -58 308 -289 562 -584 644 -39 11 -76 23 -82 27 -6 4 59 77 164
      //  183 96 97 182 190 191 208 20 40 21 105 2 141 -8 15 -69 81 -135 147 l-121
      //  120 524 525 c567 567 548 545 532 635 -17 91 -127 149 -216 114z m-1175 -1389
      //  l220 -220 -113 -112 -112 -113 -222 222 -223 223 110 110 c60 60 112 110 115
      //  110 3 0 104 -99 225 -220z m220 -1015 c192 -50 338 -210 368 -404 36 -233
      //  -121 -464 -358 -526 -268 -69 -545 117 -588 395 -51 328 261 617 578 535z
      //  m-2242 -1227 c-59 -60 -112 -108 -118 -108 -13 0 -215 202 -215 215 0 5 48 58
      //  107 117 l108 108 112 -112 113 -113 -107 -107z"
      //       />
      //       <path
      //         d="M3453 2200 c-44 -19 -79 -64 -90 -112 -4 -19 -13 -29 -32 -33 -81
      //  -17 -137 -94 -128 -173 3 -24 14 -56 24 -71 27 -35 81 -71 108 -71 18 0 24 -7
      //  29 -35 13 -69 83 -125 156 -125 73 0 143 56 156 125 5 28 11 35 29 35 27 0 81
      //  36 108 71 63 85 5 220 -104 244 -19 4 -28 14 -32 33 -15 70 -89 132 -157 132
      //  -14 0 -44 -9 -67 -20z"
      //       />
      //     </g>
      //   </svg>
    )}
    {...props}
  />
);

export default SyringeCancelIcon;
