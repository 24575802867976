import { Drawer, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import NotificationCard from "./NotificationCard";
import { LoadingOutlined, BellOutlined } from "@ant-design/icons";
import HeaderBadge from "../../core/component/HeaderBadge";
import { notificationCrudExtendedApi } from "../../client/EntityApi";

interface IAllNotificationsProps {
  isSmallScreen?: boolean;
}

const AllNotifications = (props: IAllNotificationsProps) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
    // const notificationInterval = setInterval(getNotifications, 60000);
    // return () => clearInterval(notificationInterval);
  }, []);

  const getNotifications = () => {
    setNotificationLoading(true);
    notificationCrudExtendedApi
      .getAllTodayNotifications()
      .then((response) => setNotifications(response["hydra:member"]))
      .finally(() => setNotificationLoading(false));
  };

  return (
    <>
      <HeaderBadge
        tooltipText="Tüm Bildirimler"
        avatarIcon={<BellOutlined />}
        badgeCount={notifications.length}
        onClick={() => setDrawerIsOpen(true)}
        isSmallScreen={props.isSmallScreen}
      />
      <Drawer
        title={<>Tüm Bildirimler</>}
        placement="right"
        visible={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        width={props.isSmallScreen ? "300px" : "500px"}
      >
        {notificationLoading ? (
          <Row>
            <Col span={24}>
              <Row justify="center" align="middle" style={{ margin: "5em" }}>
                <LoadingOutlined spin />
              </Row>
            </Col>
          </Row>
        ) : (
          notifications.map((notification, index) => {
            return (
              <NotificationCard
                key={index}
                notification={notification}
                hideActions
                showUser
              />
            );
          })
        )}
      </Drawer>
    </>
  );
};

export default AllNotifications;
