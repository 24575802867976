import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { petExtendedApi, petOwnerCrudApi } from "../client/EntityApi";
import {
  getPetOwnerCPColumns,
  getPetOwnerCPFormItems,
} from "../components/crud/PetOwnerCPComponents";
import PetVaccineReport from "../components/PetVaccineReport";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable from "../core/component/crud/CrudTable";
import LargeTag from "../core/component/LargeTag";
import { Rules } from "../core/Rules";
import PetIcon from "../Icons/PetIcon";
import { MessageOutlined } from "@ant-design/icons";
import smsApi from "../core/client/SmsApi";

const PetOwnerCrudPage = () => {
  const { TextArea } = Input;

  const navigate = useNavigate();
  const [smsForm] = Form.useForm();

  const [petVaccineReportModalIsOpen, setPetVaccineReportModalIsOpen] =
    useState<boolean>(false);
  const [petVaccineReport, setPetVaccineReport] = useState<any>([]);
  const [petCountReport, setPetCountReport] = useState<any>();

  const [smsActionLoading, setSmsActionLoading] = useState<boolean>(false);
  const [smsDialogIsOpen, setSmsDialogIsOpen] = useState<boolean>(false);
  const [petOwnerOptions, setPetOwnerOptions] = useState<any>([]);

  const smsModalOnCancel = () => {
    smsForm.resetFields();
    setSmsActionLoading(false);
    setSmsDialogIsOpen(false);
  };

  return (
    <>
      <Row justify="center" style={{ marginBottom: "1em" }} gutter={[8, 8]}>
        <Col>
          <Button
            onClick={() => setPetVaccineReportModalIsOpen(true)}
            disabled={petVaccineReport.length === 0}
          >
            Yaklaşan Aşıları Göster
          </Button>
        </Col>
        {petCountReport && (
          <Col>
            <LargeTag size={30} color="green">
              Aktif Pet : {petCountReport.activeCount}
            </LargeTag>
            <LargeTag size={30} color="red">
              Pasif Pet : {petCountReport.passiveCount}
            </LargeTag>
          </Col>
        )}
      </Row>
      <CrudTable
        entityLabel="Pet Sahip"
        api={petOwnerCrudApi}
        searchKeys={["firstname", "lastname", "phone"]}
        columns={getPetOwnerCPColumns()}
        addFormItems={getPetOwnerCPFormItems()}
        editFormItems={getPetOwnerCPFormItems()}
        expandable={{
          expandedRowRender: (record: any) => (
            <>
              <b>Adres : </b> {record.address}
            </>
          ),
        }}
        extraRowProcess={(row) => (
          <>
            <CrudPageProcessButton
              tooltipText="Petler"
              icon={<PetIcon />}
              onClick={() => navigate("/sahip/" + row.id + "/pet")}
            />
          </>
        )}
        extraTitleProcess={() => (
          <CrudPageProcessButton
            tooltipText="Sms Gönder"
            icon={<MessageOutlined />}
            onClick={() => setSmsDialogIsOpen(true)}
          />
        )}
        afterRefreshOperation={(data) => {
          setPetOwnerOptions(
            data.map((item) => {
              return {
                label: item.firstname + " " + item.lastname,
                value: item.phone,
              };
            })
          );
          petExtendedApi
            .getPetVaccineReports()
            .then((response) => setPetVaccineReport(response));
          petExtendedApi
            .getPetCountReport()
            .then((response) => setPetCountReport(response));
        }}
      />
      <Modal
        title="Yaklaşan Aşılar"
        visible={petVaccineReportModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => setPetVaccineReportModalIsOpen(false)}
      >
        <PetVaccineReport report={petVaccineReport} />
      </Modal>
      <Modal
        title="Pet Sahiplerine SMS Gönder"
        visible={smsDialogIsOpen}
        okText="Gönder"
        cancelText="Vazgeç"
        confirmLoading={smsActionLoading}
        onOk={() => {
          smsForm.validateFields().then((values) => {
            setSmsActionLoading(true);
            smsApi
              .sendSms(values.message, values.phones)
              .finally(() => smsModalOnCancel());
          });
        }}
        onCancel={smsModalOnCancel}
      >
        <Form form={smsForm} layout="vertical">
          <Form.Item
            label="Pet Sahipleri"
            name="phones"
            rules={[Rules.required]}
          >
            <Select mode="multiple" options={petOwnerOptions} />
          </Form.Item>
          <Form.Item label="Mesaj" name="message" rules={[Rules.required]}>
            <TextArea
              placeholder="Gönderilecek olan sms içeriğini giriniz."
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PetOwnerCrudPage;
