import { useEffect, useRef, useState } from "react";
import { companyExtendedApi, taskCategoryCrudApi } from "../client/EntityApi";
import {
  getCPeriodicTasksCPColumns,
  getCPeriodicTasksCPCustomTableTitle,
} from "../components/crud/CPeriodicTasksCPComponents";
import PeriodSelect from "../components/PeriodSelect";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import { EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { userCrudApi } from "../core/client/BaseEntityApi";
import { Form, Modal, Select } from "antd";
import { Rules } from "../core/Rules";

const CPeriodicTasksCrudPage = () => {
  const [assignTaskForm] = Form.useForm();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [companyReportLoading, setCompanyReportLoading] = useState(true);
  const [selectedPeriodId, setSelectedPeriodId] = useState<number>();
  const [companyReport, setCompanyReport] = useState();
  const [assignTaskLoading, setAssignTaskLoading] = useState(false);
  const [assignTaskDialogIsOpen, setAssignTaskDialogIsOpen] = useState(false);
  const [mappedUsers, setMappedUsers] = useState<any[]>([]);
  const [selectedTaskCategoryId, setSelectedTaskCategoryId] =
    useState<number>();

  useEffect(() => {
    userCrudApi.getAll().then((response) => {
      const mappedUsers = response["hydra:member"]
        .filter((user: any) => !user.isPassive)
        .map((user: any) => {
          const fullName = user.name + " " + user.surname;
          return {
            label: fullName,
            value: user["@id"],
          };
        });
      setMappedUsers(mappedUsers);
    });
  }, []);

  useEffect(() => {
    crudPageRef.current?.refreshData();
  }, [selectedPeriodId]);

  const getAll = () => {
    return selectedPeriodId
      ? companyExtendedApi.getTaskCategoriesWithPeriod(selectedPeriodId)
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  const onCancel = () => {
    assignTaskForm.resetFields();
    setSelectedTaskCategoryId(undefined);
    setAssignTaskDialogIsOpen(false);
    setAssignTaskLoading(false);
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        api={{}}
        getAll={getAll}
        columns={getCPeriodicTasksCPColumns(
          setAssignTaskDialogIsOpen,
          setSelectedTaskCategoryId,
          assignTaskForm
        )}
        searchKeys={["name"]}
        customTableTitle={() =>
          companyReportLoading ? (
            <SyncOutlined spin />
          ) : (
            getCPeriodicTasksCPCustomTableTitle(companyReport)
          )
        }
        hideAddProcess
        hideDefaultRowProceses
        extraRowProcess={(row) => (
          <CrudPageProcessButton
            tooltipText="Detaya Git"
            icon={<EyeOutlined />}
            onClick={() => window.open("/isletme-detay/" + row.id)}
          />
        )}
        extraTitleProcess={() => (
          <PeriodSelect
            style={{ width: "50%", marginRight: "1em" }}
            selectedPeriodId={selectedPeriodId}
            setSelectedPeriodId={setSelectedPeriodId}
          />
        )}
        afterRefreshOperation={() => {
          if (selectedPeriodId) {
            setCompanyReportLoading(true);
            companyExtendedApi
              .getCompanyReport(selectedPeriodId)
              .then((response) => setCompanyReport(response))
              .finally(() => setCompanyReportLoading(false));
          }
        }}
      />
      <Modal
        visible={assignTaskDialogIsOpen}
        title="Görevi Atamak İstediğinize Emin Misiniz ?"
        okText="Görevi Ata"
        cancelText="Vazgeç"
        onOk={() => {
          if (selectedTaskCategoryId && selectedPeriodId) {
            assignTaskForm.validateFields().then((values) => {
              setAssignTaskLoading(true);
              taskCategoryCrudApi
                .edit(selectedTaskCategoryId, {
                  assignedWorker:
                    values.worker === "none" ? null : values.worker,
                })
                .then(() => crudPageRef.current?.refreshData())
                .finally(() => onCancel());
            });
          }
        }}
        onCancel={onCancel}
        confirmLoading={assignTaskLoading}
      >
        <Form form={assignTaskForm} autoComplete="off" layout="vertical">
          <Form.Item name="worker" label="Personel" rules={[Rules.required]}>
            <Select
              size="large"
              options={[{ label: "Kimse", value: "none" }, ...mappedUsers]}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CPeriodicTasksCrudPage;
