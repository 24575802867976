import { Tag } from "antd";

interface IYesNoTagProps {
  success?: boolean;
  successText?: string;
  failText?: string;
  successColor?: string;
  failColor?: string;
}

const YesNoTag = (props: IYesNoTagProps) => {
  return (
    <Tag
      color={
        props.success === true
          ? props.successColor ?? "green"
          : props.failColor ?? "red"
      }
    >
      {/* {(props.success === true) === true ? "Evet" : "Hayır"} */}
      {(props.success === true) === true
        ? props.successText ?? "Evet"
        : props.failText ?? "Hayır"}
    </Tag>
  );
};

export default YesNoTag;
