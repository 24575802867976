import Icon from "@ant-design/icons";

const PawPrintIcon = (props: any) => (
  <Icon
    component={() => (
      <svg
        style={{
          width: "20px",
          height: "20px",
          verticalAlign: "middle",
          fill: "currentcolor",
          overflow: "hidden",
        }}
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="64.000000pt"
        height="64.000000pt"
        viewBox="0 0 64.000000 64.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M214 590 c-31 -12 -54 -55 -54 -99 0 -92 98 -137 154 -70 69 81 -6
      207 -100 169z m63 -38 c36 -23 34 -94 -3 -118 -45 -30 -95 27 -73 83 15 40 46
      54 76 35z"
          />
          <path
            d="M414 540 c-33 -13 -54 -50 -54 -95 0 -94 112 -119 155 -35 27 52 13
      109 -32 129 -28 13 -40 13 -69 1z m64 -42 c27 -27 -1 -108 -38 -108 -13 0 -50
      37 -50 51 0 15 21 53 34 61 17 11 41 9 54 -4z"
          />
          <path
            d="M53 470 c-84 -34 -53 -170 38 -170 57 0 89 73 59 131 -9 15 -61 51
      -72 48 -2 0 -13 -4 -25 -9z m43 -38 c19 -12 34 -50 28 -67 -12 -30 -42 -37
      -64 -15 -41 41 -10 113 36 82z"
          />
          <path
            d="M503 356 c-37 -32 -39 -99 -4 -127 79 -64 188 63 115 135 -23 24 -78
      20 -111 -8z m85 -18 c26 -26 0 -88 -38 -88 -40 0 -53 47 -20 80 23 23 41 25
      58 8z"
          />
          <path
            d="M219 347 c-47 -31 -139 -170 -139 -209 0 -21 10 -40 34 -64 36 -36
      52 -40 106 -24 47 15 109 14 158 -1 72 -21 132 20 132 91 0 49 -95 190 -143
      213 -50 24 -107 22 -148 -6z m150 -43 c40 -33 101 -135 101 -167 0 -42 -27
      -60 -74 -51 -54 10 -152 10 -199 -1 -44 -9 -77 13 -77 51 0 29 80 156 111 178
      37 25 102 21 138 -10z"
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default PawPrintIcon;
