import { Button, Col, Progress, Row } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  UploadOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import api from "../client/AxiosConfig";

interface IUploadProps {
  accept?: string;
  uploadText?: string;
  iriPrefix?: string;
  buttonStyle?: object;
  multiple?: boolean;
  patchableList?: boolean;
  fileList?: IUploadFile[];
  value?: any;
  onChange?: (data: string | string[]) => void;
}

export interface IUploadFile {
  name: string;
  uid: string;
  iri?: string;
  size?: number;
  status?: "notYet" | "done" | "uploading" | "error";
  percent?: number;
  rawData?: Blob;
}

export interface ChunkFile {
  index: string;
  file: Blob;
  isLast: "1" | "0";
}

// TODO: Bug -> Bir yükleme devam ederken remove yapsan bile o kayıt tekrardan geliyor

const Upload = (props: IUploadProps) => {
  let inputRef = useRef<HTMLInputElement>(null);

  const [fileList, setFileList] = useState<IUploadFile[]>(props.fileList ?? []);

  useEffect(() => {
    if (
      !props.value ||
      typeof props.value === "string" ||
      (Array.isArray(props.value) && props.value.length === 0) ||
      (Array.isArray(props.value) &&
        props.value.length > 0 &&
        typeof props.value[0] === "string")
    )
      return;

    let baseFileList: IUploadFile[] = [];
    if (Array.isArray(props.value)) {
      props.value.forEach((media: any) => {
        baseFileList.push({
          name: media.name,
          uid: media.uid,
          iri: media["@id"],
          status: "done",
          percent: 100,
        });
      });
    } else if (typeof props.value === "object") {
      baseFileList.push({
        name: props.value.name,
        uid: props.value.uid,
        iri: props.value["@id"],
        status: "done",
        percent: 100,
      });
    }

    setFileList(baseFileList);
  }, [props.value]);

  useEffect(() => {
    //TODO: allFilesUploaded özelliği eklenmeli, gerekirse biz bir state olarak tutalım ref ile dışarıdan erişsinler, yada
    //fonksiyon yazalım ona göre ekstra işlem yapılsın dışarıdan, yada custom rule yazılıyor mu bakalım
    if (props.onChange) {
      let iriList: string[] = [];
      fileList.forEach((file) => {
        if (file.iri && file.iri !== "") iriList.push(file.iri);
      });

      props.onChange(props.multiple ? iriList : iriList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const chunkUpload = async (file: IUploadFile, fileList: IUploadFile[]) => {
    const chunkSize = 1000000;
    const chunks = getChunksFromFile(file.rawData!, chunkSize);
    for (let i = 0; i < chunks.length; i++) {
      let formData = new FormData();
      formData.append("file", chunks[i].file);
      formData.append("index", chunks[i].index);
      formData.append("last", chunks[i].isLast);
      formData.append("uuid", file.uid);
      formData.append("orig", file.name);
      await api
        .post("api/upload", formData)
        .then((response) => {
          const percent =
            response.status === 201
              ? 100
              : Math.floor(
                  (chunkSize * 100 * (parseInt(chunks[i].index) + 1)) /
                    file.rawData!.size
                );
          setFileList(
            updatePercent(file.uid, percent, fileList, response.data.id)
          );
        })
        .catch(() => {
          setFileList(updatePercent(file.uid, -1, fileList));
        });
    }
  };

  const updatePercent = (
    uid: String,
    percent: number,
    fileList: IUploadFile[],
    id?: string
  ) => {
    return fileList.map((item) => {
      if (item.uid === uid) {
        item.percent = percent;
        item.status =
          percent === 100 ? "done" : percent === -1 ? "error" : "uploading";
        if (id) {
          item.iri = props.iriPrefix ? props.iriPrefix + id : "api/media/" + id;
        }
      }

      return item;
    });
  };

  const getChunksFromFile = (file: Blob, chunkSize: number) => {
    let chunks = [] as ChunkFile[];
    if (file.size < chunkSize) {
      chunks.push({
        index: "0",
        file: file.slice(0, file.size),
        isLast: "1",
      } as ChunkFile);

      return chunks;
    }

    const fullChunkCount = Math.ceil(file.size / chunkSize);
    const remainderSize = file.size % chunkSize;

    let index = 0;
    for (; index < fullChunkCount; index++) {
      const isLastPart = index === fullChunkCount - 1;
      const startPoint = index * chunkSize;
      const endPoint = startPoint + (isLastPart ? remainderSize : chunkSize);
      const filePart = file.slice(startPoint, endPoint);
      chunks.push({
        index: index.toString(),
        file: filePart,
        isLast: isLastPart ? "1" : "0",
      } as ChunkFile);
    }

    return chunks;
  };

  return (
    <>
      <Button
        style={props.buttonStyle ?? { minWidth: "100%" }}
        icon={<UploadOutlined />}
        onClick={() => inputRef?.current?.click()}
      >
        {props.uploadText ?? "Dosya Seç"}
      </Button>
      <input
        accept={props.accept}
        multiple={props.multiple}
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        onChange={(e) => {
          if (e.target.files) {
            const files = Array.from(e.target.files).map((file) => {
              return {
                name: file.name,
                uid: uuidv4(),
                iri: "",
                size: file.size,
                status: "notYet",
                percent: 0,
                rawData: file,
              } as IUploadFile;
            });
            const toBeUploadFileList = props.patchableList
              ? [...fileList, ...files]
              : [...files];
            setFileList(toBeUploadFileList);
            toBeUploadFileList.forEach((toBeUploadFile) => {
              if (toBeUploadFile.status === "notYet") {
                chunkUpload(toBeUploadFile, toBeUploadFileList);
              }
            });
          }
        }}
      />
      {fileList.map((file: any, index: number) => {
        return (
          <Row
            key={index}
            style={{
              border: "1px solid #d9d9d9",
              padding: "1em",
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
            align="middle"
          >
            <Col span={3}>
              <FileImageOutlined
                style={{ fontSize: "26px", color: "#1890ff" }}
              />
            </Col>
            <Col span={7}>{file.name}</Col>
            <Col offset={12} span={2}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
                onClick={() =>
                  setFileList(fileList.filter((f) => f.uid !== file.uid))
                }
              />
            </Col>
            <Progress percent={file.percent} size="small" />
          </Row>
        );
      })}
    </>
  );
};

export default Upload;
