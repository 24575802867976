import api from "./AxiosConfig";
import { getCrudApi } from "./CrudApi";

export const userCrudApi = getCrudApi("workers");

export const authApi = {
  login: (username: string, password: string) => {
    return api
      .post("api/login_check", { username: username, password: password })
      .then((response: any) => {
        return response.data;
      });
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("api/refresh-token", { refreshToken: refreshToken })
      .then((response: any) => {
        return response.data;
      });
  },
};

export const otherApi = {
  constants: () => {
    return api.get("api/constants").then((response: any) => {
      return response.data;
    });
  },
};
