import { Col, Row } from "antd";

interface IConsumptionReportProps {
  report: any;
}

const ConsumptionReport = (props: IConsumptionReportProps) => {
  return (
    <>
      {Object.keys(props.report).map((company: string, index) => {
        return (
          <div key={index} style={{ marginBottom: "1em" }}>
            <h4 style={{ color: "orange" }}>{company}</h4>
            <Row style={{ borderBottom: "1px solid #ccc" }}>
              <Col span={10} style={{ textAlign: "center" }}>
                Ürün
              </Col>
              <Col span={7} style={{ textAlign: "center" }}>
                Kalan Miktar
              </Col>
              <Col span={7} style={{ textAlign: "center" }}>
                Kalan Gün Sayısı
              </Col>
            </Row>
            {props.report[company].map((consumption: any, index: number) => (
              <Row key={index}>
                <Col span={10} style={{ textAlign: "center" }}>
                  {consumption.product}
                </Col>
                <Col span={7} style={{ textAlign: "center" }}>
                  {consumption.remainderQuantity}
                </Col>
                <Col span={7} style={{ textAlign: "center" }}>
                  {consumption.remainderDay}
                </Col>
              </Row>
            ))}
          </div>
        );
      })}
    </>
  );
};

export default ConsumptionReport;
