import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  companyCrudApi,
  companyExtendedApi,
  serviceCrudApi,
  taskCrudApi,
  vaccineCrudApi,
  vaccineExtendedApi,
} from "../client/EntityApi";
import {
  getCompanyDetailCPColumns,
  getCompanyDetailCPExpandedRowRender,
  getCompanyDetailCPFormItems,
} from "../components/crud/CompanyDetailCPComponents";
import PeriodSelect from "../components/PeriodSelect";
import { otherApi } from "../core/client/BaseEntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../core/component/crud/CrudTable";
import {
  SyncOutlined,
  MedicineBoxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import CrudPageProcessButton from "../core/component/crud/CrudPageProcessButton";
import { Col, Modal, Row } from "antd";
import VaccineCard from "../components/VaccineCard";
import { AuthHelper } from "../core/helper/AuthHelper";
import { getDateFromString } from "../core/helper/UtilHelper";
import CowIcon from "../Icons/CowIcon";

const CompanyDetail = () => {
  const { id } = useParams();
  const crudPageRef = useRef<ICrudTableRefMethods>(null);

  const [company, setCompany] = useState<any>();
  const [selectedPeriodId, setSelectedPeriodId] = useState<number>();
  const [taskTypes, setTaskTypes] = useState<any[]>([]);
  const [taskCategories, setTaskCategories] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [vaccines, setVaccines] = useState<any[]>([]);
  const [selectedTaskCategoryName, setSelectedTaskCategoryName] = useState("");

  const [vaccineLogsModalIsOpen, setVaccineLogsModalIsOpen] =
    useState<boolean>(false);
  const [vaccineLogs, setVaccineLogs] = useState<any[]>([]);
  const [vaccineLogsLoading, setVaccineLogsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      companyCrudApi.get(parseInt(id)).then((response) => setCompany(response));
    }

    serviceCrudApi.getAll().then((response) =>
      setServices(
        response["hydra:member"].map((service: any) => {
          return {
            label: service.name,
            text: service.name,
            value: service.name,
          };
        })
      )
    );

    vaccineCrudApi.getAll().then((response) =>
      setVaccines(
        response["hydra:member"].map((vaccine: any) => {
          return {
            label: vaccine.name,
            text: vaccine.name,
            value: vaccine.name,
          };
        })
      )
    );

    otherApi.constants().then((response) => setTaskTypes(response.taskType));
  }, [id]);

  useEffect(() => {
    getCompanyTaskCategories();
    crudPageRef.current?.refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriodId]);

  const getCompanyTaskCategories = () => {
    if (company && selectedPeriodId) {
      companyExtendedApi
        .getTaskCategoryWithFilter(company.id, selectedPeriodId)
        .then((response) => {
          setTaskCategories(
            response["hydra:member"].map((taskCategory: any) => {
              return {
                label: taskCategory.service.name,
                key: taskCategory.service.name,
                value: taskCategory["@id"],
              };
            })
          );
        });
    }
  };

  const getAll = () => {
    return selectedPeriodId && company
      ? companyExtendedApi.getCompanyTasksWithPeriod(
          company.id,
          selectedPeriodId
        )
      : new Promise((resolve, reject) => {
          resolve([]);
          reject();
        });
  };

  return (
    <>
      <CrudTable
        ref={crudPageRef}
        entityLabel=""
        api={taskCrudApi}
        hideSearchBar
        getAll={getAll}
        columns={getCompanyDetailCPColumns(services)}
        extendedAddFormOnCancel={() => setSelectedTaskCategoryName("")}
        extendedEditFormOnCancel={() => setSelectedTaskCategoryName("")}
        expandable={{
          expandedRowRender: (record) =>
            getCompanyDetailCPExpandedRowRender(record),
        }}
        addFormItems={getCompanyDetailCPFormItems(
          taskTypes,
          taskCategories,
          vaccines,
          selectedTaskCategoryName,
          setSelectedTaskCategoryName,
          crudPageRef.current?.addForm
        )}
        editFormItems={getCompanyDetailCPFormItems(
          taskTypes,
          taskCategories,
          vaccines,
          selectedTaskCategoryName,
          setSelectedTaskCategoryName,
          crudPageRef.current?.editForm
        )}
        customTableTitle={() => (
          <>
            {company ? (
              company.name + " İçin Yapılan İşler"
            ) : (
              <SyncOutlined spin />
            )}
          </>
        )}
        extraTitleProcess={() => (
          <>
            <PeriodSelect
              style={{ width: "50%", marginRight: "1em" }}
              selectedPeriodId={selectedPeriodId}
              setSelectedPeriodId={setSelectedPeriodId}
            />
            <CrudPageProcessButton
              tooltipText="Aşı Geçmişini Göster"
              icon={<MedicineBoxOutlined />}
              onClick={() => {
                if (!id) return;
                setVaccineLogsLoading(true);
                setVaccineLogsModalIsOpen(true);
                vaccineExtendedApi
                  .getVaccineLogs(parseInt(id))
                  .then((response) => setVaccineLogs(response))
                  .finally(() => setVaccineLogsLoading(false));
              }}
            />
            <CrudPageProcessButton
              tooltipText="İşletme Sürüsü"
              icon={<CowIcon />}
              onClick={() => window.open("/isletme/" + id + "/suru")}
            />
          </>
        )}
        setEditFields={(row) => {
          const mappedVaccines = row.vaccines.map((vaccine: any) => {
            return {
              vaccineType: vaccine.vaccineType,
              implementationDate: getDateFromString(vaccine.implementationDate),
              rapelDate: vaccine.rapelDate
                ? getDateFromString(vaccine.rapelDate)
                : undefined,
              description: vaccine.description,
            };
          });
          const mappedRationTables = row.rationTables.map(
            (rationTable: any) => {
              return {
                ...rationTable,
                date: getDateFromString(rationTable.date),
              };
            }
          );
          const mappedBaitAnalyseTables = row.baitAnalyseTables.map(
            (baitAnalyseTable: any) => {
              return {
                ...baitAnalyseTable,
                date: getDateFromString(baitAnalyseTable.date),
              };
            }
          );

          setSelectedTaskCategoryName(row.taskCategory.service.name);
          return {
            taskType: row.taskType,
            taskCategory: row.taskCategory["@id"],
            description: row.description,
            media: row.media,
            vaccines: mappedVaccines,
            gaitaTables: row.gaitaTables,
            baitTables: row.baitTables,
            rationTables: mappedRationTables,
            baitAnalyseTables: mappedBaitAnalyseTables,
          };
        }}
        hideEditProcess={(row) =>
          !(
            AuthHelper.checkVisibility("İş Düzenle") ||
            (AuthHelper.isResourceOwn(row.worker.id) &&
              row.taskStatus !== "approved")
          )
        }
        hideDeleteProcess={(row) =>
          !(
            AuthHelper.checkVisibility("İş Sil") ||
            (AuthHelper.isResourceOwn(row.worker.id) &&
              row.taskStatus !== "approved")
          )
        }
      />
      <Modal
        title="Aşı Geçmişini Göster"
        visible={vaccineLogsModalIsOpen}
        okButtonProps={{ hidden: true }}
        cancelText="Kapat"
        onCancel={() => {
          setVaccineLogs([]);
          setVaccineLogsLoading(false);
          setVaccineLogsModalIsOpen(false);
        }}
      >
        {vaccineLogsLoading ? (
          <Row justify="center">
            <LoadingOutlined />
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              {vaccineLogs.map((vaccineLog: any, index: number) => {
                return <VaccineCard key={index} vaccine={vaccineLog} />;
              })}
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default CompanyDetail;
