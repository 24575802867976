import { Button, Card, Col, Form, Input, Row } from "antd";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userCrudExtendedApi } from "../client/EntityApi";
import { userCrudApi } from "../core/client/BaseEntityApi";
import { AuthHelper } from "../core/helper/AuthHelper";
import { Rules } from "../core/Rules";

const ProfilePage = () => {
  const [updateProfileForm] = Form.useForm();
  const [renewPasswordForm] = Form.useForm();
  const [user, setUser] = useState<any>();
  const [updateProfileLoading, setUpdateProfileLoading] =
    useState<boolean>(false);
  const [renewPasswordLoading, setRenewPasswordLoading] =
    useState<boolean>(false);

  useEffect(() => setUser(AuthHelper.getUser()), []);

  useEffect(() => {
    if (user) {
      updateProfileForm.setFieldsValue({
        name: user.name,
        surname: user.surname,
        username: user.username,
        role: user.role.name,
      });
    }
  }, [user, updateProfileForm]);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} lg={12}>
        <Card style={{ borderRadius: "8px" }}>
          <h3 style={{ textAlign: "center" }}>
            <b>Profil Bilgilerim</b>
          </h3>
          <Form
            form={updateProfileForm}
            autoComplete="off"
            layout="vertical"
            onFinish={(values) => {
              if (user?.id) {
                setUpdateProfileLoading(true);
                delete values["username"];
                delete values["role"];
                userCrudApi
                  .edit(user.id, values)
                  .then((response) => {
                    Cookies.set("user", JSON.stringify(response));
                    setUser(response);
                    toast.success("Bilgiler güncellendi.");
                  })
                  .finally(() => setUpdateProfileLoading(false));
              }
            }}
          >
            <Form.Item
              label="İsim"
              name="name"
              rules={[Rules.required, Rules.min(3)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Soyisim"
              name="surname"
              rules={[Rules.required, Rules.min(3)]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Kullanıcı Adı" name="username">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Rol" name="role">
              <Input disabled />
            </Form.Item>
            <Form.Item>
              <Button
                loading={updateProfileLoading}
                type="primary"
                htmlType="submit"
              >
                Bilgileri Güncelle
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col xs={24} lg={12}>
        <Card style={{ borderRadius: "8px" }}>
          <h3 style={{ textAlign: "center" }}>
            <b>Şifremi Güncelle</b>
          </h3>
          <Form
            form={renewPasswordForm}
            autoComplete="off"
            layout="vertical"
            onFinish={(values) => {
              setRenewPasswordLoading(true);
              userCrudExtendedApi
                .renewPassword(values.oldPassword, values.newPassword)
                .then(() => {
                  renewPasswordForm.resetFields();
                  toast.success("Şifre güncelleme başarılı.");
                })
                .finally(() => setRenewPasswordLoading(false));
            }}
          >
            <Form.Item
              label="Eski Şifre"
              name="oldPassword"
              rules={[Rules.required]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Yeni Şifre"
              name="newPassword"
              rules={[Rules.required, Rules.min(4)]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                loading={renewPasswordLoading}
                type="primary"
                htmlType="submit"
              >
                Şifremi Güncelle
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ProfilePage;
