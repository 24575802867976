import { Select } from "antd";
import { useEffect, useState } from "react";
import { periodCrudApi, periodCrudExtendedApi } from "../client/EntityApi";
import { AuthHelper } from "../core/helper/AuthHelper";
import { getMonthString } from "../core/helper/UtilHelper";

interface PeriodSelectProps {
  selectedPeriodId?: number;
  setSelectedPeriodId: (periodId: number) => void;
  style?: object;
}

const PeriodSelect = (props: PeriodSelectProps) => {
  const [periods, setPeriods] = useState<any[]>([]);
  const [periodLoading, setPeriodLoading] = useState<boolean>(false);

  useEffect(() => {
    setPeriodLoading(true);
    periodCrudApi.getAll().then((response) => {
      const mappedPeriods = response["hydra:member"]
        .filter(
          (period: any) =>
            period.isVisible ||
            AuthHelper.checkVisibility("Periyot Görünürlüğü")
        )
        .map((period: any) => {
          return {
            label: getMonthString(parseInt(period.month)) + " " + period.year,
            value: period.id,
          };
        });
      setPeriods(mappedPeriods);
      periodCrudExtendedApi
        .getCurrentPeriod()
        .then((currentPeriodResponse) =>
          props.setSelectedPeriodId(currentPeriodResponse.periodId)
        )
        .finally(() => setPeriodLoading(false));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      size="large"
      value={props.selectedPeriodId}
      style={props.style}
      loading={periodLoading}
      disabled={periodLoading}
      onChange={(periodId) => props.setSelectedPeriodId(periodId)}
      options={periods}
    />
  );
};

export default PeriodSelect;
